import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import ContentHeader from "../components/ContentHeader";
import {getScoreClass} from "../utils/Utils";
import ScoreShadingDrop from "../components/ScoreShadingDrop";
import StablefordDrop from "../components/StablefordDrop";
import {useParams} from "react-router-dom";
import ConsoleLoading from "../components/ConsoleLoading";
import pRetry from "p-retry";
import {retryFetchJson, retryOptions} from "../utils/Net";

export const MyRoundsComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const params = useParams()

    const [state, setState] = useState({
        showResult: false,
        roundId: params.roundId,
        scoreShadingId: "normal",
        stablefordId: "totalonly",
        accessLevel: "NONE",
        apiMessage: { },
        error: null,
        loaded: false,
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();

    useEffect(() => {

        getState(state.scoreShadingId, state.stablefordId).then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState(scoreShadingId, stablefordId) {
        try {
            const token = await getAccessTokenSilently();

            let uri = `${apiOrigin}/rounds/compare/` + params.roundId;

            const responseData = await pRetry(() => retryFetchJson(uri, token), retryOptions)

            return {
                ...state,
                scoreShadingId: scoreShadingId,
                stablefordId: stablefordId,
                showResult: true,
                apiMessage: responseData,
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    function changeScoreShading(event) {
        getState(event.target.value, state.stablefordId).then(stateData => {
            setState(stateData)
        })
    }

    function changeStablefordDisplay(event) {
        getState(state.scoreShadingId, event.target.value).then(stateData => {
            setState(stateData)
        })
    }

    return (
        <div className="console">
            {!state.showResult &&
                <ConsoleLoading/>
            }
            {state.showResult &&
                <table border="0" cellPadding="0" cellSpacing="0" width={'100%'}>
                <tbody>
                <tr>
                    <td className="detailconsolecontent" border="1">
                        <ContentHeader label={'Compare Rounds'}/>
                        <div className="console">
                            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                                <tbody>
                                <tr>
                                    <td className="detailconsolecontent" border="1">
                                        <span className="infovalue">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className="dropdownlabel" valign="center">Score Shading </td>
                                                <td valign="center"><ScoreShadingDrop selected={state.scoreShadingId} changeFunction={changeScoreShading}/></td>
                                                <td className="dropdownlabel" valign="center">Stableford Display </td>
                                                <td valign="center"><StablefordDrop selected={state.stablefordId} changeFunction={changeStablefordDisplay}/></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="console">
                            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                                <tbody>
                                <tr>
                                    <td className="detailconsolecontent" border="1">


                                        <table border="0" className="filledtable">
                                            <tbody>
                                            <tr>
                                                <td nowrap="" className="cell cellheader">SSS (CI)</td>
                                                <td className="cell celloldlight" align="center">{state.apiMessage.course.sss}</td>
                                            </tr>
                                            <tr>
                                                <td className="cell cellheader">Slope</td>
                                                <td align="center" className="cell celloldlight">{state.apiMessage.course.slopeIndex}</td>
                                            </tr>
                                            <tr>
                                                <td className="cell cellheader">Hole</td>
                                                <td width="25" align="center" className="cell cellheader">1</td>
                                                <td width="25" align="center" className="cell cellheader">2</td>
                                                <td width="25" align="center" className="cell cellheader">3</td>
                                                <td width="25" align="center" className="cell cellheader">4</td>
                                                <td width="25" align="center" className="cell cellheader">5</td>
                                                <td width="25" align="center" className="cell cellheader">6</td>
                                                <td width="25" align="center" className="cell cellheader">7</td>
                                                <td width="25" align="center" className="cell cellheader">8</td>
                                                <td width="25" align="center" className="cell cellheader">9</td>
                                                <td width="25" align="center" className="cell cellheader">10</td>
                                                <td width="25" align="center" className="cell cellheader">11</td>
                                                <td width="25" align="center" className="cell cellheader">12</td>
                                                <td width="25" align="center" className="cell cellheader">13</td>
                                                <td width="25" align="center" className="cell cellheader">14</td>
                                                <td width="25" align="center" className="cell cellheader">15</td>
                                                <td width="25" align="center" className="cell cellheader">16</td>
                                                <td width="25" align="center" className="cell cellheader">17</td>
                                                <td width="25" align="center" className="cell cellheader">18</td>
                                            </tr>

                                            <tr>
                                                <td className="cell cellheader">Par</td>
                                                {Array.from(state.apiMessage.holes).map(hole =>
                                                    <td align="center" className="cell celloldlight">{hole.par}</td>
                                                )
                                                }
                                                <td className="cell cellheader" align="center" width="25">32</td>
                                                <td className="cell celloldlight" align="center" width="25">30</td>
                                                <td className="cell cellheader" align="center" width="25">62</td>
                                            </tr>

                                            <tr>
                                                <td className="cell cellheader">Stroke Index</td>
                                                {Array.from(state.apiMessage.holes).map(hole =>
                                                    <td align="center" className="cell cellheader">{hole.strokeIndex}</td>
                                                )
                                                }
                                                <td align="center" className="cell cellheader">Fr</td>
                                                <td align="center" className="cell celloldlight">Ba</td>
                                                <td align="center" className="cell cellheader">To</td>
                                                <td align="center" className="cell celloldlight" width="25">Ha</td>
                                                <td align="center" className="cell cellheader" width="25">St</td>
                                                <td align="center" className="cell celloldlight" width="25">Fa</td>
                                                <td align="center" className="cell cellheader" width="25">Pu</td>
                                                <td align="center" className="cell celloldlight" width="25">Gr</td>
                                            </tr>

                                            {state.apiMessage.rounds ?
                                                <>
                                                {Array.from(state.apiMessage.rounds).map(round =>
                                                <tr>
                                                    <td nowrap="" className="cell celloldlight">{round.round.name}</td>
                                                    {Array.from(round.scores).map(score =>
                                                        <td align="center" className={"cell " + (score.strokes > 0 ? getScoreClass(state.scoreShadingId === "normal" ? score.scratchStablefordScore : score.stablefordScore) : 'cellnone')}>
                                                            {score.strokes > 0 ? score.strokes : "-"}
                                                            {score.strokes > 0 && state.stablefordId === "perhole" ? "/" + score.stablefordScore : ""}
                                                        </td>
                                                    )}
                                                    <td align="center" className="cell cellpar">{round.frontNine}</td>
                                                    <td align="center" className="cell cellpar">{round.backNine}</td>
                                                    <td align="center" className="cell cellpar">{round.totalStrokes}</td>
                                                    <td align="center" className="cell cellpar">{round.round.handicap}</td>
                                                    <td align="center" className="cell cellpar">{round.stablefordScore}</td>
                                                    <td align="center" className="cell cellpar">{round.totalFairways}</td>
                                                    <td align="center" className="cell cellpar">{round.totalPutts}</td>
                                                    <td align="center" className="cell cellpar">{round.totalGreensInRegulation}</td>

                                                </tr>)}
                                                </> : <tr><td>Could not find any rounds to compare</td></tr>
                                            }
                                            </tbody>

                                        </table>
                                        {/*<a href={"/new-round/" + state.apiMessage.course.courseId}><img alt="New Round" align="absmiddle" border="0" src="/img/but/newround2.gif"/></a>*/}
                                        {/*<a href={"/edit-course/" + state.apiMessage.course.courseId}><img alt="Edit Course" align="absmiddle" border="0" src="/img/but/editcourse2.gif"/></a>*/}

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            }
        </div>

    );
};

export default withAuthenticationRequired(MyRoundsComponent, {
    onRedirecting: () => <Loading />,
});
