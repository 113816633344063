import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

import { ReferenceSearchResult } from '../types/Types';
import {getReferenceSearchResults} from "../utils/Net";
import { Link } from 'react-router-dom';

// ... other imports

const ReferenceAdminComponent: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ReferenceSearchResult[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const results = await getReferenceSearchResults(token, searchTerm);
            setSearchResults(results);
        } catch (error) {
            console.error("Error fetching the search results", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="detailconsolecontent">
            {/* Search Input */}
            <div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for a course..."
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            {/* Loading Indicator */}
            {isLoading && <p>Loading...</p>}

            {/* Search Results */}
            <div>
                {searchResults.length > 0 ? (
                    <ul>
                        {searchResults.map(result => (
                            <li key={result.courseId}>
                                <Link to={`/edit-reference-course/${result.newId}`}>
                                    {result.courseName} ({result.clubName}) - Located in {result.clubLocation}
                                </Link>
                                {/* More details can be added here */}
                            </li>
                        ))}
                    </ul>
                ) : (
                    !isLoading && <p>No results found.</p>
                )}
            </div>
        </div>
    );
};

export default withAuthenticationRequired(ReferenceAdminComponent, {
    onRedirecting: () => <Loading />,
});
