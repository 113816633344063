import { getConfig } from '../config';
import {
  CourseDetailsResponse, ReferenceCourseResponse, ReferenceSearchResult,
  SubscriptionData,
} from '../types/Types';
import pRetry, {AbortError} from "p-retry";
import {ReferenceCourseResponseV2} from "../ReferenceDatabase/Model";

const { apiOrigin = 'http://localhost:8080' } = getConfig();

export const checkVersion = () =>{
  const { apiOrigin = "http://localhost:8080", version } = getConfig();

    fetch(`${apiOrigin}/version`).then(response => response.json()).then(data => {
      console.log(`Client version: ${version} - Server expected client: ${data.clientVersion} - Server version ${data.serverVersion}`)
      console.log(`Environment: ${process.env.REACT_APP_ENVIRONMENT}`)
      console.log('NODE_ENV', process.env.NODE_ENV)
      if (data.clientVersion !== version && process.env.REACT_APP_ENVIRONMENT !== "development") {
        //console.log(`New version available: ${version} - reloading client`)
        //window.location.reload()
      }
    })
}

export const retryOptions = {
  onFailedAttempt: (error: { attemptNumber: any; retriesLeft: any; }) => {
    console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
  },
  retries: 5
}

const retryFetchJson = async (u: string, token: string): Promise<any> => {
  const response = await fetch(u, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Abort retrying if the resource doesn't exist
  if (response.status === 404) {
    throw new AbortError(response.statusText);
  }

  if (response.status === 500) {
    throw new Error(response.statusText);
  }

  return response.json();
};

async function subscriptionData(token: string): Promise<SubscriptionData> {
  const response = await fetch(`${apiOrigin}/rounds/count`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return {
    rounds: data.count,
    flags: data.flags,
    subscriptionType: data.subscriptionType,
  };
}


async function getReferenceSearchResults(token: string, searchTerm: string): Promise<ReferenceSearchResult[]> {
  if (searchTerm.length > 3 && searchTerm !== 'golf') {
    let uri = `${apiOrigin}/reference-courses/search?searchTerm=` + searchTerm;

    const responseData = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return await responseData.json();
  }

  return [];
}

async function getFriendData(token: string, excludeNoRounders: boolean, excludePending: boolean, includeMe: boolean): Promise<any> {
    let uri = `${apiOrigin}/my-friends?excludeNoRounders=` + excludeNoRounders
        + `&excludePending=` + excludePending
        + `&includeMe=` + includeMe;

    return await pRetry(() => retryFetchJson(uri, token), retryOptions);
}

async function getCourse(token: string, courseId: number): Promise<CourseDetailsResponse> {
  let uri = `${apiOrigin}/courses/` + courseId;

  return await pRetry(() => retryFetchJson(uri, token), retryOptions);
}

async function getReferenceCourse(token: string, courseId: number): Promise<ReferenceCourseResponse> {
  let uri = `${apiOrigin}/reference-courses/` + courseId;

  return await pRetry(() => retryFetchJson(uri, token), retryOptions);
}

async function getReferenceCourseV2(token: string, courseId: string): Promise<ReferenceCourseResponseV2> {
  let uri = `${apiOrigin}/v2/reference-courses/` + courseId;

  return await pRetry(() => retryFetchJson(uri, token), retryOptions);
}


export {
  retryFetchJson,
  subscriptionData,
  getReferenceSearchResults,
  getReferenceCourse,
  getCourse,
  getFriendData,
  getReferenceCourseV2
};
