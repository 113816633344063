import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal'
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";

import {Button} from "reactstrap";

export const NotesModal = ({ id, show, holeNumber, content, closeFunc, formValues }) => {

    const [state, setState] = useState({
        content: content
    });

    const changeNote = (value) => {
        formValues["noteText_" + holeNumber] = value

        setState({
            ...state,
            content: value
        })
    };

    return (
        <Modal show={show}>
          <ModalHeader>
            <ModalTitle>Notes for Hole {holeNumber}</ModalTitle>
          </ModalHeader>
          <ModalBody>
              <FormGroup >
                  <FormLabel>Notes: </FormLabel>
                  <FormControl as="textarea"
                               onChange={
                                  (e) => {
                                      changeNote(e.target.value)
                                  }
                               }
                               id={id}
                               value={formValues["noteText_" + holeNumber]}
                  />
              </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="submit" onClick={closeFunc}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
    )
}

export default NotesModal