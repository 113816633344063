import LineGraph from "./LineGraph";
import * as PropTypes from "prop-types";
import React from "react";

function ProgressionGraphs(props) {
    return <div style={{clear: "both", paddingTop: "2em"}}>
        <h3>Progression Graphs</h3>
        <div>After having entered at least two rounds, these
            graphs show how your performance is changing over time.
        </div>
        <div className={"statsLineGraph"}>
            <LineGraph rawData={props.state.progressionData.greensInRegulation} title={"Greens In Regulation"}/>
        </div>
        <div className={"statsLineGraph"}>
            <LineGraph rawData={props.state.progressionData.strokes} title={"Strokes"}/>
        </div>
        <div className={"statsLineGraph"}>
            <LineGraph rawData={props.state.progressionData.putts} title={"Putts"}/>
        </div>
        <div className={"statsLineGraph"}>
            <LineGraph rawData={props.state.progressionData.fairwaysHit} title={"Fairways Hit"}/>
        </div>
    </div>;
}

ProgressionGraphs.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.number,
        dateTo: PropTypes.any,
        dateFrom: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.number,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    })
};

export default ProgressionGraphs
