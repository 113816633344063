import React, {Fragment} from "react";

import Hero from "../components/Hero";
import {CookieConsent} from "react-cookie-consent";

const Home = () => {

    return (
        <Fragment>
            <Hero />
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </Fragment>
    );
}

export default Home;
