import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getConfig} from "../../config";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import pRetry from "p-retry";
import {retryFetchJson, retryOptions} from "../../utils/Net";

export const CourseRowMobile = ({ course, courseOwnerId, accessLevel }) => {
    const navigate = useNavigate();

    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {
            rounds: 0,
            fullRounds: 0,
            lastRound: 0,
            underOverCount: [0, 9, 0, 0, 0, 0, 0, 0, 0],
            holesPlayed: 0,
            course: {
                courseTeesName: "Loading...",
            }
        },
        loaded: false,
        error: null,
    });

    const {
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {

        const callApi = async () => {
            try {
                const token = await getAccessTokenSilently();

                const responseData = await pRetry(() => retryFetchJson(`${apiOrigin}/courses/` + course.courseId, token), retryOptions);

                return {
                    ...state,
                    showResult: true,
                    apiMessage: responseData,
                };
            } catch (error) {
                return {
                    ...state,
                    error: error.error,
                };
            }
        };

        let cancel = false;
        callApi().then(stateData => {
            if (!cancel) {
                setState(stateData)
            }
        })

        return () => {
            cancel = true;
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

     return (
         <>
             <tr>
             <td nowrap="true" className="cell cellheader" colSpan={16}>
                 <button className={"button_as_link"} style={{'textDecoration':'none', color:'#FFFFFF'}}
                         onClick={() => navigate("/view-rounds/?friendId=" + courseOwnerId + "&courseId=" + course.courseId)}>
                     {course.courseName} ({state.apiMessage.course.courseTeesName})</button>
             </td>

             </tr>

             <tr>
                <td className="cell celloldlight" align="center">{state.apiMessage.rounds}</td>
                <td className="cell celloldlight" align="center">{state.apiMessage.fullRounds}</td>
                 {state.apiMessage.underOverCount ? (
                     <>
                <td align="center" className="cell cellm4">{state.apiMessage.underOverCount[0]}</td>
                <td align="center" className="cell cellalbatross">{state.apiMessage.underOverCount[1]}</td>
                <td align="center" className="cell celleagle">{state.apiMessage.underOverCount[2]}</td>
                <td align="center" className="cell cellbirdie">{state.apiMessage.underOverCount[3]}</td>
                <td align="center" className="cell cellpar">{state.apiMessage.underOverCount[4]}</td>
                <td align="center" className="cell cellbogey">{state.apiMessage.underOverCount[5]}</td>
                <td align="center" className="cell celldoublebogey">{state.apiMessage.underOverCount[6]}</td>
                <td align="center" className="cell cell3">{state.apiMessage.underOverCount[7]}</td>
                <td align="center" className="cell cell4">{state.apiMessage.underOverCount[8]}</td>
                </>
                    ) : (<td colSpan={9} align="center" className="cell">There was an error loading these details, please reload the page</td>)}
                <td align="center" className="cell celloldlight" bgcolor="#CCFFCC">3</td>


        </tr>
             </>
     )
}

export default CourseRowMobile;
