import React, {useEffect, useState} from "react";
import {getConfig} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import FriendDropRow from "./FriendDropRow";

export const FriendDrop = ({ selected, excludeNoRounders = false, excludePending = false, includeMe = true, friendFunction }) => {

    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {
            "userId": 1,
            "friends": [
                {
                    "accessLevel": -1,
                    "createdTime": 1495399536000,
                    "changedTime": 971968761000,
                    "userId": 0,
                    "friendId": 0,
                    "name": "Loading..."
                },
            ],
        },
        loaded: false,
        error: null,
    });

    const {
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {
        getState().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getState() {
        try {
            const token = await getAccessTokenSilently();

            const response = await fetch(`${apiOrigin}/my-friends?excludeNoRounders=` + excludeNoRounders
                + `&excludePending=` + excludePending
                + `&includeMe=` + includeMe, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            return {
                ...state,
                showResult: true,
                apiMessage: responseData,
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    return state.apiMessage && state.apiMessage.friends && (
            <select value={selected} className="dropdown" onChange={friendFunction} name="changefriend">
                {Array.from(state.apiMessage.friends).map(friend =>
                        <FriendDropRow key={friend.friendId} friend={friend}/>
                    )
                }
            </select>
    );
}

export default FriendDrop;
