import React from "react";
import { Course } from "../model/Course";

type Props = {
    course: Course;
}

export const CourseDropRow = ({ course }: Props) => {

     return (
         <option value={course.courseId.toString()}>
             {course.courseName}
         </option>
     )
}

export default CourseDropRow;
