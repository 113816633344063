import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import ContentHeader from "../components/ContentHeader";
import {useLocation} from "react-router-dom";
import ConsoleLoading from "../components/ConsoleLoading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import RoundsFilterDesktop from "../components/Rounds/RoundsFilterDesktop";
import {useWindowSize} from "../components/Hooks/useWindowSize";
import RoundsFilterMobile from "../components/Rounds/RoundsFilterMobile";
import RoundsTableDesktop from "../components/Rounds/RoundsTableDesktop";
import RoundsTableMobile from "../components/Rounds/RoundsTableMobile";
import pRetry from "p-retry";
import {checkVersion, retryFetchJson, retryOptions} from "../utils/Net";
import Pagination from "../components/Rounds/Pagination";

export const MyRoundsComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();
    const [width] = useWindowSize()

    const search = useLocation().search;
    const navigate = useNavigate();

    const [state, setState] = useState({
        showResult: false,
        friendId: new URLSearchParams(search).get('friendId') || 0,
        courseId: new URLSearchParams(search).get('courseId') || -1,
        scoreShadingId: "normal",
        stablefordId: "totalonly",
        accessLevel: "NONE",
        apiMessage: {
            decoratedRounds: {},
            pageNumber: 1,
            pageSize: 25,
            totalRecords: undefined,
            totalPages: undefined
        },
        error: null,
        loaded: false,
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();


    useEffect(() => {

        checkVersion();

        getState(state.friendId, state.courseId, state.scoreShadingId, state.stablefordId, state.apiMessage.pageNumber, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState(friendId, courseId, scoreShadingId, stablefordId, pageNumber, pageSize) {
        try {

            const token = await getAccessTokenSilently();

            let uri = `${apiOrigin}/rounds?d=1`;

            if (courseId !== 0) {
                uri += "&courseId=" + courseId;
            }

            if (friendId !== 0) {
                uri += "&friendId=" + friendId;
            }

            uri += "&page=" + pageNumber;
            uri += "&pageSize=" + pageSize;

            const responseData = await pRetry(() => retryFetchJson(uri, token), retryOptions)

            return {
                ...state,
                friendId: friendId,
                courseId: courseId,
                scoreShadingId: scoreShadingId,
                stablefordId: stablefordId,
                showResult: true,
                apiMessage: responseData,
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    function changeFriend(event) {
        getState(event.target.value, 0, state.scoreShadingId, state.stablefordId, 1, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function changeCourse(event) {
        getState(state.friendId, event.target.value, state.scoreShadingId, state.stablefordId, 1, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function changeScoreShading(event) {
        getState(state.friendId, state.courseId, event.target.value, state.stablefordId, state.apiMessage.pageNumber, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function changeStablefordDisplay(event) {
        getState(state.friendId, state.courseId, state.scoreShadingId, event.target.value, state.apiMessage.pageNumber, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function changePage(pageNumber) {
        getState(state.friendId, state.courseId, state.scoreShadingId, state.stablefordId, pageNumber, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function changePageSize(pageSize) {
        getState(state.friendId, state.courseId, state.scoreShadingId, state.stablefordId, 1, pageSize).then(stateData => {
            setState(stateData)
        })
    }

    function allCourses() {
        getState(state.friendId, -1, state.scoreShadingId, state.stablefordId, state.apiMessage.pageNumber, state.apiMessage.pageSize).then(stateData => {
            setState(stateData)
        })
    }

    async function deleteRound(roundId) {
        const token = await getAccessTokenSilently();

        if (window.confirm("Are you sure you want to delete this round?")) {
            fetch(apiOrigin + '/rounds/' + roundId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.status === 204) {
                        getState(state.friendId, state.courseId, state.scoreShadingId, state.stablefordId, state.apiMessage.pageNumber, state.apiMessage.decoratedRounds.pageSize).then(stateData => {
                            setState(stateData)
                        })
                    } else {
                        denied("delete")
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    function denied(type) {
        toast.error("You don't have permission to " + type + " this round.")
    }

    async function deleteCourse(hasAccess, canDelete) {

        if (!hasAccess) {
            toast.error("You don't have access to delete courses for this user's account");
            return;
        }

        if (!canDelete) {
            toast.error("You must remove all rounds at this course before you can delete it");
            return;
        }

        if (window.confirm("Are you sure you want to delete this course?")) {
            const token = await getAccessTokenSilently();
            fetch(apiOrigin + '/courses/' + state.courseId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 204) {
                    window.location.href = '/courses';
                } else {
                    denied("delete")
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    return (

        <div className="console">
            {!state.showResult &&
                <ConsoleLoading/>
            }
            {state.showResult &&
<>
    <ContentHeader label={'Rounds'}/>
    {width > 768 ?
        <RoundsFilterDesktop state={state} friendFunction={changeFriend} courseFunction={changeCourse}
                             onClick={allCourses}
                             changeFunction={changeScoreShading} changeFunction1={changeStablefordDisplay}
                             onClick1={() => navigate("/new-round/" + state.courseId)}
                             onClick2={() => navigate("/edit-course/" + state.courseId)}
                             onClick3={() => deleteCourse(state.apiMessage.decoratedRounds.accessLevel === "WRITE", !state.apiMessage.decoratedRounds.rounds || state.apiMessage.decoratedRounds.rounds.length === 0)}
        />
    :
        <RoundsFilterMobile state={state} friendFunction={changeFriend} courseFunction={changeCourse}
                             onClick={allCourses}
                             changeFunction={changeScoreShading} changeFunction1={changeStablefordDisplay}
                             onClick1={() => navigate("/new-round/" + state.courseId)}
                             onClick2={() => navigate("/edit-course/" + state.courseId)}
                             onClick3={() => deleteCourse(state.apiMessage.decoratedRounds.accessLevel === "WRITE", !state.apiMessage.decoratedRounds.rounds || state.apiMessage.decoratedRounds.rounds.length === 0)}
        />
    }

    <div className="console scrollable-table-container">
        {width > 1024 ?
            <RoundsTableDesktop state={state} deleteRound={deleteRound} denied={denied}/>
        :
            <RoundsTableMobile state={state} deleteRound={deleteRound} denied={denied}/>
        }
        <Pagination
            apiMessage={state.apiMessage}
            pageChangeFunction={changePage}
            pageSizeFunction={changePageSize}
        />
    </div>



</>
            }
        </div>

    );
};

export default withAuthenticationRequired(MyRoundsComponent, {
    onRedirecting: () => <Loading />,
});
