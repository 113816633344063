import React from "react";

export const ScoreShadingDrop = ({changeFunction, selected}) => {

    const options = [
        { label: 'Based on Scratch for each Round', value: 'normal' },
        { label: 'Based on Handicap for each Round', value: 'handicap' },
    ];

    return (
        <select value={selected} className="dropdown" onChange={changeFunction} name="changeScoreShading">
            {options.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
    );
}

export default ScoreShadingDrop;
