import React, { useState, useEffect } from "react";
import { getConfig } from "../config";
import ConsoleLoading from "../components/ConsoleLoading";
import {useNavigate, useParams} from "react-router-dom";
import pRetry from 'p-retry';
import {retryFetchJson, retryOptions} from "../utils/Net";
import {ScorecardHole, ScorecardResponse, ScorecardTee} from "../types/Types";
import Blurb from "../components/Blurb";

type ScorecardState = {
    apiMessage: ScorecardResponse;
    currentTeeId: number;
    currentSex: string;
    holes: ScorecardHole[];
    imageWorld: string;
    imageLocal: string;
}

export const ReferenceCourseComponent = () => {
  const { apiOrigin = "http://localhost:8080" } = getConfig();
  const params = useParams();
  const navigate = useNavigate()

  const [state, setState] = useState({
    apiMessage: {} as ScorecardResponse,
  } as ScorecardState);

  const courseId = params.courseId || ''

  useEffect(() => {

    getState(courseId).then(stateData => {
       document.title = stateData.apiMessage.club.clubName + " " + stateData.apiMessage.course.courseName + " " + stateData.apiMessage.club.location + " - Golf Scorecard";
       setState(stateData)
    })

    return () => {
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getState(courseId: string, tee: number = -1) {
    try {

      let uri = `${apiOrigin}/golf-scorecard/` + courseId;

      const responseData = await pRetry(() => retryFetchJson(uri, ''), retryOptions);

      state.currentTeeId = tee === -1 ? responseData.tees[0].teeId : tee;
      const teeState = responseData.tees.find((tee: ScorecardTee) => tee.teeId === state.currentTeeId)
      state.holes = teeState ? teeState.holes : []

      state.imageWorld = "data:image/png;base64," + responseData.imageWorld;
      state.imageLocal = "data:image/png;base64," + responseData.imageLocal;

      return {
        ...state,
        showResult: true,
        apiMessage: responseData,
      };
    } catch (error: any) {
      return {
        ...state,
        error: error.error,
      };
    }
  }

  function changeCourse(event: any) {
    navigate('/golf-scorecard/' + event.target.value);
    getState(event.target.value).then(stateData => {
      setState(stateData)
    })
  }

  function changeTee(event: any) {
    state.currentTeeId = parseInt(event.target.value)
    const teeState = state.apiMessage.tees.find((tee: ScorecardTee) => tee.teeId === state.currentTeeId)
    state.holes = teeState ? teeState.holes : []
    setState({
      ...state,
    })
  }

  function changeSex(event: any) {
    const currentTeeState = state.apiMessage.tees.find((tee: ScorecardTee) => tee.teeId === state.currentTeeId)
    state.currentSex = event.target.value
    if (currentTeeState) {
      const newTargetTeeName = state.currentSex === 'L' ? currentTeeState.teeName + ' Ladies' : currentTeeState.teeName.replace('Ladies', '').trim()
      const newTargetTee = state.apiMessage.tees.find((tee: ScorecardTee) => tee.teeName.toLowerCase() === newTargetTeeName.toLowerCase())
      if (newTargetTee) {
        state.currentTeeId = newTargetTee.teeId
        state.holes = newTargetTee.holes
      } else {
        const firstSexTee = state.apiMessage.tees.filter(tee =>
            state.currentSex === 'L' ?
                tee.teeName.endsWith('Ladies') :
                !tee.teeName.endsWith('Ladies')
        )[0]
        state.currentTeeId = firstSexTee.teeId
        state.holes = firstSexTee.holes
      }
    }
    setState({
      ...state,
    })
  }

  return (
      <div className="console ">
        {!state.apiMessage.course ?
            <ConsoleLoading/> :
            <div>
              <div className={"detailconsolecontent"}>
                <h2>{state.apiMessage.club.clubName}</h2>
                {state.apiMessage.clubCourses.length > 1 &&
                    <h5>{state.apiMessage.course.courseName}</h5>
                }
                <h6>{state.apiMessage.club.location}</h6>
              </div>

              {state.apiMessage.club.blurb &&
              <div className={"detailconsolecontent"}>
                <div dangerouslySetInnerHTML={{ __html: state.apiMessage.club.blurb.replace(/(\r\n|\r|\n)/g, '<br>') }} />
              </div>
              }

              <div className="detailconsolecontent scorecardDropdown float-lg-start" style={{clear:"both",width:"100%"}}>

                <div style={{paddingBottom:"1em"}}>
                  {state.apiMessage.clubCourses.length > 1 &&
                      <>
                      <select value={state.apiMessage.course.id} className="scorecardDropdown" onChange={changeCourse}>
                        {Array.from(state.apiMessage.clubCourses).map(course =>
                            <option key={course.id} value={course.id}>
                              {course.courseName}
                            </option>
                        )
                        }
                      </select>
                      &nbsp;&nbsp;
                      </>
                  }

                  {state.apiMessage.tees.length > 1 &&
                      <select value={state.currentTeeId} className="scorecardDropdown" onChange={changeTee}>
                        {Array.from(state.apiMessage.tees.filter(tee =>
                                state.currentSex === 'L' ?
                                    tee.teeName.endsWith('Ladies') :
                                    !tee.teeName.endsWith('Ladies')
                            )
                        ).map(tee =>
                            <option key={tee.teeId} value={tee.teeId}>
                              {tee.tee.replace('Ladies', ' (Ladies)').replace('Unspecified', 'Unknown Tees')}
                            </option>
                        )
                        }
                      </select>
                  }
                  &nbsp;&nbsp;
                  {Array.from(state.apiMessage.tees.filter(tee => tee.teeName.endsWith('Ladies'))).length > 0 &&
                      <select value={state.currentSex} className="scorecardDropdown" onChange={changeSex}>
                        <option value="M">Men</option>
                        <option value="L">Ladies</option>
                      </select>
                  }
                </div>

                <div className={"float-lg-start"}>
                  <table className={"scorecardTable"}>
                      <thead>
                      <tr>
                          <th>Hole</th>
                          <th>Par</th>
                          <th>Yards</th>
                          <th>Stroke Index</th>
                      </tr>
                      </thead>
                    <tbody>
                      {Array.from(state.holes).map(hole =>
                          <tr key={hole.holeNumber}>
                              <td className={"scorecardHoleCell"}>{hole.holeNumber}</td>
                              <td className={"scorecardHolePar"}>{hole.par}</td>
                              <td className={"scorecardHoleYardage"}>{hole.yardage}</td>
                              <td className={"scorecardHoleStrokeIndex"}>{hole.strokeIndex}</td>
                          </tr>
                      )
                      }
                      </tbody>
                  </table>
                  <table className={"scorecardSlopeTable"}>
                    <tbody>
                    <tr>
                        <th>Standard Scratch Score (SSS)</th>
                        <td>{state.apiMessage.tees.find((tee: ScorecardTee) => tee.teeId === state.currentTeeId)!!.sss}</td>
                    </tr>
                    <tr>
                        <th>Course Rating (Slope Index)</th>
                      <td>{state.apiMessage.tees.find((tee: ScorecardTee) => tee.teeId === state.currentTeeId)!!.slopeIndex}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div className={"float-lg-end"}>
                  <img src={state.imageWorld} alt="Course Map" className={"scorecardImage"}/>
                  <br/>
                  <img src={state.imageLocal} alt="Course Map" className={"scorecardImage"}/>
                </div>
              </div>

              <div className="detailconsolecontent" style={{float:"left"}}>
              <Blurb/>
              </div>
            </div>
       }

      </div>
  );
};

export default ReferenceCourseComponent
