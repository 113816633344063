import * as PropTypes from "prop-types";
import React from "react";

function StatsMainTable(props) {
    return (
        <div className={"statsMainTable"} style={{float: "left", marginTop: "0px", marginRight: "10px"}}>
            <h3>Summary</h3>
    <table>
        <tbody>
        <tr>
            <td
                className="statname">Rounds
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.rounds}</td>
            <td className="statdesc">Total rounds
                played
                including incomplete and nine hole
                rounds.
            </td>
        </tr>
        <tr>
            <td className="statname">Holes
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.holes}</td>
            <td className="statdesc">Total holes
                played.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Strokes
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.strokes}</td>
            <td className="statdesc">Total
                strokes
                across
                all holes.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Average Putts
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.putts.total} / {props.state.apiMessage.putts.recorded} = {props.state.apiMessage.putts.average.toFixed(1)}</td>
            <td className="statdesc">Total putts
                across
                all holes.
            </td>
        </tr>
        <tr>
            <td className="statname">Chip
                Ins
                (zero putts)
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.totalChipIns}</td>
            <td className="statdesc">Total across
                all
                holes.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Average Round
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.averageRound.total} / {props.state.apiMessage.averageRound.recorded} = {props.state.apiMessage.averageRound.average.toFixed(2)}</td>
            <td className="statdesc">18 completed
                holes
                only.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Fairways
                Hit
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.fairwaysHit.total}/{props.state.apiMessage.fairwaysHit.recorded} ({props.state.apiMessage.fairwaysHit.percentage.toFixed(1)}%)</nobr>
            </td>
            <td className="statdesc">% fairways
                hit
                across all rounds where entered. Not
                applicable
                for par threes.
            </td>
        </tr>
        <tr>
            <td className="statname">Avg.
                Drive
                Yards
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.driveYards.average.toFixed(2)}</nobr>
            </td>
            <td className="statdesc">Average
                drive
                yards,
                where recorded.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Bunkers
                Hit
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.bunkersHit.total}/{props.state.apiMessage.bunkersHit.recorded} ({props.state.apiMessage.bunkersHit.percentage.toFixed(1)}%)</nobr>
            </td>
            <td className="statdesc">% bunkers
                hit
                across
                all rounds where entered. Greenside
                bunkers
                are
                indicated by entering a <i>sand
                    save</i> value.
            </td>
        </tr>
        <tr>
            <td className="statname">Sand
                Saves
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.sandSaves.total}/{props.state.apiMessage.sandSaves.recorded} ({props.state.apiMessage.sandSaves.percentage.toFixed(1)}%)</nobr>
            </td>
            <td className="statdesc">% <i>sand
                saves</i> across all rounds where
                entered.
                An
                entry (Y or N) should be made if a
                greenside
                bunker was hit, otherwise it should be
                left
                blank. A sand save is one shot from the
                bunker
                followed by a sunk putt.
            </td>
        </tr>
        <tr>
            <td className="statname">Par Saves
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.parSaves.total}/{props.state.apiMessage.parSaves.recorded} ({props.state.apiMessage.parSaves.percentage.toFixed(1)}%)</nobr>
            </td>
            <td className="statdesc">Holes where a GIR is missed, but a score of par or better is achieved, vs holes where a GIR is missed..
            </td>
        </tr>
        <tr>
            <td
                className="statname">Greens in
                Regulation
            </td>
            <td align="center"
                className="statvalue">
                <nobr>{props.state.apiMessage.greensInRegulation.total}/{props.state.apiMessage.greensInRegulation.recorded} ({props.state.apiMessage.greensInRegulation.percentage.toFixed(1)}%)</nobr>
            </td>
            <td className="statdesc">The number
                of
                greens
                hit where two putts would allow you to
                make
                par.
                Calculated from the <i>putts</i> figure
                for
                each
                hole, where entered.
            </td>
        </tr>
        <tr>
            <td
                className="statname">Average Penalties
            </td>
            <td align="center"
                className="statvalue">{props.state.apiMessage.penalties.average.toFixed(1)}
            </td>
            <td className="statdesc">Average
                penalty
                strokes across all holes, where entered.
            </td>
        </tr>
        </tbody>
    </table>
    </div>
    )
}

StatsMainTable.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.number,
        dateTo: PropTypes.any,
        dateFrom: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.number,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    })
};

export default StatsMainTable;