import React from "react";

const Hero = () => (
    <div id={"homepagehero"}>
      <div className={"detailconsolecontenthome float-lg-start"}>
          <h2>
              Record your scores, analyse performance, track your handicap.
          </h2>
          <div>
                  <ul style={{lineHeight:'40px',marginTop:'10px',marginBottom:'10px'}}>
                      <li>Enter your golf scores</li>
                      <li>Manage your friends' scores
                      </li>
                      <li>Record fairways hit, putts, bunker saves and more
                      </li>
                      <li>Track your performance
                      </li>
                      <li>Maintain a <strong>handicap</strong> among your regular golfing friends
                      </li>
                      <li><strong>Stableford</strong> scoring
                      </li>
                  </ul>
          </div>
      </div>
        <div className={"progression_graphs float-lg-end"}>
            <div>
                <img style={{border: '0px solid #888'}}
                     width={"100%"}
                     alt={"Handicap Calculator Screenshot"}
                     src="/img/DeviceScreens/screenshot2.jpeg"/><br/>
            </div>
        </div>
        <div className={"golf_tracking_summary float-lg-end"}>
                <img style={{border: '0px solid #888'}}
                     width={"100%"}
                     alt={"Golf Score Tracker Screenshot One"}
                     src="/img/DeviceScreens/screenshot1.png"/><br/>

        </div>
        <div className={"laptop_image float-lg-start"}>
            <div>
                <img style={{border: '0px solid #888'}}
                     width={"100%"}
                     alt={"Handicap Calculator Screenshot"}
                     src="/img/DeviceScreens/Google Pixelbook right faced.png"/><br/>
            </div>
        </div>

        <div className={"users_say float-lg-end"}>
                        <h4>What our users say...</h4>
                        "The society that we run is
                        now using this as our way of ensuring we all play off a handicap using the same
                        methods... it's fair, personal, easy to use, and is interesting to see how each
                        game looks on paper. Fantastic site, long may it exist!"<br/><br/>"My wife and I
                        were searching for a good score/handicap tracker a few days ago. After trying
                        a few not-so-great websites, we searched for 'online group golf score tracker'
                        at Google. There it was, your website was found. We are very glad to have done
                        that last search. My golfer friends are coming on board now."<br/><br/>"Word of
                        mouth is how I found out about this brilliant site. I have continued to
                        spread the word as a result. I am very new to the game but am getting
                        pleasure from trying to improve where ever I can."<br/><br/>"Top site!! nice
                        and easy to use, most members of our society are on board."
                    </div>


    </div>
);

export default Hero;
