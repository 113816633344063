import React, {useEffect, useState} from "react";
import FriendDropRow from "./FriendDropRow";

const NewFriendDrop = ({ friendData, selected, friendFunction }) => {

    const [state, setState] = useState({

        showResult: false,
        apiMessage: {
            "userId": 1,
            "friends": [
                {
                    "accessLevel": -1,
                    "createdTime": 1495399536000,
                    "changedTime": 971968761000,
                    "userId": 0,
                    "friendId": 0,
                    "name": "Loading..."
                },
            ],
        },
        loaded: false,
        error: null,
    });

    useEffect(() => {

        getState().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friendData]);

    async function getState() {
        try {
            return {
                ...state,
                showResult: true,
                loaded: true,
                apiMessage: friendData,
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    return (
        <select value={selected} className="dropdown" onChange={friendFunction} name="changefriend">
            {Array.from(friendData.friends).map(friend =>
                    <FriendDropRow key={friend.friendId} friend={friend}/>
                )
            }
        </select>
    );
}

export default NewFriendDrop;