import React from "react";

export const StablefordDrop = ({changeFunction, selected}) => {

    const options = [
        { label: 'Per Hole', value: 'perhole' },
        { label: 'Total Only', value: 'totalonly' },
    ];

    return (
        <select value={selected} className="dropdown" onChange={changeFunction} name="changeStableford">
            {options.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
    );
}

export default StablefordDrop;
