import React from "react";
import LogoNavAndBushes from "./LogoNavAndBushes";

const TastyHero = () => (

    <div className="tasty-home">
        <div className="tasty-site-desktop">
            <img src="/img/tasty/desktop_white.png" alt="Desktop Screenshot"/>
        </div>

        <div className="tasty-site-tablet">
            <img src="/img/tasty/tablet_white.png" alt="Tablet Screenshot"/>
        </div>

        <div className={"tasty-home-background"}>

            <LogoNavAndBushes/>

            <div className="tasty-hero-text">
                <h1>Your golf score tracker<br/>and handicap calculator</h1>
                <ul>
                    <li>Enter your golf scores</li>
                    <li>Manage your friends' scores</li>
                    <li>Record fairways hit, putts, bunker saves and more</li>
                    <li>Track your performance</li>
                    <li>Maintain a handicap among your regular golfing friends</li>
                    <li>Stableford scoring</li>
                </ul>
            </div>

            <div className="tasty-content-section">
                <div className="tasty-content-title">Join the club!</div>
                <div className="tasty-content-paragraph">
                    <img src="/img/tasty/1x/golfer.png" alt="Golfer" className="tasty-golfer-img"/>
                    <img src="/img/tasty/1x/quote.png" alt="Opening Quote" className="tasty-quote-img"/>
                    <p>The society that we run is now using this as our way of ensuring we all play off a handicap using
                        the same methods... it's fair, personal, easy to use, and is interesting to see how each game
                        looks on paper. Fantastic site, long may it exist!</p>
                </div>
            </div>


            <div className="tasty-inner-background">
                <div className="footer">
                    <div className="footer-left">
                        © Golfing Record {new Date().getFullYear()}
                    </div>
                    <div className="footer-right">
                        <a href="/privacy">Privacy Policy</a>
                        <a href="/terms">Terms and Conditions</a>
                        <a href="/cookies">Cookie Policy</a>
                    </div>
                </div>

            </div>

        </div>
    </div>
);

export default TastyHero;
