import React from "react";

const Blurb = () => (
    <div id={"scorecard-blurb"}>

        <script async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9811385496939370"
                crossOrigin="anonymous"></script>
        <ins className="adsbygoogle"
             style={{display:"block"}}
             data-ad-client="ca-pub-9811385496939370"
             data-ad-slot="8156606864"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>


      <div className={"blurbContainer"}>
          <div style={{border:"1px solid #000",padding:"1em",marginBottom:"1em"}}>
              Golfers have been adding their scores to GolfingRecord since 1997. When we started, it was pretty tough
              to get hold of course data, but our players have been steadily adding courses for over two decades. We now have over 30,000 courses in our database
              from all over the world, and users that have played on every single one of them! If you spot any information that is incomplete, incorrect or out-of-date, please email us at <a href="mailto:golfingrecord@netsensia.com@">golfingrecord@netsensia.com</a>.
          </div>
          <h2>
              Record your scores, analyse performance, track your handicap.
          </h2>
              <div className={"blurbImage"}>
                  <img style={{border: '0px solid #888', width:"300px"}}
                       alt={"Handicap Calculator Screenshot"}
                       src="/img/DeviceScreens/screenshot2.jpeg"/><br/>
              </div>
              <div className={"blurbTextUsers"}>
                  <h4>What our users say...</h4>
                  "The society that we run is
                  now using this as our way of ensuring we all play off a handicap using the same
                  methods... it's fair, personal, easy to use, and is interesting to see how each
                  game looks on paper. Fantastic site, long may it exist!"<br/><br/>"My wife and I
                  were searching for a good score/handicap tracker a few days ago. After trying
                  a few not-so-great websites, we searched for 'online group golf score tracker'
                  at Google. There it was, your website was found. We are very glad to have done
                  that last search. My golfer friends are coming on board now."<br/><br/>"Word of
                  mouth is how I found out about this brilliant site. I have continued to
                  spread the word as a result. I am very new to the game but am getting
                  pleasure from trying to improve where ever I can."<br/><br/>"Top site!! nice
                  and easy to use, most members of our society are on board."
              </div>
            <div className={"blurbText"}>
                <ul style={{lineHeight:'40px',marginTop:'10px',marginBottom:'10px'}}>
                    <li>Enter your golf scores</li>
                    <li>Manage your friends' scores
                    </li>
                    <li>Record fairways hit, putts, bunker saves and more
                    </li>
                    <li>Track your performance
                    </li>
                    <li>Maintain a <strong>handicap</strong> among your regular golfing friends
                    </li>
                    <li><strong>Stableford</strong> scoring
                    </li>
                </ul>
            </div>
      </div>
    </div>
);

export default Blurb;
