import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const Footer: React.FC = () => (
        <Nav className="ml-auto">
            <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
            <Nav.Link as={Link} to="/privacy">Privacy Policy</Nav.Link>
            <Nav.Link as={Link} to="/terms">Terms and Conditions</Nav.Link>
            <Nav.Link as={Link} to="/cookies">Cookie Policy</Nav.Link>
        </Nav>
);

export default Footer;
