import React from "react";
import loading from "../assets/loading.svg";

const ConsoleLoading = () => (
  <div>
    <table width={"100%"} className="text-center">
      <tbody>
        <tr>
          <td className="detailconsolecontent">
            <img src={loading} alt="Loading" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ConsoleLoading;
