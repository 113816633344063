import FriendDrop from "../Friends/FriendDrop";
import CourseDrop from "../CourseDrop";
import * as PropTypes from "prop-types";
import React from "react";
import ScoreShadingDrop from "../ScoreShadingDrop";
import StablefordDrop from "../StablefordDrop";
import './Pagination.css';

function RoundsFilterDesktop(props) {

    return <div>
        <div className="console">
            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                <tbody>
                <tr>
                    <td className="detailconsolecontent" border="1">
                                        <span className="infovalue">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className="dropdownlabel" valign="center">Player </td>
                                                <td valign="center">
                                                    <FriendDrop selected={props.state.friendId}
                                                                excludeNoRounders={false}
                                                                excludePending={true}
                                                                includeMe={true}
                                                                friendFunction={props.friendFunction}/>
                                                </td>
                                                <td className="dropdownlabel" valign="center">Course </td>
                                                <td valign="center">
                                                    <CourseDrop selected={props.state.courseId}
                                                                friendId={props.state.friendId}
                                                                courseFunction={props.courseFunction}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </span>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
        <div className="console">
            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                <tbody>
                <tr>
                    <td className="detailconsolecontent" border="1">
                                        <span className="infovalue">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td className="dropdownlabel" valign="center">Score Shading </td>
                                                <td valign="center"><ScoreShadingDrop
                                                    selected={props.state.scoreShadingId}
                                                    changeFunction={props.changeFunction}/></td>
                                                <td className="dropdownlabel" valign="center">Stableford Display </td>
                                                <td valign="center"><StablefordDrop selected={props.state.stablefordId}
                                                                                    changeFunction={props.changeFunction1}/></td>
                                            </tr>
                                            {props.state.apiMessage.decoratedRounds.course &&
                                                <tr>
                                                    <td colSpan={3}>
                                                        <br/>

                                                        <button className={"stdButton"}
                                                                style={{verticalAlign: "middle"}} id="radio_roundview"
                                                                type="radio" name="roundview"
                                                                onClick={props.onClick1}>New Round
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button className={"stdButton"}
                                                                style={{verticalAlign: "middle"}} id="radio_roundview"
                                                                type="radio" name="roundview"
                                                                onClick={props.onClick2}>Edit Course Details
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button className={"stdButton"}
                                                                style={{verticalAlign: "middle"}} id="radio_roundview"
                                                                type="radio" name="roundview"
                                                                onClick={props.onClick3}>Delete Course
                                                        </button>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                        </span>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

    </div>;
}

RoundsFilterDesktop.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.any,
        accessLevel: PropTypes.string,
        scoreShadingId: PropTypes.string,
        stablefordId: PropTypes.string,
        error: PropTypes.any,
        courseId: PropTypes.any,
        apiMessage: PropTypes.shape( {
            decoratedRounds: PropTypes.any,
            pageNumber: PropTypes.number,
            pageSize: PropTypes.number,
            totalRecords:PropTypes.number,
            totalPages: PropTypes.number,
        }),
        showResult: PropTypes.bool
    }),
    friendFunction: PropTypes.func,
    courseFunction: PropTypes.func,
    onClick: PropTypes.func,
    changeFunction: PropTypes.func,
    changeFunction1: PropTypes.func,
    onClick1: PropTypes.func,
    onClick2: PropTypes.func,
    onClick3: PropTypes.func
};

export default RoundsFilterDesktop;
