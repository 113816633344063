import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import {useAuth0} from "@auth0/auth0-react";
import {getConfig} from "../config";
import {Link} from "react-router-dom";

function TastyNavBar() {

    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        isLoading
    } = useAuth0();

    const { appOrigin } = getConfig();

    const logoutWithRedirect = () =>
        logout({ logoutParams: { returnTo: appOrigin } })

    if (isLoading) {
        return null; // or return a loading spinner here
    }

    return (
        <Navbar bg="transparent" expand="md">
            <Navbar.Toggle aria-controls="basic-navbar-nav"  />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {window.location.pathname !== '/' && (<Nav.Link as={Link} to="/">Home</Nav.Link>)}
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/courses">Courses</Nav.Link>
                    )}
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/view-rounds?courseId=-1">Rounds</Nav.Link>
                    )}
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/statistics">Stats</Nav.Link>
                    )}
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/friends">Friends</Nav.Link>
                    )}
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/handicap">Handicap</Nav.Link>
                    )}
                    {!isAuthenticated && (
                        <>
                            <Nav.Link as={Link} to="/help">Help</Nav.Link>
                            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        </>
                    )}

                    {!isAuthenticated && (
                        <>
                            <Nav.Link
                                id={"navbar-register"}
                                onClick={() => loginWithRedirect({authorizationParams: {
                                    redirectUri: `${getConfig().appOrigin}/courses`
                                    }}
                                )}
                                href="/courses">Register
                            </Nav.Link>
                            <Nav.Link
                                onClick={() => loginWithRedirect({authorizationParams: {
                                        redirectUri: `${getConfig().appOrigin}/courses`
                                    }}
                                )}
                                href="/courses" className={"tasty-btn"}>Sign&nbsp;In
                            </Nav.Link>
                        </>
                    )}

                    {isAuthenticated && (
                        <NavDropdown title="More" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/export">Export</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/help">Help</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/contact">Contact</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/premium">Premium</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => logoutWithRedirect()}
                                as={Link} to="/">Sign out
                            </NavDropdown.Item>
                        </NavDropdown>
                    )}

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}


export default TastyNavBar;
