import React, {Fragment} from "react";

import {CookieConsent} from "react-cookie-consent";
import TastyHero from "../components/TastyHero";

const TastyHome = () => {

    document.body.style.backgroundColor = '#e5eedf';

    return (
        <Fragment>
            <TastyHero />
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </Fragment>
    );
}

export default TastyHome;
