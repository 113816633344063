import React from "react";

export const FriendDropRow = ({ friend }) => {

     return (
         <option value={friend.friendId}>
             {friend.realName}
         </option>
     )
}

export default FriendDropRow;
