import React from "react";

import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Footer from "../../components/Footer";

export const CookiePolicyComponent = () => {

    return (

        <>
        <div className="static">
            <Helmet>
                <title>Golfing Record | Cookie Policy</title>
            </Helmet>

            <h1>Cookie Policy</h1>

            <p>Last updated: September 15th 2023</p>
            <p>This Cookie Policy explains how we use cookies on Our Service. Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used to ensure the website functions properly, as well as to provide web services and functionalities for the users.</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h2>Definitions</h2>
            <p>For the purposes of this Cookie Policy:</p>
            <ul>
                <li>
                    <p><strong>Service</strong> refers to the website operated by Golfing Record.</p>
                </li>
                <li>
                    <p><strong>Cookies</strong> means small data files that are stored on Your computer or device when You use the Service.</p>
                </li>
            </ul>

            <h1>Type of Cookies Used</h1>
            <p>We use cookies for multiple purposes:</p>
            <ul>
                <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service, particularly to enable logging in and maintaining sessions. These cookies are essential for the website to function and cannot be switched off without affecting the use of the site.</li>
                <li><strong>Advertising Cookies.</strong> We use cookies to display relevant advertising to you through Google AdSense. These cookies collect information about your browsing habits to make advertising more engaging for you. They remember the websites you have visited and share this information with advertisers.</li>
            </ul>

            <h1>Your Choices Regarding Cookies</h1>
            <p>If You prefer to avoid the use of Cookies on the Service, You must first disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>
            <p>If You do not accept our Cookies, You may experience some inconvenience in your use of the Service and some features may not function properly.</p>

            <p>For more information on cookies and how to manage them, you can consult the third-party websites, such as <a href="https://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">allaboutcookies.org</a>.</p>

        </div>
            <Footer/>

        </>
    );
};

export default CookiePolicyComponent;
