import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getConfig} from "../../config";
import {renderLocalDateOrBlank} from "../../utils/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import pRetry from "p-retry";
import {retryFetchJson, retryOptions} from "../../utils/Net";

export const CourseRowDesktop = ({ course, courseOwnerId, accessLevel }) => {
    const navigate = useNavigate();

    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {
            rounds: 0,
            fullRounds: 0,
            lastRound: 0,
            underOverCount: [0, 9, 0, 0, 0, 0, 0, 0, 0],
            holesPlayed: 0,
        },
        loaded: false,
        error: null,
    });

    const {
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {

        const callApi = async () => {
            try {
                const token = await getAccessTokenSilently();

                const responseData = await pRetry(() => retryFetchJson(`${apiOrigin}/courses/` + course.courseId, token), retryOptions);

                return {
                    ...state,
                    showResult: true,
                    apiMessage: responseData,
                };
            } catch (error) {
                return {
                    ...state,
                    error: error.error,
                };
            }
        };

        let cancel = false;
        callApi().then(stateData => {
            if (!cancel) {
                setState(stateData)
            }
        })

        return () => {
            cancel = true;
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function denied(type) {
        toast.error("You don't have permission to " + type + " this course.")
    }

    async function deleteCourse(course) {
        const token = await getAccessTokenSilently();

        if (window.confirm("Are you sure you want to delete course " + course.courseName + "?")) {
            fetch(apiOrigin + '/courses/' + course.courseId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    if (response.status === 204) {
                        window.location.reload();
                    } else {
                        denied("delete")
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    function mustDeleteAllRounds() {
        toast.error("You must remove all rounds at this course before you can delete it");
    }

    function deleteDenied() {
        denied("delete");
    }

    return (
        <>
        {state.showResult &&
                <tr>
                    <td nowrap="true" className="cell cellheader">
                        <button className={"button_as_link"} style={{'textDecoration': 'none', color: '#FFFFFF'}}
                                onClick={() => navigate("/view-rounds/?friendId=" + courseOwnerId + "&courseId=" + course.courseId)}>
                            {course.courseName}</button>
                    </td>
                    <td className="cell" align="center">{state.apiMessage.course.courseTeesName}</td>
                    <td className="cell celloldlight" align="center">{state.apiMessage.rounds}</td>
                    <td className="cell celloldlight" align="center">{state.apiMessage.fullRounds}</td>
                    <td className="cell celldate" align="center">{renderLocalDateOrBlank(state.apiMessage.lastRound)}</td>
                    {state.apiMessage.underOverCount ? (
                        <>
                            <td align="center" className="cell cellm4">{state.apiMessage.underOverCount[0]}</td>
                            <td align="center" className="cell cellalbatross">{state.apiMessage.underOverCount[1]}</td>
                            <td align="center" className="cell celleagle">{state.apiMessage.underOverCount[2]}</td>
                            <td align="center" className="cell cellbirdie">{state.apiMessage.underOverCount[3]}</td>
                            <td align="center" className="cell cellpar">{state.apiMessage.underOverCount[4]}</td>
                            <td align="center" className="cell cellbogey">{state.apiMessage.underOverCount[5]}</td>
                            <td align="center" className="cell celldoublebogey">{state.apiMessage.underOverCount[6]}</td>
                            <td align="center" className="cell cell3">{state.apiMessage.underOverCount[7]}</td>
                            <td align="center" className="cell cell4">{state.apiMessage.underOverCount[8]}</td>
                        </>
                        ) : (<td colSpan={9} align="center" className="cell">There was an error loading these details, please reload the page</td>)}
                    <td align="center" className="cell celloldlight" bgcolor="#CCFFCC">3</td>

                    <td align="center" className="cell cellicon">
                        {accessLevel === "WRITE" && state.apiMessage.rounds === 0 ?
                            <button className={"button_as_link"} onClick={() => deleteCourse(course)}>Delete</button>
                            : (accessLevel === "READ" ?
                                    <button className={"button_as_link"} onClick={deleteDenied}
                                            style={{opacity: 0.3}}>Delete</button>
                                    : <button className={"button_as_link"} onClick={mustDeleteAllRounds}
                                              style={{opacity: 0.3}}>Delete</button>
                            )
                        }
                    </td>
                    <td align="center" className="cell cellicon">
                        {(accessLevel === "WRITE" &&
                                <button className={"button_as_link"}
                                        onClick={() => navigate("/edit-course/" + course.courseId)}>Edit</button>) ||
                            <button className={"button_as_link"} onClick={() => denied("edit")}
                                    style={{opacity: 0.3}}>Edit</button>
                        }
                    </td>
                    <td align="center" className="cell cellicon">
                        {(accessLevel === "WRITE" &&
                                <button className={"button_as_link"}
                                        onClick={() => navigate("/new-round/" + course.courseId)}>Add Round</button>) ||
                            <button className={"button_as_link"} onClick={() => denied("add a round at")}
                                    style={{opacity: 0.3}}>Add Round</button>
                        }
                    </td>
                </tr>
        }
        </>
     )
}

export default CourseRowDesktop;
