import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import ContentHeader from "../components/ContentHeader";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { getConfig } from "../config";
import ConsoleLoading from "../components/ConsoleLoading";
import { subscriptionData } from "../utils/Net";
import * as PropTypes from "prop-types";

function PricingPlan(props) {
  return <div className={`plan${props.highlightText ? " highlighted" : ""}`}>
    <h2>{props.title}</h2>
    <h3>{props.price}</h3>
    <div className={"subscription-benefits"}>
      <ul style={{lineHeight: "40px", marginTop: "10px", marginBottom: "10px"}}>
        {props.benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
        ))}
      </ul>
      <div class={"footnote"}>
      * When adding friends' rounds from your own account
      </div>
    </div>
    {props.highlightText && (
        <div className="best-value">{props.highlightText}</div>
    )}

    {props.buttonText &&
    <div id="monthly-button">
          <button
              className={"btn btn-primary btn-subscribe"}
              onClick={props.onClick}
              type="primary"
              css={{width: "fill", alignX: "center"}}
          >
            {props.buttonText}
          </button>
    </div>
    }

  </div>;
}

PricingPlan.propTypes = {
  options: PropTypes.any,
  onClick: PropTypes.func
};

export const CreditsComponent = () => {
  const { isLoading, getAccessTokenSilently, error } = useAuth0();

  const [optionsPortalSession, setOptionsPortalSession] = useState(null);
  const [optionsPremium, setOptionsPremium] = useState(null);
  const [optionsFriends, setOptionsFriends] = useState(null);
  const [optionsSociety, setOptionsSociety] = useState(null);
  const [adminStats, setAdminStats] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);

  useEffect(() => {
    const { apiOrigin, appOrigin} = getConfig();

    console.log("Client version: " + process.env.REACT_APP_GOLFINGRECORD_CLIENT_VERSION);
    console.log("Environment: " + process.env.NODE_ENV);
    console.log("API Origin: " + apiOrigin);
    console.log("App Origin: " + appOrigin);

    getAccessTokenSilently().then((token) => {
      subscriptionData(token).then((data) => {
        setSubscriptionType(data.subscriptionType);

        if (isAdmin(data.subscriptionType)) {
          fetch(`${apiOrigin}/admin/stats`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => {
            res.json().then((data) => {
              setAdminStats(data);
            });
          });
        }

        if (isMonthly(data.subscriptionType)) {
          fetch(`${apiOrigin}/payments/billing_portal_session`, {
            method: "GET", headers: { Authorization: `Bearer ${token}`},
          }).then((res) => {
            res.json().then((data) => {
              setOptionsPortalSession({ id: data.id, url: data.url }); });
          });
        }

        if (isFree(data.subscriptionType)) {
          fetch(`${apiOrigin}/payments/checkout_session?subscriptionType=premium`, {
            method: "GET", headers: { Authorization: `Bearer ${token}` },
          }).then((res) => {
            res.json().then((data) => {
              setOptionsPremium({ id: data.sessionId, url: data.checkoutUrl }); });
          });
          fetch(`${apiOrigin}/payments/checkout_session?subscriptionType=friends`, {
            method: "GET", headers: { Authorization: `Bearer ${token}` },
          }).then((res) => {
            res.json().then((data) => { setOptionsFriends({ id: data.sessionId, url: data.checkoutUrl }); });
          });
          fetch(`${apiOrigin}/payments/checkout_session?subscriptionType=society`, {
            method: "GET", headers: { Authorization: `Bearer ${token}` },
          }).then((res) => {
            res.json().then((data) => { setOptionsSociety({ id: data.sessionId, url: data.checkoutUrl }); });
          });
        }
      });
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  function onSubmit(options) {
    window.location.href = options.url;
  }

  function isMonthly(subscriptionType) {
    return subscriptionType === "SUBSCRIBER" || subscriptionType === "PREMIUM_SUBSCRIBER" || subscriptionType === "FRIENDS_SUBSCRIBER" || subscriptionType === "SOCIETY_SUBSCRIBER";
  }

  function isFree(subscriptionType) {
    return subscriptionType === "FREE" || subscriptionType === "FREE_LEGACY"
  }

  function isAdmin(subscriptionType) {
    return subscriptionType === "ADMIN"
  }

  return (
    <div className="console">
      <ContentHeader label={"Premium Subscription"} />

      {subscriptionType ? (
        <div className="detailconsolecontent">
          {!isFree(subscriptionType) ? (
              <>
                <h4>Your Subscription</h4>
                {isMonthly(subscriptionType) ? (
                    optionsPortalSession &&
                        <>
                        <div className="my-pricing-table">
                          <div className="pricing-table">
                            <PricingPlan
                                title={"Premium Solo"}
                                price={"$3/month"}
                                benefits={["Unlimited rounds", "-"]}
                                highlightText={subscriptionType === "PREMIUM_SUBSCRIBER" || subscriptionType === "SUBSCRIBER" ? "Current Plan" : ""}
                                buttonText={subscriptionType === "PREMIUM_SUBSCRIBER" || subscriptionType === "SUBSCRIBER" ? "Manage Subscription" : ""}
                                onClick={() => {window.location.href = optionsPortalSession.url}}/>

                            <PricingPlan
                                title={"Premium Friends"}
                                price={"$6/month"}
                                benefits={["Unlimited rounds", "Unlimited rounds for 5 friends *"]}
                                highlightText={subscriptionType === "FRIENDS_SUBSCRIBER" ? "Current Plan" : ""}
                                buttonText={subscriptionType === "FRIENDS_SUBSCRIBER" ? "Manage Subscription" : ""}
                                onClick={() => {window.location.href = optionsPortalSession.url}}/>

                            <PricingPlan
                                title={"Society"}
                                price={"$15/month"}
                                benefits={["Unlimited rounds", "Unlimited rounds for 30 friends *"]}
                                highlightText={subscriptionType === "SOCIETY_SUBSCRIBER" ? "Current Plan" : ""}
                                buttonText={subscriptionType === "SOCIETY_SUBSCRIBER" ? "Manage Subscription" : ""}
                                onClick={() => {window.location.href = optionsPortalSession.url}}/>

                          </div>
                        </div>
                        </>
                ) : (
                    <>
                      <div className="my-pricing-table">
                      <div className="pricing-table">
                          <PricingPlan
                              title={"Lifetime"}
                              price={"$0/month"}
                              benefits={["Unlimited rounds", "Unlimited rounds for 30 friends *"]}
                              highlightText={"Current Plan"}
                              buttonText={""}
                              onClick={() => {window.location.href = optionsPortalSession.url}}/>
                      </div>
                    </div></>
                )}
              </>
          ) : (
              <>
              <div className="my-pricing-table">
                <div className="pricing-table">
                  {optionsPremium &&
                  <PricingPlan
                      title={"Premium Solo"}
                      price={"$3/month"}
                      benefits={["Unlimited rounds", "-"]}
                      highlightText={""}
                      buttonText={"Buy with Stripe"}
                      onClick={() => onSubmit(optionsPremium)}/>
                  }

                  {optionsFriends && <PricingPlan
                      title={"Premium Friends"}
                      price={"$6/month"}
                      benefits={["Unlimited rounds", "Unlimited rounds for 5 friends *"]}
                      highlightText={""}
                      buttonText={"Buy with Stripe"}
                      onClick={() => onSubmit(optionsFriends)}/>
                  }

                  {optionsSociety && <PricingPlan
                      title={"Society"}
                      price={"$15/month"}
                      benefits={["Unlimited rounds", "Unlimited rounds for 30 friends *"]}
                      highlightText={""}
                      buttonText={"Buy with Stripe"}
                      onClick={() => onSubmit(optionsSociety)}/>
                  }
                </div>
                <div className="additional-info">
                  Hassle-free cancellation at any time.
                  <br/>
                  We use{" "}
                  <a
                      href="https://stripe.com/"
                      rel="noreferrer"
                      target="_blank"
                  >
                    Stripe
                  </a>{" "}
                  to process payments securely.<br/>
                  You will be able to access your Stripe dashboard from this page to cancel your subscription.
                </div>
              </div>


              </>
          )}
          {isAdmin(subscriptionType) && adminStats != null && (
              <>
                <h4>Admin Stats</h4>
                Users = {adminStats.users}, Courses = {adminStats.courses}, Rounds
                = {adminStats.rounds}, Feedback = {adminStats.feedback}
                <table className={"feedback"}>
                  <tbody>
                  {adminStats.feedbackItems &&
                      Array.from(adminStats.feedbackItems).map((feedback) => (
                          <tr key={feedback.id}>
                            <td>{feedback.feedbackAuthorEmail}</td>
                            <td>{feedback.feedbackContent}</td>
                          </tr>
                      ))}
                  </tbody>
                </table>
                <a href={"/course-database"}>Course Database</a>
              </>
          )}
        </div>
      ) : (
        <ConsoleLoading />
      )}
    </div>
  );
};

export default withAuthenticationRequired(CreditsComponent, {
  onRedirecting: () => <Loading />,
});

