import TastyNavBar from "./TastyNavBar";
import React from "react";

const LogoNavAndBushes  = () =>
        <>
            <div className="tasty-site-logo">
                <img src="/img/tasty/1x/logo.png" alt="Golfing Record Logo"/>
            </div>

            <div className="tasty-navbar-container">
                <TastyNavBar/>
            </div>

            <img className={"tasty-bush tasty-bush1"} src={"/img/tasty/1x/bush1.png"} alt={"Bush1"}/>
            <img className={"tasty-bush tasty-bush2"} src={"/img/tasty/1x/bush2.png"} alt={"Bush2"}/>
            <img className={"tasty-bush tasty-bush3"} src={"/img/tasty/1x/bush3.png"} alt={"Bush3"}/>
            <img className={"tasty-bush tasty-bush4"} src={"/img/tasty/1x/bush4.png"} alt={"Bush4"}/>
            <img className={"tasty-bush tasty-bush5"} src={"/img/tasty/1x/bush5.png"} alt={"Bush5"}/>

            <div className="special-element"></div>

        </>


export default LogoNavAndBushes;