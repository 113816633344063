import * as PropTypes from "prop-types";
import React from "react";

function PercentageByPar(props) {
    return <table className="filledtable" border="0" style={{width:"100%"}}>
        <tbody>
        <tr>
            <td align="center" className="cell cellheader"
                height="20">&nbsp;</td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Eagle
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Birdie
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Par
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Bogey
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">D Bogey
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">3 Over
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">4+ Over
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 3
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par3.eagle.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par3.eagle.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par3.birdie.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par3.birdie.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par3.par.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par3.par.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par3.bogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par3.bogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par3.doubleBogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par3.doubleBogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par3.threeOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par3.threeOver.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par3.fourPlusOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par3.fourPlusOver.cumulative.toFixed(2)}
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 4
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par4.eagle.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par4.eagle.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par4.birdie.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par4.birdie.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par4.par.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par4.par.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par4.bogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par4.bogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par4.doubleBogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par4.doubleBogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par4.threeOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par4.threeOver.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par4.fourPlusOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par4.fourPlusOver.cumulative.toFixed(2)}
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 5
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par5.eagle.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.percentageByPar.par5.eagle.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par5.birdie.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.percentageByPar.par5.birdie.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par5.par.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.percentageByPar.par5.par.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par5.bogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.percentageByPar.par5.bogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par5.doubleBogey.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.percentageByPar.par5.doubleBogey.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par5.threeOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.percentageByPar.par5.threeOver.cumulative.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par5.fourPlusOver.total.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.percentageByPar.par5.fourPlusOver.cumulative.toFixed(2)}
            </td>
        </tr>
        </tbody>
    </table>;
}

PercentageByPar.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.number,
        dateTo: PropTypes.any,
        dateFrom: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.number,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    })
};

export default PercentageByPar;