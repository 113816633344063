import React, {useEffect} from 'react';
import { Route, BrowserRouter, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import Home from './views/Home';
import Profile from './views/Profile';
import MyCourses from './views/MyCourses';
import Contact from './views/static/Contact';
import Privacy from "./views/static/Privacy";
import Terms from "./views/static/Terms";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from './config';
import { ToastContainer } from 'react-toastify';

// styles
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';
import MyRounds from './views/MyRounds';
import NewCourse from './views/NewCourse';
import Friends from './views/Friends';
import Export from './views/Export';
import Statistics from './views/Statistics';
import Credits from './views/Credits';
import Handicap from './views/Handicap';
import EditRound from './views/EditRound';
import CreditsSuccess from './views/CreditsSuccess';

import CompareRounds from './views/CompareRounds';
import Help from './views/Help';
import ReferenceCourse from "./views/ReferenceCourse";
import ReactGA from "react-ga4";
import TastyHome from "./views/TastyHome";
import TastyHeader from "./components/TastyHeader";
import CookiePolicy from "./views/static/CookiePolicy";
import EditReferenceCourse from "./ReferenceDatabase/EditReferenceCourse";
import ReferenceAdmin from "./ReferenceDatabase/ReferenceAdmin";

initFontAwesome();

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

ReactGA.initialize("G-20MB74NRLW");

class RoutesContainer extends React.Component {
  render() {
    return <Routes>
      <Route path="/" element={<TastyHome/>}/>
      <Route path="/tasty" element={<Home/>}/>
      <Route path="/profile" element={<ProtectedRoute component={Profile}/>}/>
      <Route path="/golf-scorecard/:courseId" element={<ReferenceCourse/>}/>
      <Route path="/courses" element={<ProtectedRoute component={MyCourses}/>}/>
      <Route path="/view-rounds" element={<ProtectedRoute component={MyRounds}/>}/>
      <Route path="/compare-rounds/:roundId" element={<ProtectedRoute component={CompareRounds}/>}/>
      <Route path="/new-course/:friendId" element={<ProtectedRoute component={NewCourse}/>}/>
      <Route path="/edit-course/:courseId" element={<ProtectedRoute component={NewCourse}/>}/>
      <Route path="/edit-round/:roundId" element={<ProtectedRoute component={EditRound}/>}/>
      <Route path="/new-round/:courseId" element={<ProtectedRoute component={EditRound}/>}/>
      <Route path="/friends" element={<ProtectedRoute component={Friends}/>}/>
      <Route path="/export" element={<ProtectedRoute component={Export}/>}/>
      <Route path="/statistics" element={<ProtectedRoute component={Statistics}/>}/>
      <Route path="/help" element={<Help/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/cookies" element={<CookiePolicy/>}/>
      <Route path="/reference" element={<ProtectedRoute component={ReferenceAdmin}/>}/>
      <Route path="/terms" element={<Terms/>}/>
      <Route path="/edit-reference-course/:courseId" element={<ProtectedRoute component={EditReferenceCourse}/>}/>
      <Route path="/premium" element={<ProtectedRoute component={Credits}/>}/>
      <Route path="/premium/success" element={<ProtectedRoute component={CreditsSuccess}/>}/>
      <Route path="/handicap" element={<ProtectedRoute component={Handicap}/>}/>
    </Routes>;
  }
}

const MainContent = () => {
  const location = useLocation();

  return (
      <div id="app">
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <Container>
          { (location.pathname !== "/" && <TastyHeader>
            <RoutesContainer/>
          </TastyHeader>) || <RoutesContainer/>
          }

        </Container>
      </div>
  );
};


const App = () => {

  const config = getConfig();
  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      ...(config.audience ? { audience: config.audience } : null),
      redirect_uri: window.location.origin,
      scope: "openid profile email read:v2-reference-courses"
    }
  };

  useEffect(() => {
    ReactGA.send("pageview");
  }, [])

  console.log(window.location.href)

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback {...providerConfig}>
        <MainContent />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
};

export default App;
