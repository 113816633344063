import React from 'react';
import { SearchResult } from '../types/Types';

const SearchResults = (props: {
  searchResults: SearchResult[];
  selectCourseFunc: (courseId: number) => void;
  showUsername: boolean;
}) => {

  return (
      <div style={{width: '100%', height: '12em', overflow: 'auto'}}>
        <table className={'searchResults'}>
          <thead>
          <tr>
            <th>Club</th>
            <th>Course</th>
            <th>Location</th>
            <th>Holes</th>
            {props.showUsername && <th>User</th>}
          </tr>
          </thead>
          <tbody>
          {props.searchResults.length === 0 && (
                <tr>
                    <td colSpan={5}>No courses were found for the given search term.<br/><br/>You can add the course manually below, or let us know and we will get the course added to the database.</td>
                </tr>
            )}
          {Array.from(props.searchResults).map((course) => (
              <tr key={course.courseId}>
                <td>{course.clubName}</td>
                <td key={course.courseId} style={{whiteSpace: 'nowrap'}}>
                  <button
                      key={course.courseId}
                      className={'button_as_link'}
                      onClick={() => props.selectCourseFunc(course.courseId)}
                  >
                    {course.courseName}
                  </button>
                </td>
                <td style={{whiteSpace: 'nowrap'}}>{course.geoName}</td>
                <td>{course.numberOfHoles}</td>
                {props.showUsername && <td>{course.userId}</td>}
              </tr>
          ))}
          </tbody>
        </table>
      </div>
  );
}

export default SearchResults;
