import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import ContentHeader from "../components/ContentHeader";
import Multiselect from 'multiselect-react-dropdown';
import { saveAs } from 'file-saver';

export const ExportComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {},
        friends: [],
        loaded: false,
        error: null,
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();

    const multiselectRef = React.createRef();

    useEffect(() => {
        getState().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState() {
        try {
            const token = await getAccessTokenSilently();

            const response = await fetch(`${apiOrigin}/my-friends?excludeNoRounders=true&excludePending=true&includeMe=true`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            responseData.friends.forEach(friend => {
                state.friends.push({name: friend.name, id: friend.friendId})
            })

            return {
                ...state,
                showResult: true,
                apiMessage: responseData,
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    async function exportData() {
        const csv = multiselectRef.current.getSelectedItems().map(friend => {
            return friend.id
        }).join(",")

        const token = await getAccessTokenSilently();

        const response = await fetch(`${apiOrigin}/export?friendList=${csv}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                ContentType: "text/csv"
            },
        });

        saveAs(new Blob([await response.blob()], {type: "text/csv;charset=utf-8"}), "export.csv")

    }

    return (

        <div className="console">
<>
                        <ContentHeader label={'Export Your Data'}/>
                                    <div className="detailconsolecontent">
                                        <span className="infovalue">
                                        Use this page to export yours and your friends' rounds, courses and statistics.
                                        </span>
                                    </div>
                                    <div className="detailconsolecontent">

                                                    Friends
                                                    {state.apiMessage.friends &&
                                                        <Multiselect
                                                            options={state.friends}
                                                            ref={multiselectRef}
                                                            selectedValues={state.friends}
                                                            showCheckbox={true}
                                                            closeOnSelect={false}
                                                            avoidHighlightFirstOption={true}
                                                            displayValue="name"
                                                        />
                                                    }

                                    </div>
    <div className="detailconsolecontent">
                                    Select the friends whose details you would like to export and click
                                        the button below. A CSV file (compatible with most spreadsheets) will be created
                                        containing all your course and round details.
                                </div>
    <div className="detailconsolecontent">
                                        <input onClick={exportData} className="stdbutton" id="export_button" type="submit" value="Export" />
                                </div>
</>
        </div>

    );
};

export default withAuthenticationRequired(ExportComponent, {
    onRedirecting: () => <Loading />,
});
