import React from "react";
import LogoNavAndBushes from "./LogoNavAndBushes";

const TastyHeader = (props) => (

    <>
     <div className="tasty-header">

        <div className={"tasty-home-background"}>

            <LogoNavAndBushes />

            <div class="tasty-content">
                {props.children}
            </div>

        </div>
    </div>
    </>
);

export default TastyHeader;
