import {renderLocalDateOrBlank, renderTeeName} from "../../utils/Utils";
import * as PropTypes from "prop-types";
import React from "react";

function MainTable(props) {
    return(
            <table className="cleanTable">
                <thead>
                <tr key={"header"}>
                    <th>Date</th>
                    <th>Tees</th>
                    <th>Course</th>
                    <th>Par</th>
                    <th>SSS</th>
                    <th>Slope</th>
                    <th>Strokes</th>
                    <th>Over SSS</th>
                    <th>Stroke Adjusted</th>
                    <th>Slope Adjusted</th>
                    {props.state.apiMessage.handicapSystem < 1000 &&
                        <th>96%</th>
                    }
                </tr>

                </thead>
                        <tbody>
                        {(props.state.apiMessage.rounds && props.state.apiMessage.rounds.length > 0) &&
                            Array.from(props.state.apiMessage.rounds).map(round =>
                                <tr key={round.roundId}>
                                    <td>{renderLocalDateOrBlank(round.roundDate)}</td>
                                    <td>{renderTeeName(round.tees)}</td>
                                    <td>{round.courseName}</td>
                                    <td>{round.par}</td>
                                    <td>{round.standardScratchScore}</td>
                                    <td>{round.slope.toFixed(1)}</td>
                                    <td>{round.strokes}</td>
                                    <td>{round.overSSS.toFixed(1)}</td>
                                    <td>{round.strokeAdjusted.toFixed(1)}</td>
                                    <td>{round.slopeAdjusted.toFixed(1)}</td>
                                    {props.state.apiMessage.handicapSystem < 1000 &&
                                        <td>{round.ninetySixPercent.toFixed(1)}</td>
                                    }
                                </tr>
                            )}
                        <tr key={"footer"}>
                            <td colSpan={2}>Averages</td>
                            <td>&nbsp;</td>
                            <td>{props.state.apiMessage.averagePar.toFixed(1)}</td>
                            <td>{props.state.apiMessage.averageSSS.toFixed(1)}</td>
                            <td>{props.state.apiMessage.averageSlope}</td>
                            <td>{props.state.apiMessage.averageStrokes}</td>
                            <td>{props.state.apiMessage.averageOverSSS.toFixed(1)}</td>
                            <td>{props.state.apiMessage.averageStrokeAdjusted.toFixed(1)}</td>
                            <td>{props.state.apiMessage.averageSlopeAdjusted.toFixed(1)}</td>
                            {props.state.apiMessage.handicapSystem < 1000 &&
                                <td>{props.state.apiMessage.averageNinetySixPercent.toFixed(1)}</td>
                            }
                        </tr>
                        </tbody>
                    </table>
    )
}

MainTable.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.any,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    }),
    callbackfn: PropTypes.func
};

export default MainTable
