import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ apiMessage, pageChangeFunction, pageSizeFunction }) => {
    const { pageNumber, pageSize, totalRecords, totalPages } = apiMessage;

    const handlePageSizeChange = (event) => {
        pageSizeFunction(event.target.value);
    };

    const handlePageChange = (newPageNumber) => {
        pageChangeFunction(newPageNumber);
    };

    return (
        <div className="pagination-controls">
            <div>Page {pageNumber} of {totalPages}</div>
            <div className="button-container">
                <button onClick={() => handlePageChange(1)} disabled={pageNumber === 1}>First</button>
                <button onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>Previous</button>
                <button onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>Next</button>
                <button onClick={() => handlePageChange(totalPages)} disabled={pageNumber === totalPages}>Last</button>
            </div>
            <div>
                Page size:
                <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div>Total records: {totalRecords}</div>
        </div>
    );
};

Pagination.propTypes = {
    apiMessage: PropTypes.shape({
        pageNumber: PropTypes.number,
        pageSize: PropTypes.number,
        totalRecords: PropTypes.number,
        totalPages: PropTypes.number,
    }).isRequired,
    pageChangeFunction: PropTypes.func.isRequired,
    pageSizeFunction: PropTypes.func.isRequired,
};

export default Pagination;