import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import ContentHeader from "../components/ContentHeader";
import ConsoleLoading from "../components/ConsoleLoading";
import Filters from "../components/Handicap/Filters";
import MainTable from "../components/Handicap/MainTable";
import {checkVersion} from "../utils/Net";
export const HandicapComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {},
        error: null,
        loaded: false,
        courseId: null,
        friendId: null,
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();

    useEffect(() => {
        checkVersion();

        getState().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState(friendId, courseId, handicapSystem, useEsc) {

        try {
            const token = await getAccessTokenSilently();

            let uri = `${apiOrigin}/handicap?d=1`;

            if (friendId != null) {
                uri += `&friendId=${friendId}`;
            }

            if (courseId != null) {
                uri += `&courseId=${courseId}`;
            }

            if (handicapSystem != null) {
                uri += `&handicapSystem=${handicapSystem}`;
            }

            if (useEsc != null) {
                uri += `&useEsc=${useEsc}`;
            }

            const response = await fetch(uri, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            return {
                ...state,
                showResult: true,
                apiMessage: responseData,
                friendId: friendId,
                courseId: courseId
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    function changeFriend(event) {
        getState(event.target.value, state.courseId, null, state.apiMessage.useEsc).then(stateData => {
            setState(stateData)
        })
    }

    function changeCourse(event) {
        getState(state.friendId, event.target.value, state.apiMessage.handicapSystem, state.apiMessage.useEsc).then(stateData => {
            setState(stateData)
        })
    }

    function changeHandicapSystem(event) {
        getState(state.friendId, state.courseId, event.target.value, state.apiMessage.useEsc).then(stateData => {
            setState(stateData)
        })
    }

    function changeEsc() {
        getState(state.friendId, state.courseId, state.apiMessage.handicapSystem, !state.apiMessage.useEsc).then(stateData => {
            setState(stateData)
        })
    }

    return (
        <div className="console">
            {!state.showResult &&
                <ConsoleLoading/>
            }

            {state.showResult && (
<>
        <ContentHeader label={'Handicap'}/>
        <Filters friendFunction={changeFriend} state={state} courseFunction={changeCourse}
                 changeFunction={changeHandicapSystem} onChange={changeEsc}/>
        <div className={"detailconsolecontent"}>
        <MainTable state={state}/>
        </div>
</>
                )}
        </div>

    );
};

export default withAuthenticationRequired(HandicapComponent, {
    onRedirecting: () => <Loading />,
});
