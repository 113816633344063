import React, {useEffect, useState} from "react";
import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import CourseDropRow from "./CourseDropRow";
import {Course} from "../model/Course";

type Props = {
    selected: number,
    friendId: number,
    courseFunction: (event: React.SyntheticEvent) => void,
}

type State = {
    apiMessage: ApiMessage,
    showResult: boolean,
    loaded: boolean,
    error: string
}

type ApiMessage = {
    courses: Course[]
}

export const CourseDrop = ({ selected, friendId, courseFunction }: Props) => {

    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState<State>({
        showResult: false,
        apiMessage: {} as ApiMessage,
        loaded: false,
        error: "",
    });

    const {
        getAccessTokenSilently,
    } = useAuth0();

    useEffect(() => {
        getState(friendId).then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [friendId]);

    async function getState(friendId: number) {
        try {
            const token = await getAccessTokenSilently();

            let uri = `${apiOrigin}/courses/summary`;
            if (friendId !== 0) {
                uri += "?friendId=" + friendId;
            }
            const response = await fetch(uri, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            return {
                ...state,
                showResult: true,
                apiMessage: responseData,
            };
        } catch (error: any) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    return (
        <select value={selected} className="dropdown" onChange={courseFunction} name="changecourse">
            <option value={-1}>All courses...</option>
            {state.apiMessage.courses !== undefined && Array.from(state.apiMessage.courses).map(course =>
                    <CourseDropRow key={course.courseId} course={course}/>
                )
            }
        </select>
    );
}

export default CourseDrop;
