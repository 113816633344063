import moment from 'moment';

export const zeroPad = (i: number) => {
  if (i < 10) {
    return '0' + i;
  } else {
    return i;
  }
};

const renderLocalDate = (d: Date) => {
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  } as const;
  return new Intl.DateTimeFormat('en-GB', options).format(d).replace('Sept', 'Sep');
};

export const renderLocalDateOrBlank = (dateArray?: number[]) => {
  return dateArray
    ? renderLocalDate(moment(dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2], 'YYYY-MM-DD').toDate())
    : '-';
};

export const dateFromArray = (dateArray?: number[]) => {
  if (dateArray === undefined) {
    return new Date();
  }
  return moment(dateArray[0] + '/' + zeroPad(dateArray[1]) + '/' + zeroPad(dateArray[2]), 'YYYY/MM/DD').toDate();
};

export const parseToDateArray = (date: Date) => {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate(), 0, 0];
};

export const getScoreClass = (score: number) => {
  if (score >= 5) return 'cellalbatross';

  switch (score) {
    case 5:
      return 'cellalbatross';
    case 4:
      return 'celleagle';
    case 3:
      return 'cellbirdie';
    case 2:
      return 'cellpar';
    case 1:
      return 'cellbogey';
    case 0:
      return 'celldoublebogey';
    default:
      return 'celldoublebogey';
  }
};

export const renderTee = (tee: number) => {
  return 'url(/img/ui/tee_' + tee + '.gif)';
};

export const numTees = 10;
export const renderTeeName = (tee: number) => {
  switch (tee) {
    case 1:
      return 'Black';
    case 2:
      return 'Blue';
    case 3:
      return 'White';
    case 4:
      return 'Yellow';
    case 5:
      return 'Red';
    case 6:
      return 'Purple';
    case 7:
      return 'Orange';
    case 8:
      return 'Teal';
    case 9:
      return 'Green';
    default:
      return 'Unspecified';
  }
};

export const getTeeId = (teeColour: string) => {
  switch (teeColour.toLowerCase()) {
    case 'black':
      return 1;
    case 'blue':
      return 2;
    case 'white':
      return 3;
    case 'yellow':
      return 4;
    case 'red':
      return 5;
    case 'purple':
      return 6;
    case 'orange':
      return 7;
    case 'teal':
      return 8;
    case 'green':
      return 9;
    default:
      return 0;
  }
};

export const orZero = (value: number): number => {
  if (value === undefined || value === null) {
    return 0;
  }
  return value;
};

export const orEmptyString = (value: string): string => {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
};
