import React, {useCallback, useEffect, useState} from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from '../config';
import Loading from '../components/Loading';
import ContentHeader from '../components/ContentHeader';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { dateFromArray, parseToDateArray } from '../utils/Utils';
import NotesModal from '../components/NotesModal';
import ConsoleLoading from '../components/ConsoleLoading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import pRetry from "p-retry";
import {retryFetchJson, retryOptions} from "../utils/Net";
import { useWindowSize } from "../components/Hooks/useWindowSize"
import * as PropTypes from "prop-types";

function EditRoundMobile(props) {
  return (
      <>
        {/* SSS and Slope Table */}
        <table className="normaltext">
          <tbody>
          <tr>
            <td className="cell cellheader">SSS (CI)</td>
            <td className="cell celloldlight" align="center">73.00</td>
          </tr>
          <tr>
            <td className="cell cellheader">Slope</td>
            <td className="cell celloldlight" align="center">N/A</td>
          </tr>
          </tbody>
        </table>

        {/* Main Golf Round Table */}
        <table className="normaltext">
          <tbody>
          {/* Headers */}
          <tr>
            <th>Hole</th>
            <th>Par</th>
            <th>S/I</th>
            <th>Strokes</th>
            <th>Putts</th>
            <th>Drive</th>
            <th>F/H</th>
            <th>Bunker</th>
            <th>Save</th>
            <th>Pens</th>
            <th>Notes</th>
          </tr>

          {/* Data Rows */}
          {Array.from(Array(18).keys()).map((i) => (
              <tr key={i}>
                {props.callbackfn(i)}
                {props.state.apiMessage.holes && props.callbackfn1(props.state.apiMessage.holes[i])}
                {props.state.apiMessage.holes && props.callbackfn2(props.state.apiMessage.holes[i])}
                {props.callbackfn3(i)}
                {props.callbackfn5(i)}
                {props.callbackfn6(i)}
                {props.callbackfn7(i)}
                {props.callbackfn8(i)}
                {props.callbackfn9(i)}
                {props.callbackfn10(i)}
                {props.callbackfn4(i)}
                {/* ... add more callback functions here */}
              </tr>
          ))}

          {/* Comments */}
          <tr>
            <td colSpan={11}>
                Comments
            </td>
          </tr>
          <tr>
            <td colSpan={11}>
              <textarea
                  onChange={props.onChange}
                  className="formel"
                  value={props.state.formValues["comments"]}
                  name="comments"
                  style={{
                    width: "100%",
                    rows: "4",
                    wrap: "virtual",
                  }}
              ></textarea>
            </td>
          </tr>
          </tbody>
        </table>
      </>
  );
}

function EditRoundDesktop(props) {
  return <table className="normaltext" width={"100%"}>
    <tbody>
    <tr>
      <td nowrap="" className="cell cellheader">
        SSS (CI)
      </td>
      <td className="cell celloldlight" align="center">
        73.00
      </td>
    </tr>
    <tr>
      <td className="cell cellheader">Slope</td>
      <td className="cell celloldlight" align="center">
        N/A
      </td>
    </tr>
    <tr>
      <td key="hole" className="cell celloldlight">
        Hole
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn)}
    </tr>
    <tr>
      <td key="par" className="cell celloldlight">
        Par
      </td>
      {props.state.apiMessage.holes &&
          Array.from(props.state.apiMessage.holes).map(props.callbackfn1)}
    </tr>
    <tr>
      <td key="si" className="cell celloldlight">
        Stroke Index
      </td>
      {props.state.apiMessage.holes &&
          Array.from(props.state.apiMessage.holes).map(props.callbackfn2)}
    </tr>
    <tr>
      <td height="20" className="cell cellheader">
        Strokes
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn3)}
    </tr>
    <tr>
      <td align="center" colSpan="19" height="20">
        &nbsp;
      </td>
    </tr>
    <tr>
      <td height="20" className="cell cellheader">
        Notes
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn4)}
    </tr>
    <tr>
      <td height="20" className="cell cellheader">
        Putts
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn5)}
    </tr>
    <tr>
      <td height="20" className="cell cellheader">
        Drive Yardage
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn6)}
    </tr>
    <tr>
      <td height="20" nowrap="" className="cell cellheader">
        Fairway hit
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn7)}
    </tr>
    <tr>
      <td height="20" nowrap="" className="cell cellheader">
        Bunker
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn8)}
    </tr>
    <tr>
      <td height="20" nowrap="" className="cell cellheader">
        Sand Save
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn9)}
    </tr>
    <tr>
      <td height="20" className="cell cellheader">
        Penalties
      </td>
      {Array.from(Array(18).keys()).map(props.callbackfn10)}
    </tr>
    <tr>
      <td className="cell cellheader">Comments</td>
      <td key="comments" colSpan="18">
                    <textarea
                        onChange={props.onChange}
                        className="formel"
                        value={props.state.formValues["comments"]}
                        name="comments"
                        style={{
                          width: "100%",
                          rows: "4",
                          wrap: "virtual",
                        }}
                    ></textarea>
      </td>
    </tr>
    </tbody>
  </table>;
}

EditRoundDesktop.propTypes = {
  callbackfn: PropTypes.func,
  state: PropTypes.shape({
    formValues: PropTypes.shape({}),
    loaded: PropTypes.bool,
    friendName: PropTypes.string,
    courseName: PropTypes.string,
    friendId: PropTypes.number,
    notesOpen: PropTypes.shape({
      11: PropTypes.bool,
      12: PropTypes.bool,
      13: PropTypes.bool,
      14: PropTypes.bool,
      15: PropTypes.bool,
      16: PropTypes.bool,
      17: PropTypes.bool,
      18: PropTypes.bool,
      1: PropTypes.bool,
      2: PropTypes.bool,
      3: PropTypes.bool,
      4: PropTypes.bool,
      5: PropTypes.bool,
      6: PropTypes.bool,
      7: PropTypes.bool,
      8: PropTypes.bool,
      9: PropTypes.bool,
      10: PropTypes.bool
    }),
    remainingRounds: PropTypes.number | PropTypes.string,
    error: PropTypes.any,
    apiMessage: PropTypes.shape({})
  }),
  callbackfn1: PropTypes.func,
  callbackfn2: PropTypes.func,
  callbackfn3: PropTypes.func,
  callbackfn4: PropTypes.func,
  callbackfn5: PropTypes.func,
  callbackfn6: PropTypes.func,
  callbackfn7: PropTypes.func,
  callbackfn8: PropTypes.func,
  callbackfn9: PropTypes.func,
  callbackfn10: PropTypes.func,
  onChange: PropTypes.func
};
export const EditRoundComponent = () => {
  const { apiOrigin = 'http://localhost:8080' } = getConfig();
  const navigate = useNavigate();
  const [width] = useWindowSize();

  const { getAccessTokenSilently, isLoading, error } = useAuth0();

  const [state, setState] = useState({
    apiMessage: {},
    formValues: {
        useForHandicap: true,
        roundDate: '1970-01-01',
        handicap: 0,
        comments: '',
        strokes_1: '', strokes_2: '', strokes_3: '', strokes_4: '', strokes_5: '', strokes_6: '', strokes_7: '', strokes_8: '', strokes_9: '', strokes_10: '', strokes_11: '', strokes_12: '', strokes_13: '', strokes_14: '', strokes_15: '', strokes_16: '', strokes_17: '', strokes_18: '',
        putts_1: '', putts_2: '', putts_3: '', putts_4: '', putts_5: '', putts_6: '', putts_7: '', putts_8: '', putts_9: '', putts_10: '', putts_11: '', putts_12: '', putts_13: '', putts_14: '', putts_15: '', putts_16: '', putts_17: '', putts_18: '',
        yards_1: '', yards_2: '', yards_3: '', yards_4: '', yards_5: '', yards_6: '', yards_7: '', yards_8: '', yards_9: '', yards_10: '', yards_11: '', yards_12: '', yards_13: '', yards_14: '', yards_15: '', yards_16: '', yards_17: '', yards_18: '',
        fairway_1: '', fairway_2: '', fairway_3: '', fairway_4: '', fairway_5: '', fairway_6: '', fairway_7: '', fairway_8: '', fairway_9: '', fairway_10: '', fairway_11: '', fairway_12: '', fairway_13: '', fairway_14: '', fairway_15: '', fairway_16: '', fairway_17: '', fairway_18: '',
        bunker_1: '', bunker_2: '', bunker_3: '', bunker_4: '', bunker_5: '', bunker_6: '', bunker_7: '', bunker_8: '', bunker_9: '', bunker_10: '', bunker_11: '', bunker_12: '', bunker_13: '', bunker_14: '', bunker_15: '', bunker_16: '', bunker_17: '', bunker_18: '',
        sand_1: '', sand_2: '', sand_3: '', sand_4: '', sand_5: '', sand_6: '', sand_7: '', sand_8: '', sand_9: '', sand_10: '', sand_11: '', sand_12: '', sand_13: '', sand_14: '', sand_15: '', sand_16: '', sand_17: '', sand_18: '',
        penalties_1: '', penalties_2: '', penalties_3: '', penalties_4: '', penalties_5: '', penalties_6: '', penalties_7: '', penalties_8: '', penalties_9: '', penalties_10: '', penalties_11: '', penalties_12: '', penalties_13: '', penalties_14: '', penalties_15: '', penalties_16: '', penalties_17: '', penalties_18: '',
        noteText_1: '', noteText_2: '', noteText_3: '', noteText_4: '', noteText_5: '', noteText_6: '', noteText_7: '', noteText_8: '', noteText_9: '', noteText_10: '', noteText_11: '', noteText_12: '', noteText_13: '', noteText_14: '', noteText_15: '', noteText_16: '', noteText_17: '', noteText_18: ''
    },
    notesOpen: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false,
      18: false,
    },
    error: null,
    loaded: false,
    friendId: 0,
    courseName: '',
    friendName: '',
    remainingRounds: 0,
  });

  const params = useParams();

  const roundId = params.roundId;
  const courseId = params.courseId;
  const isNewRound = roundId === undefined || roundId === null;
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [isStateLoaded, setIsStateLoaded] = useState(false);

  const handleSubmit = () => {
    setIsSubmitClicked(true);
  };

  const handleSubmitCallback = useCallback(async () => {
    let round = {
      useForHandicap: state.formValues['useForHandicap'],
      roundDate: state.formValues['roundDate'],
      handicap: state.formValues['handicap'] || 0,
      comments: state.formValues['comments'] || '',
      courseId: state.apiMessage.course.courseId,
      holes: [
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
        {
          holeNumber: 0,
          strokes: 0,
          putts: null,
          noteText: '',
          driveYards: 0,
          fairwayHit: -1,
          bunkerHit: -1,
          sandSave: -1,
          penalties: 0,
        },
      ],
    };

    console.log('roundDate: ' + state.formValues['roundDate']);

    Array.from(Array(18).keys()).forEach((k) => {
      let i = k + 1;
      round.holes[k].strokes = state.formValues['strokes_' + i];
      round.holes[k].putts = state.formValues['putts_' + i];
      round.holes[k].noteText = state.formValues['noteText_' + i] || '';
      round.holes[k].driveYards = state.formValues['yards_' + i];
      round.holes[k].fairwayHit = state.formValues['fairway_' + i] !== undefined ? state.formValues['fairway_' + i] : -1;
      round.holes[k].bunkerHit = resolveTriState(state.formValues['bunker_' + i]);
      round.holes[k].sandSave = resolveTriState(state.formValues['sand_' + i]);
      round.holes[k].penalties = resolveTriState(state.formValues['penalties_' + i]);
      round.holes[k].holeNumber = i;
    });

    function resolveTriState(value) {
      if (value === 0 || value === "0") {
        return 0;
      } else if (value === 1 || value === "1") {
        return 1;
      } else {
        return -1;
      }
    }

    const token = await getAccessTokenSilently();

    if (isNewRound) {
      await fetch(`${apiOrigin}/rounds`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(round),
      })
          .then((response) => {
            if (response.status < 300) {
              navigate('/view-rounds?friendId=' + state.friendId + '&courseId=' + state.apiMessage.course.courseId);
            } else {
              toast.error('There was an error creating the round: ' + response.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    } else {
      await fetch(`${apiOrigin}/rounds/${state.apiMessage.round.round.roundId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(round),
      })
          .then((response) => {
            if (response.status < 300) {
              navigate('/view-rounds?friendId=' + state.friendId + '&courseId=' + state.apiMessage.course.courseId);
            } else {
              toast.error('There was an error updating the round: ' + response.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }
  }, [apiOrigin, getAccessTokenSilently, isNewRound, navigate, state]);

  useEffect(() => {
    if (isSubmitClicked && !hasPendingChanges && isStateLoaded) {
      handleSubmitCallback().catch(error => {
        // Handle error
        console.error("API submission failed:", error);
      }).finally(() => {
         setIsSubmitClicked(false);
      });
    }
  }, [isSubmitClicked, hasPendingChanges, isStateLoaded, handleSubmitCallback]);

  useEffect(() => {
    if (isNewRound) {
      getCourseName(courseId).then((stateData) => {
        setState(stateData);
      });
    } else {
      getRoundDetails().then((stateData) => {
        setState(stateData);
      });
    }

    setIsStateLoaded(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  async function getRoundDetails() {
    try {
      const token = await getAccessTokenSilently();

      let uri = `${apiOrigin}/rounds`;

      if (roundId !== null) {
        uri += '/' + roundId;
      }

      const response = await fetch(uri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      state.formValues['useForHandicap'] = responseData.round.round.useForHandicap;
      state.formValues['roundDate'] = responseData.round.round.roundDate;
      state.formValues['handicap'] = responseData.round.round.handicap;
      state.formValues['comments'] = responseData.round.round.comments;

      state.friendId = responseData.course.userId;
      state.courseId = responseData.course.courseId;

      Array.from(responseData.round.scores).forEach((score) => {
        state.formValues['strokes_' + score.holeNumber] = score.strokes !== 0 ? score.strokes : '';
        state.formValues['noteText_' + score.holeNumber] = score.noteText;
        state.formValues['putts_' + score.holeNumber] = score.putts == null ? '' : score.putts;
        state.formValues['yards_' + score.holeNumber] = score.driveYards;
        state.formValues['fairway_' + score.holeNumber] = score.fairwayHit;
        state.formValues['bunker_' + score.holeNumber] = score.bunkerHit;
        state.formValues['sand_' + score.holeNumber] = score.sandSave;
        state.formValues['penalties_' + score.holeNumber] = score.penalties;
      });

      return {
        ...state,
        apiMessage: responseData,
        courseName: responseData.course.courseName,
      };
    } catch (error) {
      return {
        ...state,
        error: error.error,
      };
    }
  }

  async function getCourseName(courseId) {
    try {
      const token = await getAccessTokenSilently();

      let uri = `${apiOrigin}/courses/` + courseId;

      const responseData = await pRetry(() => retryFetchJson(uri, token), retryOptions);

      state.formValues['roundDate'] = parseToDateArray(new Date());
      state.formValues['handicap'] = responseData.userHandicap;
      state.formValues['useForHandicap'] = 1;
      state.friendId = responseData.course.userId;
      state.courseId = responseData.course.courseId;

      Array.from(responseData.holes).forEach((hole) => {
        state.formValues['strokes_' + hole.holeNumber] = '';
        state.formValues['noteText_' + hole.holeNumber] = '';
        state.formValues['putts_' + hole.holeNumber] = '';
        state.formValues['yards_' + hole.holeNumber] = '';
        state.formValues['fairway_' + hole.holeNumber] = -1;
        state.formValues['bunker_' + hole.holeNumber] = -1;
        state.formValues['sand_' + hole.holeNumber] = -1;
        state.formValues['penalties_' + hole.holeNumber] = '';
      });

      const response2 = await fetch(`${apiOrigin}/rounds/count/${responseData.course.userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData2 = await response2.json();

      return {
        ...state,
        apiMessage: responseData,
        courseName: responseData.course.courseName,
        friendName: responseData.course.username,
        remainingRounds: responseData2.count < 0 ? 0 : responseData2.count,
      };
    } catch (error) {
      return {
        ...state,
        error: error.error,
      };
    }
  }

  const handleDateChange = (d) => {
    state.formValues['roundDate'] = parseToDateArray(d);
    setState({
      ...state,
    });
    setHasPendingChanges(true);
    setTimeout(() => setHasPendingChanges(false), 500);
  };

  const handleChange = (event) => {
    state.formValues[event.target.name] = event.target.value;
    setState({
      ...state,
    });
    setHasPendingChanges(true);
    setTimeout(() => setHasPendingChanges(false), 500);
  };


  const openNote = (event) => {
    state.notesOpen[event.target.id.replace('notesimage_', '')] = true;
    setState({
      ...state,
    });
  };

  const closeNote = () => {
    Array.from(state.apiMessage.holes).map((hole) => (state.notesOpen[hole.holeNumber] = false));
    setState({
      ...state,
    });
  };

  return (
    <div className="console">
      {!isStateLoaded && <ConsoleLoading />}

      <ContentHeader label={isNewRound ? 'New Round' : 'Edit Round'} />
      {isStateLoaded && (
        <div className="console">
          <div className="detailconsolecontent" border="1">
            <table className={"editRoundDetails"}>
              <tbody>
              <tr key="courseName">
                <td nowrap="" className="normaltext">
                  Course:&nbsp;
                  <a href={`/view-rounds/?friendId=${state.friendId}&courseId=${state.courseId}`}>
                    <b>{state.courseName}</b>
                  </a>
                </td>
              </tr>
              <tr key="userName">
                <td nowrap="" className="normaltext">
                  Player:&nbsp;
                  <a href={`/view-rounds/?friendId=${state.friendId}&courseId=${state.courseId}`}>
                    <b>{state.friendName}</b>
                  </a>
                </td>
              </tr>
              {isNewRound && state.remainingRounds < 100 &&
                  <tr key="roundsRemaining">
                  <td nowrap="" className="normaltext">
                    Rounds Remaining:&nbsp;
                      <b>{state.remainingRounds}</b>
                  </td>
                </tr>
              }
              <tr key="handicap">
                <td nowrap="" className="normaltext">
                  Enter your handicap (used for stableford scoring):
                </td>
                <td>
                  <input
                      key="handicap"
                      onChange={handleChange}
                      className="normaltext"
                      type="edit"
                      size="2"
                      maxLength="2"
                      name={'handicap'}
                      value={state.formValues.handicap}
                  />
                </td>
              </tr>
              <tr key="useForHandicap">
                <td nowrap="" className="normaltext">
                  Use this round in handicap calculation?
                </td>
                <td>
                  <select
                      key={'useForHandicap'}
                      onChange={handleChange}
                      value={state.formValues['useForHandicap']}
                      className="normaltext"
                      name={'useForHandicap'}
                  >
                    <option key={1} value="1">
                      Y
                    </option>
                    <option key={0} value="0">
                      N
                    </option>
                  </select>
                </td>
              </tr>
              <tr key="roundDate">
                <td nowrap="" className="normaltext">
                  Please enter the date of this round:
                </td>
                <td className="normaltext">
                  <DatePicker
                      key="roundDate"
                      format="yyyy/MM/dd"
                      clearIcon={null}
                      showLeadingZeros={true}
                      onChange={handleDateChange}
                      value={dateFromArray(state.formValues['roundDate'])}
                  />
                </td>
              </tr>
              </tbody>
            </table>
            {width > 1024 ?
              <EditRoundDesktop callbackfn={(i) => (
                  <td key={'hole' + i} className="cell cellheader" align="center">
                    {i + 1}
                  </td>
              )} state={state} callbackfn1={(hole) => (
                  <td key={'par' + hole.holeNumber} className="cell celloldlight" align="center">
                    {hole.par}
                  </td>
              )} callbackfn2={(hole) => (
                  <td key={'si' + hole.holeNumber} className="cell cellheader" align="center">
                    {hole.strokeIndex}
                  </td>
              )} callbackfn3={(k) => (
                  <td key={'strokes_' + (k + 1)} className="cell celledit">
                    <input
                        className="normaltext"
                        onChange={handleChange}
                        size="2"
                        maxLength="2"
                        name={'strokes_' + (k + 1)}
                        value={state.formValues['strokes_' + (k + 1)]}
                    />
                  </td>
              )} callbackfn4={(k) => (
                  <td key={'notes_' + (k + 1)} className="cell celledit" style={{textAlign: 'center'}}>
                    <NotesModal
                        id={'noteText_' + (k + 1)}
                        show={state.notesOpen[k + 1]}
                        closeFunc={closeNote}
                        holeNumber={k + 1}
                        formValues={state.formValues}
                    />
                    <img
                        onClick={openNote}
                        key={'notes_' + (k + 1)}
                        id={'notesimage_' + (k + 1)}
                        border="0"
                        src="/img/icn/notepad.gif"
                        alt={'Notes for hole ' + (k + 1)}
                    />
                  </td>
              )} callbackfn5={(k) => (
                  <td key={'putts_' + (k + 1)} className="cell celledit">
                    <input
                        className="normaltext"
                        onChange={handleChange}
                        size="2"
                        maxLength="2"
                        key={'putts_' + (k + 1)}
                        name={'putts_' + (k + 1)}
                        value={state.formValues['putts_' + (k + 1)]}
                    />
                  </td>
              )} callbackfn6={(k) => (
                  <td key={'yards_' + (k + 1)} className="cell celledit">
                    <input
                        className="normaltext"
                        onChange={handleChange}
                        size="4"
                        maxLength="4"
                        key={'yards_' + (k + 1)}
                        name={'yards_' + (k + 1)}
                        value={
                          state.formValues['yards_' + (k + 1)] !== null ? state.formValues['yards_' + (k + 1)] : ''
                        }
                    />
                  </td>
              )} callbackfn7={(k) => (
                  <td key={'fairway_' + (k + 1)} className="cell celledit">
                    <select
                        onChange={handleChange}
                        key={'fairway_' + (k + 1)}
                        value={state.formValues['fairway_' + (k + 1)]}
                        className="normaltext"
                        name={'fairway_' + (k + 1)}
                    >
                      <option key={-1} value="-1">
                        -
                      </option>
                      <option key={1} value="1">
                        Y
                      </option>
                      <option key={0} value="0">
                        N
                      </option>
                    </select>
                  </td>
              )} callbackfn8={(k) => (
                  <td key={'bunker_' + (k + 1)} className="cell celledit">
                    <select
                        onChange={handleChange}
                        key={'bunker_' + (k + 1)}
                        value={state.formValues['bunker_' + (k + 1)]}
                        className="normaltext"
                        name={'bunker_' + (k + 1)}
                    >
                      <option key={-1} value="-1">
                        -
                      </option>
                      <option key={1} value="1">
                        Y
                      </option>
                      <option key={0} value="0">
                        N
                      </option>
                    </select>
                  </td>
              )} callbackfn9={(k) => (
                  <td key={'sand_' + (k + 1)} className="cell celledit">
                    <select
                        key={'sand_' + (k + 1)}
                        value={state.formValues['sand_' + (k + 1)]}
                        onChange={handleChange}
                        className="normaltext"
                        name={'sand_' + (k + 1)}
                    >
                      <option key={-1} value="-1">
                        -
                      </option>
                      <option key={1} value="1">
                        Y
                      </option>
                      <option key={0} value="0">
                        N
                      </option>
                    </select>
                  </td>
              )} callbackfn10={(k) => (
                  <td key={'penalties_' + k} className="cell celledit">
                    <input
                        onChange={handleChange}
                        className="normaltext"
                        size="2"
                        maxLength="2"
                        name={'penalties_' + (k + 1)}
                        key={'penalties_' + (k + 1)}
                        value={
                          state.formValues['penalties_' + (k + 1)] !== null
                              ? state.formValues['penalties_' + (k + 1)]
                              : ''
                        }
                    />
                  </td>
              )} onChange={handleChange}/>
                :
              <EditRoundMobile callbackfn={(i) => (
              <td key={'hole' + i} className="cell cellheader" align="center">
            {i + 1}
          </td>
          )} state={state} callbackfn1={(hole) => (
            <td key={'par' + hole.holeNumber} className="cell celloldlight" align="center">
              {hole.par}
            </td>
        )} callbackfn2={(hole) => (
            <td key={'si' + hole.holeNumber} className="cell cellheader" align="center">
              {hole.strokeIndex}
            </td>
        )} callbackfn3={(k) => (
            <td key={'strokes_' + (k + 1)} className="cell celledit">
              <input
                  className="normaltext"
                  onChange={handleChange}
                  size="2"
                  maxLength="2"
                  name={'strokes_' + (k + 1)}
                  value={state.formValues['strokes_' + (k + 1)]}
              />
            </td>
        )} callbackfn4={(k) => (
            <td key={'notes_' + (k + 1)} className="cell celledit" style={{textAlign: 'center'}}>
              <NotesModal
                  id={'noteText_' + (k + 1)}
                  show={state.notesOpen[k + 1]}
                  closeFunc={closeNote}
                  holeNumber={k + 1}
                  formValues={state.formValues}
              />
              <img
                  onClick={openNote}
                  key={'notes_' + (k + 1)}
                  id={'notesimage_' + (k + 1)}
                  border="0"
                  src="/img/icn/notepad.gif"
                  alt={'Notes for hole ' + (k + 1)}
              />
            </td>
        )} callbackfn5={(k) => (
            <td key={'putts_' + (k + 1)} className="cell celledit">
              <input
                  className="normaltext"
                  onChange={handleChange}
                  size="2"
                  maxLength="2"
                  key={'putts_' + (k + 1)}
                  name={'putts_' + (k + 1)}
                  value={state.formValues['putts_' + (k + 1)]}
              />
            </td>
        )} callbackfn6={(k) => (
            <td key={'yards_' + (k + 1)} className="cell celledit">
              <input
                  className="normaltext"
                  onChange={handleChange}
                  size="4"
                  maxLength="4"
                  key={'yards_' + (k + 1)}
                  name={'yards_' + (k + 1)}
                  value={
                    state.formValues['yards_' + (k + 1)] !== null ? state.formValues['yards_' + (k + 1)] : ''
                  }
              />
            </td>
        )} callbackfn7={(k) => (
            <td key={'fairway_' + (k + 1)} className="cell celledit">
              <select
                  onChange={handleChange}
                  key={'fairway_' + (k + 1)}
                  value={state.formValues['fairway_' + (k + 1)]}
                  className="normaltext"
                  name={'fairway_' + (k + 1)}
              >
                <option key={-1} value="-1">
                  -
                </option>
                <option key={1} value="1">
                  Y
                </option>
                <option key={0} value="0">
                  N
                </option>
              </select>
            </td>
        )} callbackfn8={(k) => (
            <td key={'bunker_' + (k + 1)} className="cell celledit">
              <select
                  onChange={handleChange}
                  key={'bunker_' + (k + 1)}
                  value={state.formValues['bunker_' + (k + 1)]}
                  className="normaltext"
                  name={'bunker_' + (k + 1)}
              >
                <option key={-1} value="-1">
                  -
                </option>
                <option key={1} value="1">
                  Y
                </option>
                <option key={0} value="0">
                  N
                </option>
              </select>
            </td>
        )} callbackfn9={(k) => (
            <td key={'sand_' + (k + 1)} className="cell celledit">
              <select
                  key={'sand_' + (k + 1)}
                  value={state.formValues['sand_' + (k + 1)]}
                  onChange={handleChange}
                  className="normaltext"
                  name={'sand_' + (k + 1)}
              >
                <option key={-1} value="-1">
                  -
                </option>
                <option key={1} value="1">
                  Y
                </option>
                <option key={0} value="0">
                  N
                </option>
              </select>
            </td>
        )} callbackfn10={(k) => (
            <td key={'penalties_' + k} className="cell celledit">
              <input
                  onChange={handleChange}
                  className="normaltext"
                  size="2"
                  maxLength="2"
                  name={'penalties_' + (k + 1)}
                  key={'penalties_' + (k + 1)}
                  value={
                    state.formValues['penalties_' + (k + 1)] !== null
                        ? state.formValues['penalties_' + (k + 1)]
                        : ''
                  }
              />
            </td>
        )} onChange={handleChange}/>
            }
            <br/>
            <button
                className="stdButton"
                onClick={handleSubmit}
                disabled={isNewRound && state.remainingRounds <= 0}
            >
              Save
            </button>
            {(isNewRound && state.remainingRounds <= 0) &&
                <>
              Rounds Remaining:&nbsp;
              <b>{state.remainingRounds}</b>
              </>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(EditRoundComponent, {
  onRedirecting: () => <Loading />,
});
