import React from "react";

export const HandicapSystemDrop = ({changeFunction, selected}) => {

    const options = [
        { label: 'Strict (CONGU®)', value: 28 },
        { label: 'Standard (CONGU®)', value: 36 },
        { label: 'USGA (Estimation)', value: 360 },
        { label: 'Relaxed', value: 54 },
        { label: 'Very Relaxed', value: 72 },
        { label: 'World Handicap System (Beta)', value: 1000 },
        { label: 'World Handicap System (2022)', value: 1001 },
    ];

    return (
        <select value={selected} className="dropdown" onChange={changeFunction} name="changeHandicapSystem">
            {options.map((option, i) => (
                <option value={option.value} key={i}>{option.label}</option>
            ))}
        </select>
    );
}

export default HandicapSystemDrop;
