import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import {renderLocalDateOrBlank} from "../utils/Utils";
import ContentHeader from "../components/ContentHeader";
import ConsoleLoading from "../components/ConsoleLoading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddFriendForm from "../components/Friends/AddFriendForm";
import {checkVersion} from "../utils/Net";
export const FriendsComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: {
            friends: [],
            userId: 0
        },
        incomingRequests: [],
        outgoingRequests: [],
        formValues: { friendName: "", friendType: "create", friendEmail: "" },
        error: null,
        loaded: false,
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();

    useEffect(() => {

        checkVersion();

        getState().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState() {
        async function getFriendList(token) {
            const response = await fetch(`${apiOrigin}/my-friends?excludeNoRounders=false&excludePending=false&includeMe=true`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const friendListResponse = await response.json();
            return friendListResponse;
        }

        async function getIncomingRequests(token) {
            const response = await fetch(`${apiOrigin}/my-friends/requests/incoming`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const friendListResponse = await response.json();
            return friendListResponse;
        }

        async function getOutgoingRequests(token) {
            const response = await fetch(`${apiOrigin}/my-friends/requests/outgoing`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const friendListResponse = await response.json();
            return friendListResponse;
        }

        try {
            const token = await getAccessTokenSilently();

            return {
                ...state,
                showResult: true,
                apiMessage: await getFriendList(token),
                incomingRequests: await getIncomingRequests(token),
                outgoingRequests: await getOutgoingRequests(token),
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    const handleChange = (event) => {
        state.formValues[event.target.name] = event.target.value
        setState({
            ...state,
        })
    };

    async function createFriend() {

        const token = await getAccessTokenSilently();

        let uri = `${apiOrigin}/my-friends/new-account`;

        let body = {
            name: state.formValues["friendDisplayName"],
            email: state.formValues["friendEmail"]
        }

        const response = await fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: "POST",
            body: JSON.stringify(body)
        });

        if (response.status === 409) {
            toast.error("This email address is already in use. Please use a different email address or add your friend using their existing username.");
        } else {
            if (response.status === 201) {
                getState().then(stateData => {
                    setState(stateData)
                })
                toast("Friend account created")
            } else {
                toast.error("Unknown error creating friend account. If this error persists, please contact us.")
            }
        }
    }

    async function addFriend() {

        state.apiMessage.friends && state.apiMessage.friends.forEach((friend) => {
            if (state.formValues["friendName"] === friend.name) {
                toast.error("Friend already in list");
                return
            }
        })

        const token = await getAccessTokenSilently();

        let uri = `${apiOrigin}/my-friends`;

        let body = {
            name: state.formValues["friendName"]
        }

        const response = await fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: "POST",
            body: JSON.stringify(body)
        });

        if (response.status === 404) {
            toast.error("Friend not found")
        } else {
            getState().then(stateData => {
                setState(stateData)
            })
            toast("Friend request sent")
        }
    }

    async function removeFriend(friendId, name) {
        if (window.confirm("Are you sure you want to remove " + name + "? " +
            "You'll need to know their username to re-add them, and they will need to re-grant you access.")) {

            const token = await getAccessTokenSilently();
            let uri = `${apiOrigin}/my-friends/remove?friendId=` + friendId;

            await fetch(uri, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: "DELETE"
            })

            getState().then(stateData => {
                setState(stateData)
            })
        }

    }

    async function removeRequest(friendId, direction) {

        const token = await getAccessTokenSilently();
        let uri = `${apiOrigin}/my-friends/request/` + direction + `?friendId=` + friendId;

        await fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: "DELETE"
        })

        getState().then(stateData => {
            setState(stateData)
        })
    }

    async function acceptRequest(friendId, direction) {

        const token = await getAccessTokenSilently();
        let uri = `${apiOrigin}/my-friends/request/` + friendId + '/accept';

        await fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: "POST"
        })

        getState().then(stateData => {
            setState(stateData)
        })
    }

    async function toggleAccess(friendId, accessLevel) {
        const token = await getAccessTokenSilently();

        let uri = `${apiOrigin}/my-friends/set-access`;
        let request = {
            "userId": friendId,
            "accessLevel": accessLevel === 1 ? "READ" : "WRITE"
        }

        await fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: "PUT",
            body: JSON.stringify(request)
        })

        getState().then(stateData => {
            setState(stateData)
        })
    }

    return (

        <div className="console">

            <ContentHeader label={'Friends'}/>
            <div className="detailconsolecontent">
                <AddFriendForm onChange={handleChange} state={state} onClick={addFriend} onClick1={createFriend}/>
                <br/><br/>

                {!state.showResult ?
                    <ConsoleLoading/> :
                    <div>
                        {state.incomingRequests.length > 0 &&
                            <div style={{marginBottom: "2em"}}>
                                <h5>Pending Requests</h5>
                                These friend requests are pending until you accept or delete them.
                                <table className="cleanTable friendsIncomingTable">
                                    <thead>
                                    <tr>
                                        <th align="top">Username</th>
                                        <th align="top">Request Sent</th>
                                        <th colSpan={2}>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.incomingRequests.length > 0 && Array.from(state.incomingRequests)
                                        .slice(0, 3)
                                        .map(friend =>
                                            <tr key={friend.requesterId}>
                                                <td>{friend.requesterName}</td>
                                                <td align="center">
                                                    {renderLocalDateOrBlank(friend.createdAt)}
                                                </td>
                                                <td align="center">
                                                    <button className={"button_as_link"} style={{color: "red"}} onClick={() => removeRequest(friend.requesterId, 'incoming')}>Remove Request</button>
                                                </td>
                                                <td align="center">
                                                    <button className={"button_as_link"}
                                                            onClick={() => acceptRequest(friend.requesterId, 'incoming')}>Accept
                                                        Request
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {state.outgoingRequests.length > 0 &&
                            <div style={{marginBottom: "2em"}}>
                                <h5>Sent Requests</h5>
                                These friend requests are pending until they are accepted or deleted.
                                <table className="cleanTable friendsOutgoingTable">
                                    <thead>
                                    <tr>
                                        <th align="top">Username</th>
                                        <th align="top">Request Sent</th>
                                        <th colSpan={2}>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.outgoingRequests.length > 0 && Array.from(state.outgoingRequests)
                                        .slice(0, 3)
                                        .map(friend =>
                                            <tr key={friend.targetId}>
                                                <td>{friend.requesterName}</td>
                                                <td align="center">
                                                    {renderLocalDateOrBlank(friend.createdAt)}
                                                </td>
                                                <td align="center">
                                                    <button className={"button_as_link"}
                                                            onClick={() => removeRequest(friend.targetId, 'outgoing')}>Remove Request
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {state.apiMessage.friends && state.apiMessage.friends.length > 0 &&
                            <div>
                                <h5>Friends</h5>
                                <table className="cleanTable friendsTable">
                                    <thead>
                                    <tr>
                                        <th align="top">Username</th>
                                        <th align="top">Name</th>
                                        <th colSpan="2">Handicap</th>
                                        <th>Handicap<br/>system</th>
                                        <th nowrap="" align="center">Access to your
                                            account<br/>Click to toggle
                                        </th>
                                        <th nowrap="" align="center">Your access to this
                                            account
                                        </th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.apiMessage.friends && state.apiMessage.friends.length > 0 && Array.from(state.apiMessage.friends)
                                        .filter(friend => friend.reverseAccessLevelDescription !== "Pending")
                                        .map(friend =>
                                            <tr key={friend.friendId}>
                                                <td>{friend.name}</td>
                                                <td>{friend.realName}</td>
                                                <td className="invisiLeft">{friend.handicap.toFixed(0)}</td>
                                                <td className="invisiRight">{friend.handicap.toFixed(2)}</td>
                                                <td height="20" nowrap="" align="center"></td>
                                                <td height="20" nowrap="" align="center">
                                                    <button className={"button_as_link"} onClick={() => toggleAccess(friend.friendId, friend.accessLevel)}>
                                                        {friend.accessLevelDescription}
                                                    </button>
                                                </td>
                                                <td height="20" align="center">{friend.reverseAccessLevelDescription}</td>
                                                <td align="center">
                                                    {friend.friendId !== friend.userId &&
                                                        <button className={"button_as_link"}
                                                                onClick={() => removeFriend(friend.friendId, friend.name)}>Remove Friend
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }


                    </div>
                }
            </div>

        </div>


    );
};

export default withAuthenticationRequired(FriendsComponent, {
    onRedirecting: () => <Loading />,
});
