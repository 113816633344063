import React from "react";

type Props = {
    label: string;
}

export const ContentHeader = ({label}: Props) => {

        return (
            <div className={"detailconsolecontent"}>
                <h2>{label}</h2>
            </div>
        )
}

export default ContentHeader
