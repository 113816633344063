import React from "react";
import { Pie } from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle} from 'chart.js';

export const PieChart = ({rawData}) => {

    ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

    const { par3, par4, par5 } = rawData["strokes"] !== 0 ?
        rawData["percentageByPar"] : {
            "par3": {
                    "eagle": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "birdie": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "par": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "bogey": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "doubleBogey": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "threeOver": {
                        "total": 1,
                            "cumulative": 1
                    },
                    "fourPlusOver": {
                        "total": 1,
                            "cumulative": 1
                    }
                },
            "par4": {
            "eagle": {
                "total": 1,
                    "cumulative": 1
            },
                "birdie": {
                    "total": 1,
                        "cumulative": 1
                },
                "par": {
                    "total": 1,
                        "cumulative": 1
                },
                "bogey": {
                    "total": 1,
                        "cumulative": 1
                },
                "doubleBogey": {
                    "total": 1,
                        "cumulative": 1
                },
                "threeOver": {
                    "total": 1,
                        "cumulative": 1
                },
                "fourPlusOver": {
                    "total": 1,
                        "cumulative": 1
                }
            },
            "par5": {
                "eagle": {
                    "total": 1,
                        "cumulative": 1
                },
                "birdie": {
                    "total": 1,
                        "cumulative": 1
                },
                "par": {
                    "total": 1,
                        "cumulative": 1
                },
                "bogey": {
                    "total": 1,
                        "cumulative": 1
                },
                "doubleBogey": {
                    "total": 1,
                        "cumulative": 1
                },
                "threeOver": {
                    "total": 1,
                        "cumulative": 1
                },
                "fourPlusOver": {
                    "total": 1,
                        "cumulative": 1
                }
            }
        }
    const par5Data = [
        par5.eagle.total,
        par5.birdie.total,
        par5.par.total,
        par5.bogey.total,
        par5.doubleBogey.total,
        par5.threeOver.total,
        par5.fourPlusOver.total
    ]
    const par4Data = [
        par4.eagle.total,
        par4.birdie.total,
        par4.par.total,
        par4.bogey.total,
        par4.doubleBogey.total,
        par4.threeOver.total,
        par4.fourPlusOver.total
    ]
    const par3Data = [
        par3.eagle.total,
        par3.birdie.total,
        par3.par.total,
        par3.bogey.total,
        par3.doubleBogey.total,
        par3.threeOver.total,
        par3.fourPlusOver.total
    ]
    const showPar3 = par3Data.reduce((a, b) => a + b, 0) > 0
    const showPar4 = par4Data.reduce((a, b) => a + b, 0) > 0
    const showPar5 = par5Data.reduce((a, b) => a + b, 0) > 0

    const data = {
        labels: ['Eagle', 'Birdie', 'Par', 'Bogey', 'Double Bogey', 'Three over', 'Four+ over'],
        datasets: [
            {
                label: 'All',
                data: [
                    par3.eagle.total + par4.eagle.total + par5.eagle.total,
                    par3.birdie.total + par4.birdie.total + par5.birdie.total,
                    par3.par.total + par4.par.total + par5.par.total,
                    par3.bogey.total + par4.bogey.total + par5.bogey.total,
                    par3.doubleBogey.total + par4.doubleBogey.total + par5.doubleBogey.total,
                    par3.threeOver.total + par4.threeOver.total + par5.threeOver.total,
                    par3.fourPlusOver.total + par4.fourPlusOver.total + par5.fourPlusOver.total
                ],
                backgroundColor: [ '#FFDD66', '#FFFF99', '#CCFFCC', '#00FFFF', '#00CCCC', '#00AAAA', '#008888' ],
                hidden: false
            },
            {
                label: 'Par 5',
                data: par5Data,
                hidden: !showPar5,
                backgroundColor: [ '#FFDD66', '#FFFF99', '#CCFFCC', '#00FFFF', '#00CCCC', '#00AAAA', '#008888' ]
            },
            {
                label: 'Par 4',
                data: par4Data,
                hidden: !showPar4,
                backgroundColor: [ '#FFDD66', '#FFFF99', '#CCFFCC', '#00FFFF', '#00CCCC', '#00AAAA', '#008888' ]
            },
            {
                label: 'Par 3',
                data: par3Data,
                hidden: !showPar3,
                backgroundColor: [ '#FFDD66', '#FFFF99', '#CCFFCC', '#00FFFF', '#00CCCC', '#00AAAA', '#008888' ]
            },

        ]
    }

    const subTitle = 'Rings (from outer): All holes' +
        (showPar5 ? ', Par 5' : '') +
        (showPar4 ? ', Par 4' : '') +
        (showPar3 ? ', Par 3' : '')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Percentage Breakdown by Score',
            },
            subtitle: {
                display: true,
                text: subTitle,
            }
        },

    };

    return (
                <Pie data={data} options={options} />
    );
}

export default PieChart;
