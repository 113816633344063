export const TeeDropdown = ({ tees, changeFunc }) => {

    return (
        <select name="teeColour" onChange={changeFunc}>
            {tees.map((teeItem) => (
                <option key={teeItem.teeId} value={teeItem.teeId}>
                    {teeItem.tee}
                </option>
            ))}
            <option key={-1} value={-1}>Add new tee colour...</option>
        </select>
    );
}

export default TeeDropdown;