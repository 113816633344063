import React from "react";
import { Line } from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle, CategoryScale, LinearScale, PointElement, LineElement} from 'chart.js';

export const LineGraph = ({rawData, title}) => {

    const labels = rawData.map( (data) => {
      return data.date
    })

    const items = rawData.map( (data) => {
        return data.score
    })

    const movingAverage = rawData.map( (data) => {
        return data.movingAverage
    })

    ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle, CategoryScale, LinearScale, PointElement, LineElement);

    const data = {
        labels: labels,
        datasets: [{
            label: title,
            data: items,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        },
        {
            label: "Moving Average",
            data: movingAverage,
            fill: false,
            borderColor: 'rgb(240, 45, 45)',
            tension: 0.1
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            }
        },
        scales:{
            x: {
                display: false
            }
        }

    };

    return (
                <Line data={data} options={options} />
    );
}

export default LineGraph;
