import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getReferenceCourseV2 } from "../utils/Net";
import {ReferenceCourseResponseV2, ReferenceTeeV2} from "./Model";

const EditReferenceCourseComponent: React.FC = () => {
    const { courseId } = useParams<{ courseId: string }>();

    const [courseDetails, setCourseDetails] = useState<ReferenceCourseResponseV2 | null>(null);
    const [selectedTee, setSelectedTee] = useState<string>("");
    const [newTee] = useState<ReferenceTeeV2>({colourName: "", courseUuid: "", customName: "", holes: [], teeUuid: "", slopeIndex: 0, sss: 0 });
    const { getAccessTokenSilently } = useAuth0();

    // Separate state for form fields for cleaner updates.
    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (!courseId) return;
            const token = await getAccessTokenSilently();
            const courseData = await getReferenceCourseV2(token, courseId);
            if (courseData.course.tees.length === 0) {
                console.error("Tees not present in the course results");
                return;
            }
            setCourseDetails(courseData)
            setSelectedTee(courseData.course.tees[0].teeUuid)
        };

        fetchCourseDetails();
    }, [courseId, getAccessTokenSilently]);

    if (!courseDetails) return <div>Loading...</div>;  // Guard against null values.

    return (
        <div>
            <h1>Edit {courseDetails.course.name}</h1>

            {/* Club details */}
            <fieldset>
                <legend>Club Details</legend>
                <label>
                    Club Name: {courseDetails.club.name}
                </label>
                <label>
                    Website:
                    <input type="url" value={courseDetails.club.website} onChange={(e) => setCourseDetails(prev => ({ ...prev!, club: { ...prev!.club, website: e.target.value } }))} />
                </label>
                {/* ... other club fields ... */}
            </fieldset>

            {/* Course selection */}
            <fieldset>
                <legend>Select Course</legend>
                <label>
                    Course:
                    <select value={courseDetails.course.uuid}>
                        {courseDetails.club.courses.map(c => <option key={c.uuid} value={c.uuid}>{c.name}</option>)}
                    </select>
                </label>
            </fieldset>

            {/* Course details */}
            <fieldset>
                <legend>Course Details</legend>
                <label>
                    Course Name:
                    <input type="text" value={courseDetails.course.name} onChange={(e) => setCourseDetails(prev => ({ ...prev!, course: { ...prev!.course, name: e.target.value } }))} />
                </label>
                {/* ... other course fields ... */}
            </fieldset>

            {/* Tee selection and details */}
            <fieldset>
                <legend>Select Tee</legend>
                <label>
                    Tee:
                    <select value={selectedTee} onChange={(e) => setSelectedTee(e.target.value)}>
                        {courseDetails.course.tees.map(t => <option key={t.teeUuid} value={t.teeUuid}>{t.colourName}</option>)}
                        <option value="new">+ Add New Tee</option>
                    </select>
                </label>

                {selectedTee === "new" && (
                    <div>
                        <label>
                            Tee Name:
                            <input type="text" value={newTee.colourName} />
                        </label>
                    </div>
                )}

                {/* ... Remaining code ... */}
            </fieldset>

            {/* Buttons for saving, etc. */}
            <button onClick={() => {
                // Handle save functionality
            }}>Save Changes</button>
        </div>
    );
};

export default EditReferenceCourseComponent;
