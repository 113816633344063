import FriendDrop from "../Friends/FriendDrop";
import DatePicker from "react-date-picker";
import CourseDrop from "../CourseDrop";
import * as PropTypes from "prop-types";
import React from "react";

function Filters(props) {
    return <table className={"filters"}>
        <tbody>
        <tr>
            <th>For</th>
            <th>Course</th>
            <th>From</th>
            <th>To</th>
        </tr>
        <tr>
            <td>
                <FriendDrop excludeNoRounders={false} excludePending={true} includeMe={true}
                            friendFunction={props.friendFunction}/>
            </td>
            <td>
                <CourseDrop friendId={props.state.friendId} courseFunction={props.courseFunction}/>
            </td>
            <td>
                    <DatePicker key="roundDate"
                                format="yyyy/MM/dd"
                                clearIcon={null}
                                showLeadingZeros={true}
                                onChange={props.onChange}
                                value={props.state.dateFrom}/>
            </td>
            <td>
                    <DatePicker key="roundDate"
                                format="yyyy/MM/dd"
                                clearIcon={null}
                                showLeadingZeros={true}
                                onChange={props.onChange1}
                                value={props.state.dateTo}/>
            </td>
        </tr>
        </tbody>
    </table>;
}

Filters.propTypes = {
    friendFunction: PropTypes.func,
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.number,
        dateTo: PropTypes.any,
        dateFrom: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.number,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    }),
    onChange: PropTypes.func,
    onChange1: PropTypes.func,
    courseFunction: PropTypes.func
};

export default Filters
