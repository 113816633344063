import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {getConfig} from "../config";
import Loading from "../components/Loading";
import PieChart from "../components/Statistics/PieChart";
import ContentHeader from "../components/ContentHeader";
import ConsoleLoading from "../components/ConsoleLoading";
import StatsMainTable from "../components/Statistics/StatsMainTable";
import PercentageByPar from "../components/Statistics/PercentageByPar";
import StrokesByPar from "../components/Statistics/StrokesByPar";
import Filters from "../components/Statistics/Filters";
import ProgressionGraphs from "../components/Statistics/ProgressionGraphs";
import {dateFromArray} from "../utils/Utils";
import pRetry from "p-retry";
import {retryFetchJson, retryOptions, subscriptionData} from "../utils/Net";
import {useNavigate} from "react-router-dom";
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

export const StatisticsComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();
    const navigate = useNavigate();

    const [state, setState] = useState({
        showResult: false,
        courseId: 0,
        friendId: 0,
        dateFrom: new Date("1990-01-01"),
        dateTo: new Date("2025-01-01"),
        apiMessage: {},
        error: null,
        loaded: false,
        query: "",
        subscriptionData: {},
    });

    const {
        getAccessTokenSilently,
        isLoading,
        error, } = useAuth0();

    function getQueryString(friendId, courseId, dateFrom, dateTo) {
        let query = `?dateFrom=${formatDate(dateFrom)}&dateTo=${formatDate(dateTo)}`

        if (courseId > 0) {
            query += "&courseId=" + courseId;
        }

        if (friendId !== 0) {
            query += "&friendId=" + friendId;
        }
        return query;
    }

    async function dateRangeQuery() {
        const request = async () => {
            const token = await getAccessTokenSilently();

            const json = await pRetry(() => retryFetchJson(`${apiOrigin}/rounds/date-range`, token), {
                onFailedAttempt: error => {
                    console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
                },
                retries: 5}
            )

            console.log(json)
            return json
        }

        const json = await request()
        console.log(json)
        return json
    }

    useEffect(() => {

        dateRangeQuery().then(dateRange => {
            getState(state.friendId, state.courseId, dateFromArray(dateRange.first), dateFromArray(dateRange.last)).then(stateData => {
                setState(stateData)
            })
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }
    async function getState(friendId, courseId, dateFrom, dateTo) {

        async function reportQuery(query, token) {
            return await pRetry(() => retryFetchJson(`${apiOrigin}/stats` + query, token), retryOptions);
        }

        async function progressionQuery(query, token) {
            return await pRetry(() => retryFetchJson(`${apiOrigin}/stats/progression` + query, token), {
                    onFailedAttempt: error => {
                        console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
                    },
                    retries: 5
                }
            );
        }

        try {
            const token = await getAccessTokenSilently();
            state.subscriptionData = await subscriptionData(token);

            state.query = getQueryString(friendId, courseId, dateFrom, dateTo);
            const responseData = await reportQuery(state.query, token);
            const progressionData = await progressionQuery(state.query, token);

            return {
                ...state,
                friendId: friendId,
                courseId: courseId,
                dateFrom: dateFrom,
                dateTo: dateTo,
                showResult: true,
                apiMessage: responseData,
                progressionData: progressionData
            };
        } catch (error) {
            return {
                ...state,
                error: error.error,
            };
        }
    }

    function changeFriend(event) {
        getState(event.target.value, state.courseId, state.dateFrom, state.dateTo).then(stateData => {
            setState(stateData)
        })
    }

    function changeCourse(event) {
        getState(state.friendId, event.target.value, state.dateFrom, state.dateTo).then(stateData => {
            setState(stateData)
        })
    }

    function changeDateFrom(date) {
        getState(state.friendId, state.courseId, date, state.dateTo).then(stateData => {
            setState(stateData)
        })
    }

    function changeDateTo(date) {
        getState(state.friendId, state.courseId, state.dateFrom, date).then(stateData => {
            setState(stateData)
        })
    }

    function formatDate(d) {
        let year = d.getFullYear()
        let month = ("0"+(d.getMonth()+1)).slice(-2)
        let day = ("0" + d.getDate()).slice(-2)
        return year + "-" + month + "-" + day
    }

    async function exportPdf() {
        const domElement = document.getElementById('stats_outer')
        html2canvas(domElement, { onclone: (document) => {
                document.getElementById('export_options').remove()
            }})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPdf('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, 211, 298);
                pdf.save("download.pdf")
            })
    }

    return (

        <div className="console" id="stats_outer">
            <ContentHeader label={'Statistics'}/>
            {!state.showResult &&
                <ConsoleLoading/>
            }
            <div className={"detailconsolecontent"} id="export_options">
                <input
                    onClick={exportPdf} className="stdbutton" id="export_pdf" type="submit" value="Download PDF"
                />
                <br/>
                You can export your raw data as spreadsheet-readable CSV using the <button className={"button_as_link"} onClick={() => navigate("/export")}>Export Data</button> option on the account menu.
            </div>
            {state.showResult &&
                <div className={"detailconsolecontent"}>

                {state.showResult &&
                <>
                    <div id={"filters"}>
                        <Filters friendFunction={changeFriend} state={state} onChange={(date) => changeDateFrom(date)}
                                 onChange1={(date) => changeDateTo(date)} courseFunction={changeCourse}/>
                    </div>
                    <hr/>
                    <div>

                        <StatsMainTable state={state}/>
                        <div className={"statsPieChart"} style={{marginTop: "15px", float: "right"}}>
                            <PieChart rawData={state.apiMessage}/>
                        </div>
                        <div style={{width: "100%", clear: "both", paddingTop: "2em"}}>
                            <h3>Strokes by par</h3>
                            <div style={{marginBottom: "5px"}}>
                                The <i>Strokes
                                by
                                par</i> and <i>Percentage by par</i> tables
                                break down the number of birdies, pars, etc... made for each par type.
                                The figures on the right are the cumulative totals. For example,
                                the cumulative figure for the BOGEY column shows the number
                                (or percentage) of holes where you have scored bogey or
                                better.<br/>
                            </div>
                            <StrokesByPar state={state}/>
                        </div>
                        <div style={{width: "100%", paddingTop: "2em"}}>
                            <h3>Percentage by par</h3>
                            <PercentageByPar state={state}/>
                        </div>
                        <ProgressionGraphs state={state}/>
                    </div>
                    <div style={{clear: "both"}}>
                    </div>
                </>
                }
                </div>
            }
        </div>

            );
};

export default withAuthenticationRequired(StatisticsComponent, {
    onRedirecting: () => <Loading />,
});
