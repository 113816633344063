import React, {useEffect, useState} from "react";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import ContentHeader from "../components/ContentHeader";
import {getConfig} from "../config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const ProfileComponent = () => {
    const { apiOrigin = "http://localhost:8080" } = getConfig();

    const [state, setState] = useState({
        formValues: {
            emailAddress: "",
            username: ""
        },
        canEditEmail: false,
        showResult: false
    });

    const {
        isLoading,
        getAccessTokenSilently,
        error, } = useAuth0();

    useEffect(() => {

        const callApi = async () => {
            try {
                const token = await getAccessTokenSilently();

                const response = await fetch(`${apiOrigin}/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const responseData = await response.json();

                return {
                    formValues: {
                        emailAddress: responseData.emailAddress,
                        username: responseData.username
                    },
                    canEditEmail: responseData.canEditEmail,
                    showResult: true
                }

            } catch (error) {
                return {
                    ...state,
                    error: error.error,
                };
            }
        };

        callApi().then(stateData => {
            setState(stateData)
        })

        return () => {
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    const handleChange = (event) => {

        state.formValues[event.target.name] = event.target.value

        setState({
            ...state,
        })

    }

    async function handleSubmitEmail(event) {
        event.preventDefault();

        const token = await getAccessTokenSilently();

        let uri = `${apiOrigin}/profile/email`

        await fetch(uri, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(state.formValues)
        }).then(response => {
          if (response.status === 409) {
             toast.error("Email address already exists")
          } else {
              if (response.status === 200) {
                  toast("Email address updated")
              } else {
                  toast.error("Unknown error")
              }
          }
        })

    }

    async function handleSubmitUsername(event) {
        event.preventDefault();

        const token = await getAccessTokenSilently();

        let uri = `${apiOrigin}/profile/username`

        await fetch(uri, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(state.formValues)
        }).then(response => {
            if (response.status === 409) {
                toast.error("Username already exists")
            } else {
                if (response.status === 200) {
                    toast("Username updated")
                } else {
                    toast.error("Unknown error")
                }
            }
        })

    }


    return (

      <div className="console">

          <ContentHeader label={'Profile'}/>
          {state.showResult &&

              <div className="detailconsolecontent">
                  Email address
                  {!state.canEditEmail &&
                      <> is set by your identity provider</>
                  }
                  <br/>
                  <input disabled={!state.canEditEmail} onChange={handleChange} name={"emailAddress"}
                         value={state.formValues.emailAddress}/>&nbsp;
                  {state.canEditEmail &&
                      <input onClick={handleSubmitEmail} type={"submit"} value={"Save"}/>
                  }
                  <br/>

                  Username
                  <br/>
                  <input onChange={handleChange} name={"username"} value={state.formValues.username}/>&nbsp;
                  <input onClick={handleSubmitUsername} type={"submit"} value={"Save"}/>
                  <br/>

                  <br/>

              </div>
          }

      </div>

  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
