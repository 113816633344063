import * as PropTypes from "prop-types";
import React from "react";
import {getScoreClass, renderLocalDateOrBlank} from "../../utils/Utils";
import {useNavigate} from "react-router-dom";

function RightTotals() {
    return <>
        <th align="center" className="cell cellheader">Fr</th>
        <th align="center" className="cell celloldlight">Ba</th>
        <th align="center" className="cell cellheader">To</th>
    </>;
}

function RoundsTableMobile(props) {

    const navigate = useNavigate();

    return         <table border="0" cellPadding="0" cellSpacing="3" width="100%">
        <tbody>
        <tr>
            <td className="detailconsolecontent" border="1">


                <table border="0" id="roundsTable" width="100%">
                    <tbody>
                    {props.state.apiMessage.decoratedRounds.course ?
                        <>
                            <tr>
                                <td className="cell cellheader">SSS (CI)</td>
                                <td colSpan={2} className="cell celloldlight"
                                    align="center">{props.state.apiMessage.decoratedRounds.course.sss}</td>
                            </tr>
                            <tr>
                                <td className="cell cellheader">Slope</td>
                                <td colSpan={2} align="center"
                                    className="cell celloldlight">{props.state.apiMessage.decoratedRounds.course.slopeIndex}</td>
                            </tr>
                            <tr>
                                <td className="cell cellheader">Hole</td>
                                {Array.from(Array(9).keys()).map(k => k + 1).map(i =>
                                    <td key={i} align="center" className="cell cellheader">{i}</td>
                                )}
                            </tr>

                                <tr>
                                    <td className="cell cellheader"></td>
                                    {Array.from(Array(9).keys()).map(k => k + 10).map(i =>
                                        <td key={i} align="center" className="cell cellheader">{i}</td>
                                    )}
                                </tr>

                            <tr>
                                <td className="cell cellheader">Par</td>
                                {Array.from(props.state.apiMessage.decoratedRounds.holes).slice(0, 9).map(hole =>
                                    <td key={hole.holeNumber} align="center"
                                        className="cell celloldlight">{hole.par}</td>
                                )
                                }
                            </tr>

                                <tr>
                                    <td className="cell cellheader"></td>
                                    {Array.from(props.state.apiMessage.decoratedRounds.holes).slice(9, 18).map(hole =>
                                        <td key={hole.holeNumber} align="center"
                                            className="cell celloldlight">{hole.par}</td>
                                    )
                                    }
                                </tr>

                            <tr>
                                <th className="cell cellheader">Stroke Index</th>
                                {Array.from(props.state.apiMessage.decoratedRounds.holes).slice(0,9).map(hole =>
                                    <th key={hole.holeNumber} align="center" style={{textAlign: "center"}}
                                        className="cell cellheader">{hole.strokeIndex}</th>
                                )
                                }
                                {parseInt(props.state.apiMessage.decoratedRounds.course.numberOfHoles) === 9 &&
                                    <RightTotals/>
                                }
                            </tr>

                                <tr>
                                    <th className="cell cellheader"></th>
                                    {Array.from(props.state.apiMessage.decoratedRounds.holes).slice(9, 18).map(hole =>
                                        <th key={hole.holeNumber} align="center" style={{textAlign: "center"}}
                                            className="cell cellheader">{hole.strokeIndex}</th>
                                    )
                                    }
                                        <RightTotals/>
                                </tr>
                        </> :
                        <>
                            <tr>
                                <th className="cell cellheader">Hole</th>
                                {Array.from(Array(9).keys()).map(i =>
                                    <th key={i} align="center" className="cell cellheader" style={{textAlign: "center"}}>{i + 1}
                                    </th>
                                )}

                            </tr>
                            <tr>
                                <th className="cell celloldlight">Date</th>
                                {Array.from(Array(9).keys()).map(i =>
                                    <th key={i} align="center" className="cell cellheader" style={{textAlign: "center"}}>{i + 10}
                                    </th>
                                )}
                                    <RightTotals/>
                            </tr>
                        </>
                    }
                    {props.state.apiMessage.decoratedRounds.rounds ?
                        <>
                            {Array.from(props.state.apiMessage.decoratedRounds.rounds).map(round =>
                                <>
                                <tr id={"round_" + round.round.roundId} key={round.round.roundId}>
                                    <td className="cell celloldlight">{renderLocalDateOrBlank(round.round.roundDate)}</td>

                                    {Array.from(round.scores).slice(0, 9).map(score =>
                                        <td key={score.holeNumber} align="center"
                                            className={"cell " + (score.strokes > 0 ? getScoreClass(props.state.scoreShadingId === "normal" ? score.scratchStablefordScore : score.stablefordScore) : 'cellnone')}>
                                            {score.strokes > 0 ? score.strokes : "-"}
                                            {score.strokes > 0 && props.state.stablefordId === "perhole" ? "/" + score.stablefordScore : ""}
                                        </td>
                                    )}

                                    <>
                                        <td align="center" className="cell cellheader">{round.frontNine}</td>
                                        <td align="center" className="cell celloldlight">{round.backNine}</td>
                                        <td align="center" className="cell cellheader">{round.totalStrokes}</td>


                                    </>
                                </tr>
                                <tr>
                                    <td nowrap="" className="cell celloldlight"></td>

                                    {Array.from(round.scores).slice(9, 18).map(score =>
                                        <td key={score.holeNumber} align="center"
                                            className={"cell " + (score.strokes > 0 ? getScoreClass(props.state.scoreShadingId === "normal" ? score.scratchStablefordScore : score.stablefordScore) : 'cellnone')}>
                                            {score.strokes > 0 ? score.strokes : "-"}
                                            {score.strokes > 0 && props.state.stablefordId === "perhole" ? "/" + score.stablefordScore : ""}
                                        </td>
                                    )}

                                    <td colSpan={1} align="center" className="cell cellicon">
                                        {props.state.apiMessage.decoratedRounds.accessLevel === "WRITE" ?
                                            <button id={"deleteRound_" + round.round.roundId} className={"button_as_link"}
                                                    onClick={() => props.deleteRound(round.round.roundId)}>Del</button> :
                                            <button id={"deleteRound_" + round.round.roundId} className={"button_as_link disabled_link"}
                                                    onClick={() => props.denied("delete")}>Del</button>
                                        }
                                    </td>
                                    <td colSpan={1} align="center" className="cell cellicon">
                                        {props.state.apiMessage.decoratedRounds.accessLevel === "WRITE" ?
                                            <button className={"button_as_link"}
                                                    onClick={() => navigate("/edit-round/" + round.round.roundId)}>Edit</button> :
                                            <button className={"button_as_link disabled_link"}
                                                    onClick={() => props.denied("edit")}>Edit</button>
                                        }
                                    </td>
                                    <td colSpan={1} align="center" className="cell cellicon">
                                        <button className={"button_as_link"}
                                                onClick={() => navigate("/compare-rounds/" + round.round.roundId)}>Players
                                        </button>
                                    </td>
                                </tr>
                                </>
                            )}
                            {props.state.apiMessage.decoratedRounds.course &&
                                <>
                                    <tr>
                                        <td className="cell cellheader">Average</td>
                                        {props.state.apiMessage.decoratedRounds.summary.averageStrokes.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averageStrokes.holes).slice(0,9).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averageStrokes.totals.frontNine.toFixed(1)}</td>
                                        <td className="cell celloldlight"></td>
                                        <td className="cell cellheader"></td>

                                    </tr>
                                    <tr>
                                        <td className="cell cellheader"></td>

                                        {props.state.apiMessage.decoratedRounds.summary.averageStrokes.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averageStrokes.holes).slice(9,18).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }

                                        <td className="cell cellheader"></td>
                                        <td align="center" className="cell celloldlight">{props.state.apiMessage.decoratedRounds.summary.averageStrokes.totals.backNine.toFixed(1)}</td>
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averageStrokes.totals.total.toFixed(1)}</td>
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader">Best</td>
                                        {props.state.apiMessage.decoratedRounds.summary.best.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.best.holes).slice(0,9).map((hole, index) =>
                                            <td key={index} align="center"
                                                className={"cell " + (hole.strokes > 0 ? getScoreClass(hole.scratchStablefordScore) : 'cellnone')}>{hole.strokes}</td>
                                        )
                                        }
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.best.totals.frontNine}</td>
                                        <td className="cell celloldlight"></td>
                                        <td className="cell cellheader"></td>

                                    </tr>
                                    <tr>
                                        <td className="cell cellheader"></td>

                                        {props.state.apiMessage.decoratedRounds.summary.best.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.best.holes).slice(9,18).map((hole, index) =>
                                            <td key={index} align="center"
                                                className={"cell " + (hole.strokes > 0 ? getScoreClass(hole.scratchStablefordScore) : 'cellnone')}>{hole.strokes}</td>
                                        )
                                        }
                                        <td className="cell cellheader"></td>
                                        <td align="center" className="cell celloldlight">{props.state.apiMessage.decoratedRounds.summary.best.totals.backNine}</td>
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.best.totals.total}</td>
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader">Average Putts</td>
                                        {props.state.apiMessage.decoratedRounds.summary.averagePutts.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averagePutts.holes).slice(0,9).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averagePutts.totals.frontNine.toFixed(1)}</td>
                                        <td className="cell celloldlight"></td>
                                        <td className="cell cellheader"></td>

                                    </tr>
                                    <tr>
                                        <td className="cell cellheader"></td>
                                        {props.state.apiMessage.decoratedRounds.summary.averagePutts.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averagePutts.holes).slice(9,18).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }
                                        <td className="cell cellheader"></td>
                                        <td align="center"
                                            className="cell celloldlight">{props.state.apiMessage.decoratedRounds.summary.averagePutts.totals.backNine.toFixed(1)}</td>
                                        <td align="center"
                                            className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averagePutts.totals.total.toFixed(1)}</td>
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader">Average Over</td>
                                        {props.state.apiMessage.decoratedRounds.summary.averageOverPar.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averageOverPar.holes).slice(0,9).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averageOverPar.totals.frontNine.toFixed(1)}</td>
                                        <td className="cell celloldlight"></td>
                                        <td className="cell cellheader"></td>
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader"></td>
                                        {props.state.apiMessage.decoratedRounds.summary.averageOverPar.holes && Array.from(props.state.apiMessage.decoratedRounds.summary.averageOverPar.holes).slice(9,18).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole.toFixed(1)}</td>
                                        )
                                        }
                                        <td className="cell cellheader"></td>
                                        <td align="center" className="cell celloldlight">{props.state.apiMessage.decoratedRounds.summary.averageOverPar.totals.backNine.toFixed(1)}</td>
                                        <td align="center" className="cell cellheader">{props.state.apiMessage.decoratedRounds.summary.averageOverPar.totals.total.toFixed(1)}</td>
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader">Your Stroke Index</td>
                                        {props.state.apiMessage.decoratedRounds.summary.yourStrokeIndex && Array.from(props.state.apiMessage.decoratedRounds.summary.yourStrokeIndex).slice(0,9).map((hole, index) =>
                                            <td key={index} align="center" className="cell cellheader">{hole}</td>
                                        )
                                        }
                                    </tr>
                                    <tr>
                                        <td className="cell cellheader"></td>
                                        {props.state.apiMessage.decoratedRounds.summary.yourStrokeIndex && Array.from(props.state.apiMessage.decoratedRounds.summary.yourStrokeIndex).slice(9,18).map((hole, index) =>
                                            <td key={index} align="center"
                                                className="cell cellheader">{hole}</td>
                                        )
                                        }
                                    </tr>
                                </>
                            }

                        </>
                        : <tr>
                            <td colSpan={10}>This user has no
                                rounds {props.state.apiMessage.decoratedRounds.course ? "at this course" : ""}</td>
                        </tr>
                    }
                    </tbody>

                </table>

                {props.state.apiMessage.decoratedRounds.course &&
                    <div style={{marginTop: "1em", width: "100%"}}>
                        <table style={{width: "100%"}}>
                            <tbody>
                            <tr>
                                <td nowrap="" className="cell cellheader"
                                    align="center">Holes Played
                                </td>
                                <td className="cell celleagle" align="center">Eagle</td>
                                <td className="cell cellbirdie" align="center">Birdie</td>
                                <td className="cell cellpar" align="center">Par</td>
                                <td className="cell cellbogey" align="center">Bogey</td>
                                <td className="cell celldoublebogey" align="center">D
                                    Bogey
                                </td>
                                <td className="cell cell3" align="center">3 Over</td>
                                <td className="cell cell4" align="center">4+ Over</td>
                            </tr>
                            <tr>
                                <td nowrap="" className="cell cellheader"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.holesPlayed}</td>
                                <td className="cell celleagle"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.eagle}</td>
                                <td className="cell cellbirdie"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.birdie}</td>
                                <td className="cell cellpar"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.par}</td>
                                <td className="cell cellbogey"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.bogey}</td>
                                <td className="cell celldoublebogey"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.doubleBogey}</td>
                                <td className="cell cell3"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.threeOver}</td>
                                <td className="cell cell4"
                                    align="center">{props.state.apiMessage.decoratedRounds.summary.parBreakdown.fourOver}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                }

            </td>
        </tr>
        </tbody>
    </table>
        ;
}

RoundsTableMobile.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.any,
        accessLevel: PropTypes.string,
        scoreShadingId: PropTypes.string,
        stablefordId: PropTypes.string,
        error: PropTypes.any,
        courseId: PropTypes.any,
        apiMessage: PropTypes.shape( {
            decoratedRounds: PropTypes.any,
            pageNumber: PropTypes.number,
            pageSize: PropTypes.number,
            totalRecords:PropTypes.number,
            totalPages: PropTypes.number,
        }),
        showResult: PropTypes.bool
    }),
    deleteRound: PropTypes.func,
    denied: PropTypes.func
};

export default RoundsTableMobile;