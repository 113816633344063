import React from "react";
import ContentHeader from "../components/ContentHeader";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Helmet } from 'react-helmet';
export const HelpComponent = () => {

    return (
        <div className="console">
            <Helmet>
                <title>Golfing Record | Help</title>
            </Helmet>
            <ContentHeader label={'Help'}/>

            <div className={"detailconsolecontent"}>
           <Tabs>
                <TabList>
                    <Tab>Courses</Tab>
                    <Tab>Rounds</Tab>
                    <Tab>Friends</Tab>
                    <Tab>Statistics</Tab>
                    <Tab>Handicaps</Tab>
                    <Tab>General</Tab>
                    <Tab>Mobile</Tab>
                </TabList>

                <TabPanel>
                    <div
                        id="tab_courses"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Adding a new course" border={0} src="/img/label/help/adding_a_new_course.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To add a new course, select the <i>New Course</i> tab. If you have not
                            yet added any courses, this tab will be selected by default when you log
                            in.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To add the new course, enter the name, Standard Scratch Score, and Slope
                            Index (if applicable) and the Pars and Stroke Indexes for each hole. You
                            may wish to copy the information from a course that has already been
                            created by one of your friends. Any courses that belong to any of your
                            friends will appear in the drop-down list box next to the text{" "}
                            <i>"Populate form with course information from"</i>. This will not
                            create the course, it will just fill the form with the existing course's
                            details so that you can make any amendments necessary.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            The drop-down list box next to the text <i>"Add a new course for"</i>{" "}
                            allows you to choose whether you will be adding this course for yourself
                            or for one of your friends. See <i>Adding and Deleting Friends</i>.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            When you are happy with the course details, click the <i>Update</i>{" "}
                            button. If there are no errors, the course will be added and you will be
                            taken to the <i>Courses</i> screen where you will see that the course
                            has been added to your course list.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Adding a new tee" border={0} src="/img/label/help/adding_a_new_tee.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            You have the option to specify the tees you are using at a given course.
                            If you wish to add another tee for the same course, go to the "Edit
                            Course" page for the relevant course, and follow the instructions in the
                            information box above the "Tees" field.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            You will be taken to the "New Course" screen and can amend the course
                            for the new tee details before clicking the "Add Course" button. This
                            will create a second version of the course with the new tee information.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Note that if you simply change the tee value on the "Edit Course" page,
                            this will not create a new tee for the same course - instead it will
                            just change the existing tee value for that course.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Adding a nine hole course" border={0} src="/img/label/help/adding_a_nine_hole_course.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To add a nine-hole course, enter holes 1-9 and the system will duplicate
                            these to holes 10-18.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            If you enter a stroke index for the nine entered holes, the system will
                            calculate the stroke index for the duplicated holes automatically. See
                            the table below for an example:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <table className="normaltext">
                                <tbody>
                                <tr className="cell cellheader">
                                    <td>Original Hole</td>
                                    <td>Original Stroke Index</td>
                                    <td>Calculated 18-Hole Stroke Index</td>
                                    <td>Duplicated Hole</td>
                                    <td>Calculated 18-Hole Stroke Index</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>1</b>
                                    </td>
                                    <td>4</td>
                                    <td>7</td>
                                    <td>10</td>
                                    <td>8</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>2</b>
                                    </td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>11</td>
                                    <td>6</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>3</b>
                                    </td>
                                    <td>8</td>
                                    <td>15</td>
                                    <td>12</td>
                                    <td>16</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>4</b>
                                    </td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>13</td>
                                    <td>2</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>5</b>
                                    </td>
                                    <td>6</td>
                                    <td>11</td>
                                    <td>14</td>
                                    <td>12</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>6</b>
                                    </td>
                                    <td>7</td>
                                    <td>13</td>
                                    <td>15</td>
                                    <td>14</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>7</b>
                                    </td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>16</td>
                                    <td>4</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>8</b>
                                    </td>
                                    <td>5</td>
                                    <td>9</td>
                                    <td>17</td>
                                    <td>10</td>
                                </tr>
                                <tr className="cell celloldlightish" style={{textAlign: "center"}}>
                                    <td>
                                        <b>9</b>
                                    </td>
                                    <td>9</td>
                                    <td>17</td>
                                    <td>18</td>
                                    <td>18</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Editing a course" border={0} src="/img/label/help/editing_a_course.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To edit an existing course, select the <i>Course</i> tab and click the{" "}
                            <i>edit</i> icon <img alt="Edit" src="/img/new/edit.gif"/> next to the course you
                            wish to edit.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            A page will appear showing the course details. Make any changes and then
                            click the <i>Update Course</i>. You will then be returned to the{" "}
                            <i>Courses</i> page.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            If you have a friends list containing one or more players who have
                            granted you access to their account and they have the same course (by
                            name, location and tees) as the one you are updated, you can cause the
                            update to happen to their course as well. To do this, ensure that the{" "}
                            <i>Update Friends Courses Where Applicable</i> checkbox is selected.
                            Note that this checkbox will only appear if you are editing your own
                            copy of the course.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Deleting a course" border={0} src="/img/label/help/deleting_a_course.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To delete a course, select the <i>Course</i> tab and click the{" "}
                            <i>delete</i> icon <img alt="Delete" src="/img/new/trash.gif"/> next to the course
                            you wish to delete. You will be prompted to enter the word 'delete'.
                            This is to make certain that you are aware that you are about to delete
                            all the course and round information recorded for this course.
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div
                        id="tab_rounds"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Adding a new round" border={0} src="/img/label/help/adding_a_new_round.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To add a new round, click on the <i>new round</i> icon{" "}
                            <img alt="New" src="/img/new/new.gif"/> next to the course on which the round was
                            played, or use the 'New Round' button while viewing previous rounds for
                            the relevant course.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            A score must be entered for each hole. If a hole was not played, you
                            should enter a dash ('-'). If a hole was forfeited, you should enter an
                            'x'.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            There are a number of optional fields that may be used while entering a
                            new round:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <table className="normaltext" style={{width:"800px"}}>
                                <tbody>
                                <tr>
                                    <td className="cell cellheader">
                                        <b>Field</b>
                                    </td>
                                    <td className="cell cellheader">
                                        <b>Description</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Notes</td>
                                    <td className="cell celloldlightish">
                                        This field can be used to add any notes, excuses or boasts you
                                        would like to make about the hole played. When adding a round
                                        for the first time, these notes will be saved after clicking the{" "}
                                        <i>Add Round</i> button. If you are revisiting and editing an
                                        existing round, the notes will save automatically without using
                                        the <i>Add Round</i> button to save the whole round.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">Putts</td>
                                    <td className="cell celloldlight">The total number of putts taken.</td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Drive Yardage</td>
                                    <td className="cell celloldlightish">
                                        The initial drive yardage from the tee.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">Fairway Hit</td>
                                    <td className="cell celloldlight">
                                        You may optionally choose to indicate whether or not you hit the
                                        fairway from the tee. If left blank, this will not be including
                                        in your statistics report. The <i>fairways hit</i> statistic is
                                        not applicable to par three holes.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Bunker</td>
                                    <td className="cell celloldlightish">
                                        You may indicate here whether or not you found yourself in a
                                        bunker during the hole. As above, the statistic will not be used
                                        in your report if left blank. To indicate a greenside bunker as
                                        opposed to a fairway bunker, ensure that you enter a Y or N
                                        value for the <i>sand save</i> field.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">Sand Save</td>
                                    <td className="cell celloldlight">
                                        If you found yourself in a greenside bunker you may indicate
                                        here if you managed to achieve an "up and down" out of the
                                        bunker (one shot out of the bunker followed by a sunk putt - the
                                        score for the hole is not relevant).
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Penalties</td>
                                    <td className="cell celloldlightish">
                                        The number of penalties, if any, incurred during the hole.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Editing a round" border={0} src="/img/label/help/editing_a_round.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To edit a round, bring up the rounds for a particular course (by
                            clicking the course name on the <i>Courses</i> tab, or by selecting the
                            course from the drop down list on the <i>Rounds</i> tab) and click the{" "}
                            <i>edit</i> icon <img alt="Edit" src="/img/new/edit.gif"/> next to the round you
                            wish to edit.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            A page will appear with the round details. You may now make any changes
                            and click the <i>Update Round</i> button. You will be returned to the{" "}
                            <i>Rounds</i> tab.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Deleting a round" border={0} src="/img/label/help/deleting_a_round.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To edit a round, bring up the rounds for a particular course (by
                            clicking the course name on the <i>Courses</i> tab, or by selecting the
                            course from the drop down list on the <i>Rounds</i> tab) and click the{" "}
                            <i>delete</i> icon <img alt="delete" src="/img/new/trash.gif"/> next to the round
                            you wish to edit.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            You will be asked to confirm your request before the round is deleted.
                            If you click <i>cancel</i>, no action will be taken.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Viewing your round history" border={0} src="/img/label/help/viewing_your_round_history.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            You can access your history at a course in one of two ways:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <ul>
                                <li>
                                    By clicking the course name on the <i>Courses</i> tab.
                                </li>
                                <li>
                                    By selecting the course from the drop down list on the <i>Rounds</i>{" "}
                                    tab.
                                </li>
                            </ul>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Both methods will take you to the same screen where you will see a table
                            containing the course details and showing all the rounds you have
                            recorded at that course. A key to some of the column headings in this
                            table is given below:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <ul>
                                <li>
                                    <b>Fr</b> = Front Nine
                                </li>
                                <li>
                                    <b>Ba</b> = Back Nine
                                </li>
                                <li>
                                    <b>To</b> = Total
                                </li>
                                <li>
                                    <b>Ha</b> = Handicap for this round
                                </li>
                                <li>
                                    <b>St</b> = Stableford score (based on handicap entered for this
                                    round)
                                </li>
                                <li>
                                    <b>Fa</b> = Number of fairways hit (if recorded)
                                </li>
                                <li>
                                    <b>Pu</b> = Total number of putts (if recorded)
                                </li>
                            </ul>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            There are four rows at the bottom of the table that give interesting
                            information on your performance at the chosen course:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <ul>
                                <li>
                                    <b>Average</b> shows the average number of strokes taken on each
                                    hole
                                </li>
                                <li>
                                    <b>Average Over</b> is the Average for the hole minus the par
                                </li>
                                <li>
                                    <b>Best</b> shows a theoretical best round based on the best score
                                    achieved on each hole
                                </li>
                                <li>
                                    <b>Your Stroke Index</b> shows how difficult each hole is based on
                                    your own performance at the course. The lower the stroke index, the
                                    harder the hole has proved to be for you in the past.
                                </li>
                            </ul>
                        </div>
                        <div style={{marginTop: 5}}>
                            <img
                                alt="Comparing your round history with friends"
                                border={0}
                                src="/img/label/help/comparing_rounds_with_friends.gif"
                            />
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To compare your friends' rounds with your own for a particular day,
                            click the compare icon <img alt="compare" src="/img/new/compare.gif"/> next to the
                            round on the "rounds" tab page.
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div
                        id="tab_friends"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Adding and deleting friends" border={0} src="/img/label/help/adding_and_deleting_friends.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            On the <i>Friends</i> tab, you may maintain a list of other Golfing
                            Record users in whom you have an interest.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To add a user to your list of friends, enter their name and click the{" "}
                            <i>Add Friend</i> button. The user will be added to the list.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Alternatively, if your friend does not yet have an account, you can
                            create one for them by clicking the link to the right of the{" "}
                            <i>Add Friend</i> button.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            In order to create an account for a friend, you will need to know your
                            friend's email address (if they do not have an email address, you might
                            consider creating one for them at one of the free email providers like
                            Hotmail.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            If you create their account in this way (by clicking the link from the
                            Friends page), you will automatically be added to their friends list,
                            and they will be added to yours. You will be given update access to
                            their new account by default.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Your friend will receive an email notification of the account creation.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Please note that in order to add a friend to this list they must have an
                            account of their own. The name that you will be adding is the username
                            of their account. If your friends do not have accounts, or do not have
                            frequent access to the Internet, you may wish to create accounts on
                            behalf of your friends and grant yourself update access to each of them.
                            If you use the correct email address for your friends' accounts, they
                            will receive an email when the account is created containing their
                            username and password.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To remove a friend from the list, click the <i>delete</i> icon{" "}
                            <img alt="delete" src="/img/new/trash.gif"/> next to the friend's name.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img
                                alt="Granting access to your account"
                                border={0}
                                src="/img/label/help/granting_access_to_your_account.gif"
                            />
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Anyone on your friends list may view your golfing history at Golfing
                            Record. If you wish, you may allow anyone on the site to view your
                            details by de-selecting the{" "}
                            <i>Allow Only Friends To View Your History</i> option on the{" "}
                            <i>Site Settings</i> tab.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            You may also grant anyone on your friends list access to <b>Update</b>{" "}
                            your account. You can see what level of access a friend has to your
                            account on the <i>Friends</i> tab in the{" "}
                            <i>Access To Your Account/Click To Toggle</i> column. The access level
                            will either be <i>Update</i> or <i>View</i>. By clicking in this column,
                            you will switch the access level from one to the other.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            When a friend has <i>Update</i> access to your account, they may
                            maintain your golfing history for you (although they are not allowed to
                            delete courses). This will be useful if you play regularly with the same
                            group of friends and one person wishes to take responsibility for
                            maintaining the record.
                        </div>
                        <div style={{marginTop: 5}}>
                            <img alt="Maintaining your friends" border={0} src="/img/label/help/maintaining_your_friends.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            If you have been given <i>Update</i> access to another account, you can
                            perform the following actions to maintain their account:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <ul>
                                <li>
                                    Add a new course by selecting a friend from the drop down list on
                                    the <i>New Course</i> tab.
                                </li>
                                <li>
                                    Add a new round. When adding a new round, you are given a drop down
                                    list containing any of your friends who have the same course on
                                    their account.
                                </li>
                                <li>
                                    Edit a course. Beware this option because updating a course only
                                    updates the course details for THAT user. In an imminent site
                                    update, Golfing Record will provide an option to synchronise course
                                    details between friends.
                                </li>
                                <li>
                                    Edit a round. When viewing the rounds for one of your friends, click
                                    the <i>edit</i> icon <img alt="edit" src="/img/new/edit.gif"/> next to the
                                    round you wish to edit.
                                </li>
                            </ul>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div
                        id="tab_stats"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Viewing your statistics" border={0} src="/img/label/help/viewing_your_statistics.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            The <i>Statistics</i> tab page gives you a summary of your performance
                            between two dates of your choice.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Two items that may require some additional explanation are:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Strokes By Par</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            This breaks down the number of birdies, pars, bogeys, and so on, that
                            you have made for each par type. Within each category there are two
                            columns. The first column shows the exact number of times you are
                            achieved that result (i.e. 12 birdies on Par 3 Holes), while the figure
                            on the right shows the cumulative total (for example, 45 Pars on Par 3s
                            would also include the 12 birdies).
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Percentage By Par</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            This is similar to the above table but instead breaks down the{" "}
                            <i>percentage</i> of birdies, pars, bogeys, and so on, that you have
                            made for each par type. Again, there are two columns for each category,
                            with the second column being the cumulative totals.
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div
                        id="tab_handicaps"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Handicaps" border={0} src="/img/label/help/handicaps.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>The handicap calculation</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Golfing Record can calculate your handicap in a number of different
                            ways. The system used determines the maximum number of strokes that may
                            be considered for each hole.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Capping the number of strokes on each hole helps to make your handicap
                            resemble your potential scoring ability. As an example, if you make 17
                            pars but make 20 strokes on one of the holes, it clearly makes no sense
                            consider all of those 20 shots when calculating the handicap.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            When using the <b>Strict</b> system, the holes with stroke indexes 1-10
                            are capped at double-bogey and the remaining holes are capped at bogey.
                            That is to say that if you shoot three over par or more on any of the 10
                            most difficult holes (according to stroke index), the hole will be
                            counted as having been a double bogey for the purposes of calculating
                            the handicap. On the eight easiest holes, any scores of four over par or
                            more will be limited to just three over.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Using the <b>Standard</b> system, all holes are capped at double-bogie.
                            The other available systems also limit all eighteen holes to the same
                            maximum cap. The table below shows the capping for each systems.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <table className="normaltext">
                                <tbody>
                                <tr>
                                    <td className="cell cellheader">
                                        <b>System</b>
                                    </td>
                                    <td className="cell cellheader">
                                        <b>Description</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">USGA Estimation</td>
                                    <td className="cell celloldlight">
                                        Online services cannot offer official USGA handicaps. Handicaps
                                        calculated under this system are estimates only. Official United
                                        States Golf Association (USGA) handicaps can only be given out
                                        by authorized USGA clubs.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">World Handicap System (Beta)</td>
                                    <td className="cell celloldlight">
                                        This is the new World Handicap System introduced in 2020. This was the calculation we used until we replaced it with the one below. It is still available for those who want to use it.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">World Handicap System (2022)</td>
                                    <td className="cell celloldlight">
                                        This is the new World Handicap System as of 2022
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Strict</td>
                                    <td className="cell celloldlightish">
                                        Max handicap 28 - Standard men's CONGU® handicap. While Golfing
                                        Record will estimate your CONGU® handicap, official CONGU®
                                        handicaps are only allocated to players who are members of clubs
                                        or organisations affiliated to a National Union/Association or
                                        CONGU® and authorised by CONGU® to utilise the Unified
                                        Handicapping System (UHS).
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">Standard</td>
                                    <td className="cell celloldlight">
                                        Max handicap 36 - Standard women's CONGU® handicap.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Relaxed</td>
                                    <td className="cell celloldlightish">
                                        A Golfing Record handicap that allows a maximum theoretical
                                        handicap of 54. Although such a handicap is unlikely, this
                                        allows scores of three over par to have an influence on the
                                        handicap.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">Very Relaxed</td>
                                    <td className="cell celloldlight">
                                        A Golfing Record handicap that allows a maximum theoretical
                                        handicap of 72. Although such a handicap is unlikely, this
                                        allows scores of four over par to have an influence on the
                                        handicap.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlightish">Maximum</td>
                                    <td className="cell celloldlightish">
                                        A Golfing Record handicap that allows a maximum theoretical
                                        handicap of 180. This system results in your handicap being
                                        roughly an average of the number of shots over par across your
                                        ten best recent rounds including any disaster holes.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Equitable Stroke Control</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Using the USGA estimation system, you can choose to include Equitable
                            Stroke Control. Using this system will cap each hole based on your
                            handicap for that round.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <b>NOTE:</b> When using ESC, if you do not specify a handicap for a
                            specific round, your handicap will be taken to be zero and all holes on
                            that round will be capped at double bogey.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            The cappings are as follows:
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <table className="normaltext">
                                <tbody>
                                <tr>
                                    <td className="cell cellheader">
                                        <b>Handicap</b>
                                    </td>
                                    <td className="cell cellheader">
                                        <b>Hole Cap</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">&lt;=9</td>
                                    <td className="cell celloldlight">Double Bogey</td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">10-19</td>
                                    <td className="cell celloldlight">7</td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">20-29</td>
                                    <td className="cell celloldlight">8</td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">30-39</td>
                                    <td className="cell celloldlight">9</td>
                                </tr>
                                <tr>
                                    <td className="cell celloldlight">40+</td>
                                    <td className="cell celloldlight">10</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Once these adjustments have been made, the calculation is performed as
                            below.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <ol style={{lineHeight: 20}}>
                                <li>
                                    For each of the last 20 rounds played, the course's Standard Scratch
                                    Score (or total par where SSS is not known) is subtracted from the
                                    total number of strokes taken (after the adjustments detailed
                                    above).
                                </li>
                                <li>
                                    If the course has a slope index, each of these scores is then
                                    divided by this number and multiplied by 113. A slope index is
                                    usually found on American golf courses, although it is becoming
                                    increasingly used also in the UK.
                                </li>
                                <li>
                                    The ten best scores are kept and the others are discarded. If ten or
                                    fewer rounds have been recorded, then no rounds are discarded. When
                                    using USGA estimation, the following table applies when fewer than
                                    twenty rounds have been played:
                                    <table className="normaltext">
                                        <tbody>
                                        <tr>
                                            <td className="cell cellheader">
                                                <b>Rounds Played</b>
                                            </td>
                                            <td className="cell cellheader">
                                                <b>Rounds Used</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlight">5-6</td>
                                            <td className="cell celloldlight">1 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlightish">7-8</td>
                                            <td className="cell celloldlightish">2 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlight">9-10</td>
                                            <td className="cell celloldlight">3 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlightish">11-12</td>
                                            <td className="cell celloldlightish">4 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlight">13-14</td>
                                            <td className="cell celloldlight">5 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlightish">15-16</td>
                                            <td className="cell celloldlightish">6 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlight">17</td>
                                            <td className="cell celloldlight">7 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlightish">18</td>
                                            <td className="cell celloldlightish">8 lowest</td>
                                        </tr>
                                        <tr>
                                            <td className="cell celloldlight">19</td>
                                            <td className="cell celloldlight">9 lowest</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    The sum of all the counted rounds is then multiplied by 0.96 which
                                    is designed to give a slight advantage to better players.
                                </li>
                                <li>
                                    The handicap is calculated by taking this number and dividing by the
                                    number of counted rounds.
                                </li>
                            </ol>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Changing the Handicap System</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            To change the handicap for yourself or one of your friends, use the drop
                            down list on the <i>Handicap</i> tab. This will show the handicap
                            calculation based on the new system and, if you have permission to
                            update the account, will make the new system the default for that
                            player.
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                        <div
                        id="tab_general"
                        style={{minHeight: 600}}
                        className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                    >
                        <div style={{marginTop: 5}}>
                            <img alt="Stableford scoring" border={0} src="/img/label/help/stableford_scoring.gif"/>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            The column headed '<b>st</b>' in the scores table shows the Stableford
                            score for the round. Stableford points are awarded per hole based on a
                            combination of your handicap and the number of strokes taken for the
                            hole.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Scores for the scratch player</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Before factoring in the handicap adjustments, here are the scores that
                            are awarded to a zero handicap (scratch) player.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            In the case of the scratch player, two points are awarded for a par plus
                            one point for each shot below par; a bogie scores one point; a
                            double-bogie or greater receives no points.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            <h3>Handicap adjustments</h3>
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            Your handicap is used to give you extra shots on certain holes. For
                            example, a player with a handicap of 10 would get an extra shot on the
                            10 hardest holes (the holes with Stroke Index 1 to 10), while a player
                            with a handicap of 20 would get an extra shot on all 18 holes plus
                            another extra shot on the hardest two holes (Stroke Indexes 1 &amp; 2).
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            The extra shots you receive are used to modify the par of the hole
                            before applying the scoring as described for the scratch player.
                        </div>
                        <div style={{marginLeft: 45, marginBottom: 10}}>
                            For example, a player with an extra shot on a par 4 hole would be scored
                            as if the hole were a par 5; a player with two shots on a par 4 hole
                            would be scored as if the hole were a par 6. Scores based on a player's
                            handicap are referred to as the net scores.
                        </div>
                    </div>
        </TabPanel>
                <TabPanel>
                <div
                    id="tab_mobile"
                    style={{minHeight: 600}}
                    className="ui-tabs-panel ui-widget-content ui-corner-bottom"
                >
                    <table cellPadding={5}>
                        <tbody>
                        <tr>
                            <td valign="top">
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile login screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-login.gif"
                                    />
                                </div>
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile manage rounds screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-manage-rounds.gif"
                                    />
                                </div>
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile select course screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-select-course.gif"
                                    />
                                </div>
                            </td>
                            <td valign="top">
                                With the release of our new web application, you can now enter
                                your scores for you and your friends and even create new courses
                                as you walk round the golf course!
                                <br/>
                                <br/>
                                You'll save time by having your group's Stableford scores already
                                calculated before you reach the bar and your scores will be
                                automatically uploaded to your GolfingRecord account.
                                <br/>
                                <h3>Quick Start</h3>
                                To access the mobile site, simply visit{" "}
                                <b>
                                    <a href="https://mobile.golfingrecord.com">
                                        https://mobile.golfingrecord.com
                                    </a>
                                </b>{" "}
                                on your mobile device. All you need is a WebKit-enabled mobile
                                browser such as those found on <i>iPhone</i> and <i>Android</i>{" "}
                                handsets, or the Iris browser for <i>Windows Mobile</i>. It is
                                rumoured that a WebKit-enabled browser will also soon be available
                                for <i>BlackBerry</i> devices.
                                <br/>
                                <br/>
                                Enter your GolfingRecord login information and click the{" "}
                                <b>Submit</b> button in the top right corner.
                                <br/>
                                <br/>
                                You will notice more main menu options appearing soon but in this
                                first release you have just one choice: <b>Manage Rounds</b>.
                                <br/>
                                <br/>
                                On the following page you have the option to create a new round or
                                to browse and edit existing rounds.
                                <br/>
                                <h3>Creating a new round</h3>
                                When you create a new round you can either select from a list of
                                courses that you have previously created (this list will also
                                include any of your friends' courses), or you can elect to create
                                a new course.
                                <br/>
                                <br/>
                                If you decide to create a new course, you will be taken to a form
                                where you can enter the name of the course (the other information
                                on the form is optional). Once you have saved the course name,
                                click the <b>Select Golfers</b> link to choose which players will
                                be included in this round.
                                <br/>
                                <br/>
                                If you have created a new course, you can modify the hole
                                information (par, distance, stroke index) on the same page as you
                                will use to enter each golfer's score. Naturally, once you have
                                entered the information for one golfer, you won't need to do it
                                for everyone else!
                                <br/>
                                <br/>
                                Whether you have created a new course or are using an existing one
                                you will be asked to select which golfers are playing. Click the
                                name of each golfer to select them - you will notice that their
                                name moves to the top of the page where, if you click it again,
                                you can deselect them.
                                <br/>
                                <br/>
                                Once all the golfers have been selected click{" "}
                                <b>Finish Adding Golfers</b>.
                                <br/>
                                <br/>
                                You are now ready to start entering score and, if necessary,
                                course information.
                                <br/>
                                <br/>
                                To review or enter information for a hole, you can simply click
                                the link for the hole in question, or you can select the{" "}
                                <b>Next Incomplete Hole</b> link which will take you to the next
                                hole for which at least one of your group has not had a score
                                entered.
                                <br/>
                                <br/>
                                If you are the sole golfer in your group, you will be taken
                                directly to the score entry form, but if there is more than one of
                                you, you will be asked to select a golfer first.
                                <br/>
                                <br/>
                                On the score form, enter the number of strokes taken. You can
                                click the <b>par</b> icon to populate the strokes field with the
                                current par of the course, which can then be modified by the plus
                                and minus icons.
                                <br/>
                                <br/>
                                If you need to update any course information such as par, distance
                                or stroke index, you can scroll to the bottom of the form and
                                enter the information.
                                <br/>
                                <br/>
                                All your changes will take effect once you click the{" "}
                                <b>Save and Return</b> link.
                                <br/>
                                <br/>
                                You will also notice on some pages a <b>
                                View Round Summary
                            </b>{" "}
                                link. This will take you to a page which will show you the current
                                scorecards and Stableford scores of each golfer in your group. You
                                can use the <b>Edit This Round</b> link to return to editing
                                individual holes.
                            </td>
                            <td valign="top">
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile select hole screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-select-hole.gif"
                                    />
                                </div>
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile score entry screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-scoreform.gif"
                                    />
                                </div>
                                <div style={{margin: 10}}>
                                    <img
                                        alt={"Mobile summary screen"}
                                        border={1}
                                        style={{width: 153, height: 215}}
                                        src="/img/hme/mobile-screen-summary.gif"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <a href="https://mobile.golfingrecord.com">
                                    View the mobile version of the site in your current browser
                                </a>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </TabPanel>
        </Tabs>
            </div>

        </div>

            );
};

export default HelpComponent
