import * as PropTypes from "prop-types";
import React from "react";

function AddFriendForm(props) {
    return <>
        <hr/>
        <input type="radio" id="create" name="friendType" value="create" onChange={props.onChange}
               checked={props.state.formValues["friendType"] === "create"}/>
        &nbsp;<label htmlFor="create"><strong>Create New Account for Friend</strong></label>
        <br/><br/>
        When creating a new friend account, a random, secret password will be generated. You will be able to add courses
        and rounds for your friend from your own account but your friend
        can also request a password reset if they wish to have a direct login to the account is required.
        <br/><br/>
        {props.state.formValues["friendType"] === "create" &&
            <>
                <h5>Friend's name (will not be publicly visible)</h5>
                <input onChange={props.onChange}
                       value={props.state.formValues["friendDisplayName"]}
                       className="normaltext" type="edit"
                       name="friendDisplayName"
                       maxLength="60" size="30"/>
                <br/>
            </>
        }
        {props.state.formValues["friendType"] === "create" &&
            <>
                <h5>Friend's email</h5>
                <input onChange={props.onChange}
                       value={props.state.formValues["friendEmail"]}
                       className="normaltext" type="edit" name="friendEmail"
                       maxLength="60" size="30"/>
            </>
        }
        {props.state.formValues["friendType"] === "create" &&
            <>
            <br/>
                <br/>
                <button className={"stdButton"} onClick={props.onClick1}>Create Friend Account</button>
            </>
        }
        <hr/>
        <input type="radio" id="existing" name="friendType" value="existing" onChange={props.onChange}
               checked={props.state.formValues["friendType"] === "existing"}/>
        &nbsp;<label htmlFor="existing"><strong>Use a Friend's Existing Account</strong></label>
        <br/><br/>
        This option allows you to add an existing friend's account. You will initially have no read or write access to
        the account, but your friend will be able to grant these to you
        from within their own account.
        {props.state.formValues["friendType"] === "existing" &&
            <>
            <h5>Friend's username</h5>
                <input onChange={props.onChange} value={props.state.formValues["friendName"]} className="normaltext"
                       type="edit" name="friendName" maxLength="30" size="30"/>
                </>
        }

        <br/>
        {props.state.formValues["friendType"] === "existing" &&
            <>
                <br/>
                <button className={"stdButton"} onClick={props.onClick}>Add Friend To List</button>
            </>
        }
        <hr/>
    </>;
}

AddFriendForm.propTypes = {
    onChange: PropTypes.func,
    state: PropTypes.shape({
        formValues: PropTypes.shape({
            friendName: PropTypes.string,
            friendType: PropTypes.string,
            friendEmail: PropTypes.string
        }),
        loaded: PropTypes.bool,
        outgoingRequests: PropTypes.arrayOf(PropTypes.any),
        incomingRequests: PropTypes.arrayOf(PropTypes.any),
        error: PropTypes.any,
        apiMessage: PropTypes.shape({userId: PropTypes.number, friends: PropTypes.arrayOf(PropTypes.any)}),
        showResult: PropTypes.bool
    }),
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};

export default AddFriendForm;