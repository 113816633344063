import FriendDrop from "../Friends/FriendDrop";
import CourseDrop from "../CourseDrop";
import HandicapSystemDrop from "../HandicapSystemDrop";
import * as PropTypes from "prop-types";
import React from "react";

function Filters(props) {
    return <>
        <div className="console">
            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                <tbody>
                <tr>
                    <td className="detailconsolecontent" border="1">
                <span className="infovalue">
                <table>
                <tbody>
                <tr>
                <td className="dropdownlabel" valign="center">This is the handicap information for user </td>
                <td valign="center"><FriendDrop excludeNoRounders={false} excludePending={true} includeMe={true}
                                                friendFunction={props.friendFunction}/></td>
                <td className="dropdownlabel" valign="center"> at </td>
                <td valign="center"><CourseDrop friendId={props.state.friendId}
                                                courseFunction={props.courseFunction}/></td>
                </tr>
                </tbody>
                </table>
                </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div className="console">
            <table border="0" cellPadding="0" cellSpacing="3" width="100%">
                <tbody>
                <tr>
                    <td className="detailconsolecontent" border="1">
                <span className="infovalue">
                <table>
                <tbody>
                <tr>
                <td className="dropdownlabel"
                    valign="center">Your current handicap is <strong>{props.state.apiMessage.currentHandicap?.toFixed(2) ?? "-"}</strong> based on the </td>
                <td valign="center">
                <HandicapSystemDrop selected={props.state.apiMessage.handicapSystem}
                                    changeFunction={props.changeFunction}/>
                </td>
                </tr>
                {props.state.apiMessage.handicapSystem === 360 &&
                    <tr>
                        <td colSpan={2} className="dropdownlabel" valign="center">
                            <input checked={props.state.apiMessage.useEsc} onChange={props.onChange}
                                   type={"checkbox"} name={"useesc"}/>&nbsp;
                            Use Equitable Stroke Control (please see help pages for more
                            info.)
                        </td>
                    </tr>
                }
                </tbody>
                </table>
                </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </>;
}

Filters.propTypes = {
    friendFunction: PropTypes.func,
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.any,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    }),
    courseFunction: PropTypes.func,
    changeFunction: PropTypes.func,
    onChange: PropTypes.func
};
export default Filters;
