import React from "react";
import ContentHeader from "../components/ContentHeader";
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from "react-router-dom";

export const CreditsSuccessComponent = () => {
    const navigate = useNavigate();

    return (
        <div className="console">
            <div
                id="tabs_help"
                className="ui-tabs ui-widget ui-widget-content ui-corner-all"
            >

                            <ContentHeader label={'Thank You'}/>
                            <div className="detailconsolecontent">
                                Thanks for your support!
                                <div>
                                    You can cancel your subscription at any time on the <button className={"button_as_link"} onClick={() => navigate("/premium")}>subscription page</button>
                                </div>
                            </div>


            </div>


        </div>
    );
};

export default CreditsSuccessComponent