import React, {useState, useEffect} from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from '../config';
import Loading from '../components/Loading';
import ContentHeader from '../components/ContentHeader';
import NewFriendDrop from '../components/Friends/NewFriendDrop';
import { useParams } from 'react-router-dom';
import ConsoleLoading from '../components/ConsoleLoading';
import SearchResults from '../components/SearchResults';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {getCourse, getFriendData, getReferenceCourse, getReferenceSearchResults} from '../utils/Net';
import TeeDropdown from "../components/Courses/TeeDropdown";
import CourseDrop from "../components/CourseDrop";

export const NewCourseComponent = () => {
  const { apiOrigin = 'http://localhost:8080' } = getConfig();
  const navigate = useNavigate();

  const params = useParams();
  const isNewCourse = params.courseId === undefined;
  const [friendData, setFriendData] = useState(null);
  const [selectedFromMyCoursesId, setSelectedFromMyCoursesId] = useState(-1);

  const [state, setState] = useState({
    showResult: false,
    friendId: params.friendId === undefined ? 0 : params.friendId,
    error: null,
    loaded: false,
    searchResults: [],
    noResults: false,
    apiMessage: {},
    formValues: {
      courseName: '',
      courseCity: '',
      sss: 0.0,
      slopeIndex: 0,
      courseTees: 0,
      numberOfHoles: 18,
      userId: -1,
      searchTerm: '',
      courseTeesName: '',
    },
  });

  const { getAccessTokenSilently, isLoading, error } = useAuth0();

  useEffect(() => {

    getState(params.courseId, false, false).then((stateData) => {
      setState(stateData);
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  async function getState(courseId, searchResult = false, copiedFrom = false) {

    const token = await getAccessTokenSilently();
    setFriendData(await getFriendData(token, false, true, true));

    if (isNewCourse && !searchResult && !copiedFrom) {
      return {
        ...state,
        showResult: true,
      };
    }
    try {

      let responseData;

      if (searchResult) {
        responseData = await getReferenceCourse(token, courseId);

        state.formValues['courseName'] = responseData.club.clubName + ((responseData.club.clubName !== responseData.course.coursename) ? " (" + responseData.course.courseName + ")" : "")
        state.formValues['courseCity'] = responseData.course.courseCity || '';
        state.formValues['courseTees'] = 0;
        state.formValues['sss'] = responseData.course.sss || 0.0;
        state.formValues['slopeIndex'] = responseData.course.slopeIndex || 0;
        state.formValues['distanceMeasure'] = 'Y';
        state.formValues['searchTerm'] = '';
        state.formValues.numberOfHoles = responseData.course.numberOfHoles;

        const firstTeeKey = responseData.tees[0].teeId;

        populateReferenceHoleData(responseData.holes, firstTeeKey);

      } else {
        // Must be editing or populating from a selected user course
        responseData = await getCourse(token, courseId);

        state.formValues['courseName'] = responseData.course.courseName;
        state.formValues['courseCity'] = responseData.course.courseCity || '';
        state.formValues['courseTees'] = responseData.course.courseTees || 0;
        state.formValues['sss'] = responseData.course.sss || 0.0;
        state.formValues['slopeIndex'] = responseData.course.slopeIndex || 0;
        state.formValues['distanceMeasure'] = responseData.course.distanceMeasure;
        state.formValues['searchTerm'] = '';
        state.formValues['courseTeesName'] = responseData.course.courseTeesName || '';
        state.formValues.numberOfHoles = responseData.course.numberOfHoles;

        Array.from(responseData.holes).forEach((hole) => {
          state.formValues['par_' + hole.holeNumber] = hole.par;
          state.formValues['strokeIndex_' + hole.holeNumber] = hole.strokeIndex;
          state.formValues['yardage_' + hole.holeNumber] = hole.yardage;
        });
      }

      return {
        ...state,
        showResult: true,
        apiMessage: responseData,
      };
    } catch (error) {
      console.error(error);
      return {
        ...state,
        error: error.error,
      };
    }
  }

  function populateReferenceHoleData(holeData, selectedTee) {
    if (parseInt(selectedTee) !== -1) {
      Array.from(holeData[selectedTee]).forEach((hole) => {
        state.formValues['par_' + hole.holeNumber] = hole.par;
        state.formValues['strokeIndex_' + hole.holeNumber] = hole.strokeIndex;
        state.formValues['yardage_' + hole.holeNumber] = hole.yardage;
      });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let course = {
      courseName: state.formValues['courseName'],
      courseCity: state.formValues['courseCity'],
      sss: state.formValues['sss'] || 0,
      slopeIndex: state.formValues['slopeIndex'] || 0.0,
      numberOfHoles: state.formValues['numberOfHoles'] || 18,
      userId: state.friendId,
      courseTees: state.formValues['courseTees'],
      courseTeesName: state.formValues['courseTeesName'],
      distanceMeasure: state.formValues['distanceMeasure'],
      holes: [
        { holeNumber: 1, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 2, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 3, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 4, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 5, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 6, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 7, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 8, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 9, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 10, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 11, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 12, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 13, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 14, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 15, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 16, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 17, par: 0, strokeIndex: 1, yardage: 0 },
        { holeNumber: 18, par: 0, strokeIndex: 1, yardage: 0 },
      ],
    };

    Array.from(Array(state.formValues.numberOfHoles).keys()).forEach((k) => {
      let i = k + 1;
      course.holes[k].par = state.formValues['par_' + i];
      course.holes[k].strokeIndex = state.formValues['strokeIndex_' + i] || i;
      course.holes[k].yardage = state.formValues['yardage_' + i] || 0;
      course.holes[k].holeNumber = i;
    });

    const siString = course.holes
      .slice(0, course.numberOfHoles)
      .sort((a, b) => a.strokeIndex - b.strokeIndex)
      .map((h) => h.strokeIndex)
      .join('');

    const pars = course.holes.filter((h) => h.par > 0).length;
    if (pars.toString() !== state.formValues.numberOfHoles.toString()) {
      toast.error('Please enter par details for all ' + state.formValues.numberOfHoles + ' holes.');
      return;
    }

    const strokeIndexes = course.holes.filter((h) => h.par > 0).length;
    if (strokeIndexes.toString() !== state.formValues.numberOfHoles.toString()) {
      toast.error('Please enter stroke index details for all ' + state.formValues.numberOfHoles + ' holes');
      return;
    }

    if (state.formValues.courseName.trim().length === 0) {
      toast.error('Please enter a course name');
      return;
    }

    if (state.formValues.courseName.trim().length === 0) {
      toast.error('Please enter a course name');
      return;
    }

    if (course.numberOfHoles === 18 && siString !== '123456789101112131415161718') {
      toast.error('Please enter a unique stroke index for each hole');
      return;
    }

    if (course.numberOfHoles === 9 && siString !== '123456789') {
      toast.error('Please enter a unique stroke index for each hole');
      return;
    }

    const token = await getAccessTokenSilently();

    let uri = `${apiOrigin}/courses` + (isNewCourse ? '' : '/' + params.courseId);

    await fetch(uri, {
      method: isNewCourse ? 'POST' : 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(course),
    });

    navigate('/courses' + (state.friendId ? '?friendId=' + state.friendId : ''));
  }

  const handleChange = (event) => {
    if (event.target.name === 'numberOfHoles') {
      state.formValues.numberOfHoles = parseInt(event.target.value);
    } else
    if (event.target.name === 'teeColour') {
      state.formValues['courseTees'] = event.target.value;
      populateReferenceHoleData(state.apiMessage.holes, event.target.value)
    } else {
      state.formValues[event.target.name] = event.target.value;
    }

    setState({
      ...state,
    });
  };

  function submitSearch() {
      updateSearchResults(state.formValues.searchTerm.trim());
  }

  function changeFriend(event) {
    state.friendId = event.target.value;

    setState({
      ...state,
    });
  }

  async function populateCourse(courseId) {
    getState(courseId, true, false).then((stateData) => {
      setState(stateData);
    });
    setSelectedFromMyCoursesId(-1)
    clearSearch();
  }

  async function updateSearchResults(searchTerm) {
    const token = await getAccessTokenSilently();

    state.searchResults = await getReferenceSearchResults(token, searchTerm);
    state.noResults = (state.searchResults.length === 0)
    setState({
      ...state,
    });
  }

  function clearSearch() {
    state.searchResults = [];
    state.formValues.searchTerm = '';

    setState({
      ...state,
    });
  }

  function selectCourseToCopy(event) {
    const selectedUserCourseId = event.target.value;
    setSelectedFromMyCoursesId(selectedUserCourseId)
    getState(selectedUserCourseId, false, true).then((stateData) => {
      setState(stateData);
    });
    clearSearch();
  }

  function PageTitleRow(props) {
    if (props.isNewCourse) {
      return (
        <>
          <ContentHeader label={'New Course'} />
          <div className="detailconsolecontent">
            <table>
              <tr>
                <td>Add a new course for user</td>
              {
                friendData && (
                    <td>
                    <NewFriendDrop
                      friendData={friendData}
                      selected={state.friendId}
                      friendFunction={changeFriend}
                      />
                    </td>
                  )
              }
              </tr>
              <tr>
                <td>Copy course data from</td>
              {
                  friendData && (
                      <td>
                        <CourseDrop selected={selectedFromMyCoursesId}
                                    friendId={0}
                                    courseFunction={selectCourseToCopy}/>
                      </td>
                  )
              }
              </tr>
            </table>
          </div>
        </>
      );
    } else {
      return (
        <table style={{ width: '100%', fontSize: '11px', marginTop: '0px' }}>
          <tbody>
            <tr>
              <td valign="top">
                <ContentHeader label={'Edit Course'} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  return (
    <div className="console">
      {!state.showResult ? (
        <ConsoleLoading />
      ) : (
        <div>
          <div>
            <PageTitleRow isNewCourse={isNewCourse} />
          </div>
          <div>
            {isNewCourse && (
              <div className={'detailconsolecontent'}>
                <h3>Search for Course</h3>
                Details will be populated below after selection, or simply add the details manually.
                <table style={{ marginTop: '10px' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '20em' }}>
                        <input
                          onChange={handleChange}
                          className="formel"
                          type="text"
                          value={state.formValues.searchTerm}
                          name="searchTerm"
                          size="50"
                          maxLength="250"
                        />
                      </td>
                      <td>
                        <input className={"stdButton"} type="button" onClick={submitSearch} value="Search" />
                        {state.searchResults.length > 0 && <input className={"stdButton"} type="button" onClick={clearSearch} value="Clear" />}
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {(state.searchResults.length > 0 || state.noResults) && (
                      <tr>
                        <td colSpan={8} style={{border:"1px solid #444"}}>
                          <SearchResults
                            searchResults={state.searchResults}
                            selectCourseFunc={populateCourse}
                            showUsername={false}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div id="div_searchforcourseresults"></div>
              </div>
            )}
          </div>
          <div>
            <div className={'detailconsolecontent'}>
              <div id="div_courseform">
                <form onSubmit={handleSubmit}>
                  <table>
                    <tbody>
                      {isNewCourse && (
                        <tr>
                          <td>Number of Holes</td>
                          <td>
                            <select name="numberOfHoles" value={state.formValues.numberOfHoles} onChange={handleChange}>
                              <option value={9}>9 holes</option>
                              <option value={18}>18 holes</option>
                            </select>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td nowrap="" className="normaltext">
                          Course Name
                        </td>
                        <td>
                          <input
                            onChange={handleChange}
                            size="50"
                            maxLength="50"
                            className="normaltext"
                            name="courseName"
                            value={state.formValues['courseName']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td nowrap="" className="normaltext">
                          Location
                        </td>
                        <td>
                          <input
                            onChange={handleChange}
                            size="50"
                            maxLength="50"
                            className="normaltext"
                            name="courseCity"
                            value={state.formValues['courseCity']}
                          />
                        </td>
                      </tr>

                      {
                          isNewCourse && state.apiMessage && state.apiMessage.tees && state.apiMessage.tees.length > 0 && (
                              <tr>
                                <td nowrap="" className="normaltext">Tees</td>
                                <td className="normaltext">
                                  <TeeDropdown tees={state.apiMessage.tees} changeFunc={handleChange}/>
                                </td>
                              </tr>
                          )
                      }

                      {
                        (!isNewCourse || parseInt(state.formValues["courseTees"]) === -1) && (
                            <tr>
                                <td nowrap="" className="normaltext">Tee Colour</td>
                                <td className="normaltext">
                                    <input
                                        onChange={handleChange}
                                        size="50"
                                        maxLength="50"
                                        className="normaltext"
                                        name="courseTeesName"
                                        value={state.formValues['courseTeesName']}
                                    />
                                </td>
                            </tr>
                          )
                      }



                      <tr>
                        <td nowrap="" className="normaltext">
                          Course Index (S.S.S.)
                        </td>
                        <td>
                          <input
                            onChange={handleChange}
                            className="normaltext"
                            size="5"
                            maxLength="5"
                            name="sss"
                            value={state.formValues['sss']}
                          />
                          &nbsp;&nbsp;Standard Scratch Score. If the SSS is not entered, the par for the course will be
                          used.
                        </td>
                      </tr>
                      <tr>
                        <td nowrap="" className="normaltext">
                          Slope Index (if applicable)
                        </td>
                        <td>
                          <input
                            onChange={handleChange}
                            className="normaltext"
                            size="5"
                            maxLength="3"
                            name="slopeIndex"
                            value={state.formValues['slopeIndex']}
                          />
                          &nbsp;&nbsp;If the Slope Index is not known or not applicable, leave this blank.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table className="filledtable" border="0" width={'100%'}>
                    <tbody>
                      <tr>
                        <td height="20" className="cell cellheader">
                          Hole
                        </td>
                        {Array.from(Array(state.formValues.numberOfHoles).keys())
                          .map((k) => k + 1)
                          .map((i) => (
                            <td key={'holenumber_' + i} height="20" className="cell cellheader" align="center">
                              {i}
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td className="cell celloldlight">Par</td>
                        {Array.from(Array(state.formValues.numberOfHoles).keys())
                          .map((k) => k + 1)
                          .map((i) => (
                            <td key={'par_' + i} className="cell celledit">
                              <input
                                className="normaltext"
                                onChange={handleChange}
                                size="2"
                                maxLength="2"
                                key={'par_' + i}
                                name={'par_' + i}
                                value={state.formValues['par_' + i] || ''}
                              />
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td align="center" colSpan="19" height="20" className="cell cellheader">
                          The following information is optional
                        </td>
                      </tr>
                      <tr>
                        <td className="cell cellheader">Stroke Index (Handicap)</td>
                        {Array.from(Array(state.formValues.numberOfHoles).keys())
                          .map((k) => k + 1)
                          .map((i) => (
                            <td key={'par_' + i} className="cell celledit">
                              <input
                                className="normaltext"
                                onChange={handleChange}
                                size="2"
                                maxLength="2"
                                key={'strokeIndex_' + i}
                                name={'strokeIndex_' + i}
                                value={state.formValues['strokeIndex_' + i] || ''}
                              />
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td className="cell celloldlight">Distance</td>
                        {Array.from(Array(state.formValues.numberOfHoles).keys())
                          .map((k) => k + 1)
                          .map((i) => (
                            <td key={'par_' + i} className="cell celledit">
                              <input
                                className="normaltext"
                                onChange={handleChange}
                                size="4"
                                maxLength="4"
                                key={'yardage_' + i}
                                name={'yardage_' + i}
                                value={state.formValues['yardage_' + i] || ''}
                              />
                            </td>
                          ))}
                      </tr>
                      <tr>
                        <td className="cell celloldlight">Distance Measure</td>
                        <td className="normaltext" colSpan="18">
                          <select
                            onChange={handleChange}
                            value={state.formValues.distanceMeasure}
                            className="dropdown"
                            name="distanceMeasure"
                            id=""
                          >
                            <option value="Y">Yards</option>
                            <option value="M">Meters</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <input type="submit" className="stdButton" value="Save" />
                  <input type="hidden" name="action" value="add" />
                  <input type="hidden" name="uid" value="28" />
                  <input type="hidden" name="originalcoursename" value="" />
                  <input type="hidden" name="originalcity" value="" />
                  <input type="hidden" name="originaltees" value="" />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(NewCourseComponent, {
  onRedirecting: () => <Loading />,
});
