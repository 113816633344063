import * as PropTypes from "prop-types";
import React from "react";

function StrokesByPar(props) {
    return <table className="filledtable" border="0" style={{width:"100%"}}>
        <tbody>
        <tr>
            <td align="center"
                className="cell cellheader">&nbsp;</td>
            <td align="center"
                className="cell cellheader">Played
            </td>
            <td align="center"
                className="cell cellheader">Strokes
            </td>
            <td align="center"
                className="cell cellheader">Average
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Eagle
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Birdie
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Par
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">Bogey
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">D Bogey
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">3 Over
            </td>
            <td colSpan="2" align="center" height="20"
                className="cell cellheader">4+ Over
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 3
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par3.played}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par3.strokes}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">
                {props.state.apiMessage.strokesByPar.par3.average.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par3.eagle.total}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par3.eagle.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par3.birdie.total}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par3.birdie.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par3.par.total}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par3.par.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par3.bogey.total}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par3.bogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par3.doubleBogey.total}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par3.doubleBogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par3.threeOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par3.threeOver.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par3.fourPlusOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par3.fourPlusOver.cumulative}
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 4
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par4.played}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par4.strokes}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par4.average.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par4.eagle.total}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par4.eagle.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par4.birdie.total}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par4.birdie.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par4.par.total}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par4.par.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par4.bogey.total}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par4.bogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par4.doubleBogey.total}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par4.doubleBogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par4.threeOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par4.threeOver.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par4.fourPlusOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par4.fourPlusOver.cumulative}
            </td>
        </tr>
        <tr>
            <td style={{whiteSpace: "nowrap"}}
                align="center"
                height="20"
                className="cell cellheader">PAR 5
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par5.played}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par5.strokes}
            </td>
            <td align="center" height="20"
                className="cell celloldlight">{props.state.apiMessage.strokesByPar.par5.average.toFixed(2)}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par5.eagle.total}
            </td>
            <td align="center" height="20"
                className="cell celleagle">{props.state.apiMessage.strokesByPar.par5.eagle.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par5.birdie.total}
            </td>
            <td align="center" height="20"
                className="cell cellbirdie">{props.state.apiMessage.strokesByPar.par5.birdie.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par5.par.total}
            </td>
            <td align="center" height="20"
                className="cell cellpar">{props.state.apiMessage.strokesByPar.par5.par.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par5.bogey.total}
            </td>
            <td align="center" height="20"
                className="cell cellbogey">{props.state.apiMessage.strokesByPar.par5.bogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par5.doubleBogey.total}
            </td>
            <td align="center" height="20"
                className="cell celldoublebogey">{props.state.apiMessage.strokesByPar.par5.doubleBogey.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par5.threeOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell3">{props.state.apiMessage.strokesByPar.par5.threeOver.cumulative}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par5.fourPlusOver.total}
            </td>
            <td align="center" height="20"
                className="cell cell4">{props.state.apiMessage.strokesByPar.par5.fourPlusOver.cumulative}
            </td>
        </tr>
        </tbody>
    </table>;
}

StrokesByPar.propTypes = {
    state: PropTypes.shape({
        loaded: PropTypes.bool,
        friendId: PropTypes.number,
        dateTo: PropTypes.any,
        dateFrom: PropTypes.any,
        error: PropTypes.any,
        courseId: PropTypes.number,
        apiMessage: PropTypes.shape({}),
        showResult: PropTypes.bool
    })
};

export default StrokesByPar
