import React from "react";

import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Footer from "../../components/Footer";

export const ContactComponent = () => {

    return (

        <>
      <div className="static">
          <Helmet>
              <title>Golfing Record | Contact Us</title>
          </Helmet>
          <h1>Contact Us</h1>
          <div>
              <div>
                  Please email us directly at <a href="mailto:golfingrecord@netsensia.com">golfingrecord@netsensia.com</a>.
              </div>
          </div>
      </div>
                <Footer/>
        </>
  );
};

export default ContactComponent
